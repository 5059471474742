import { makeStyles } from '@material-ui/core';
import { StatusColors } from '../../../enums';

export const useStyles = makeStyles({
    healthChecksContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        width: '100%',
    },
    healthChecksItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        textAlign: 'center',
        height: '100px',
    },
    iconApp: {
        marginRight: '10px',
        marginLeft: '30px',
    },
    iconsGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    iconStatus: {
        marginRight: '30px',
    },
    error: {
        color: StatusColors.error,
    },
    on: {
        color: StatusColors.success,
    },
    off: {
        color: StatusColors.corrupted,
    },
    serviceName: {
        display: 'flex',
        alignItems: 'center',
    },
    checkStatusesbtn: {
        marginTop: '20px',
    },
});

import { FC } from 'react';
import { Navigate } from 'react-router-dom';

export type IProtectedRoute = {
    children: JSX.Element;
    isAuthenticated: boolean;
    authenticationPath: string;
};

export const ProtectedRoute: FC<IProtectedRoute> = ({
    children,
    isAuthenticated,
    authenticationPath,
}) => {
    return isAuthenticated ? children : <Navigate to={authenticationPath} />;
};

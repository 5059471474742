export enum GeneralSettingsKeys {
    BACKUP_EXPIRES_TIME = 'BACKUP_EXPIRES_TIME',
}

export enum BackupAutodelete {
    forever = '0',
    day = '1',
    week = '7',
    month = '31',
}

export type BackupLifetimeValues = `${BackupAutodelete}`;

export const BackupAutodeleteView: any = {
    [BackupAutodelete.forever]: 'Forever',
    [BackupAutodelete.day]: 'Day',
    [BackupAutodelete.week]: 'Week',
    [BackupAutodelete.month]: 'Month',
};

export const backupAutodeleteEntries = Object.keys(BackupAutodelete).map((v) => {
    const value = (BackupAutodelete as any)[v];
    const view = BackupAutodeleteView[value];
    return { value, view };
});

export interface GeneralToolSettings {
    id: string;
    setting_key: string;
    setting_value: string | null;
    last_update_by_id: string;
}

import { Button, Paper, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { confirmUser } from '../../app/auth/auth.actions';
import { useAppDispatch } from '../../app/hooks';
import { AppRoutes } from '../../enums';
import { useStyles } from './classes';
import { Logo, PasswordField } from './common';
import { loginValidation } from './validation';

interface FormValues {
    email: string;
    temp_password: string;
    password: string;
    confirm: string;
}

const defaultValues: FormValues = {
    email: '',
    temp_password: '',
    password: '',
    confirm: '',
};

export const Confirm: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showReceivedPass, setShowReceivedPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const handleShowReceivedPass = () => {
        setShowReceivedPass(!showReceivedPass);
    };

    const handleShowNewPass = () => {
        setShowNewPass(!showNewPass);
    };

    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    };

    const formik = useFormik<FormValues>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            const { email, temp_password: oldPassword, password: newPassword } = values;
            dispatch(confirmUser({ email, oldPassword, newPassword }));
        },
        validationSchema: loginValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit } = formik;
    const { email, temp_password, password, confirm } = values;

    return (
        <Paper className={classes.paper}>
            <Logo />
            <form className={classes.form} onSubmit={handleSubmit}>
                <h1>Authorization</h1>
                <TextField
                    required
                    className={classes.formInput}
                    type="email"
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors?.email}
                />
                <PasswordField
                    title="Received password"
                    name="temp_password"
                    value={temp_password}
                    onChange={handleChange}
                    showPassword={showReceivedPass}
                    error={!!errors.temp_password}
                    helperText={errors?.temp_password}
                    handleShowPassword={handleShowReceivedPass}
                />
                <PasswordField
                    title="New password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    showPassword={showNewPass}
                    error={!!errors.password}
                    helperText={errors?.password}
                    handleShowPassword={handleShowNewPass}
                />
                <PasswordField
                    title="Confirm password"
                    name="confirm"
                    value={confirm}
                    onChange={handleChange}
                    showPassword={showConfirmPass}
                    error={!!errors.confirm}
                    helperText={errors?.confirm}
                    handleShowPassword={handleShowConfirmPass}
                />
                <Link className={classes.textLink} to={AppRoutes.authLogin}>
                    Already have account? Click to sign in
                </Link>
                <Button variant="outlined" className={classes.button} type="submit" color="primary">
                    Submit
                </Button>
            </form>
        </Paper>
    );
};

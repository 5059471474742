import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 0,
    },
    addUserButton: {
        marginRight: '15px',
    },
    addUserIcon: {
        width: '50px',
        height: '50px',
    },
    disableButton: {
        marginLeft: 'auto',
    },
});

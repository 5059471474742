export const SET_SUCCESS = 'SET_SUCCESS';
export const REMOVE_SUCCESS = 'REMOVE_SUCCESS';

interface ISetSuccess {
    type: 'SET_SUCCESS';
    message: string | null;
}

interface IRemoveSuccess {
    type: 'REMOVE_SUCCESS';
}

export type SuccessActions = ISetSuccess | IRemoveSuccess;

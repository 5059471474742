import api from './axios.main';
import { userPool } from './config';
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';

export enum authRoutes {
    confirm = '/users/confirm',
}

export interface IConfirmUserData {
    email: string;
    oldPassword: string;
    newPassword: string;
}

export interface ILoginUserData {
    email: string;
    password: string;
}

export const authApi = {
    login({ email, password }: ILoginUserData) {
        return new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: userPool,
            });
            user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    },
    getResetPasswordCode(email: string) {
        return new Promise((res, rej) => {
            const user = new CognitoUser({
                Username: email,
                Pool: userPool,
            });
            user.forgotPassword({
                onSuccess: (result) => {
                    res(result);
                },
                onFailure: (err) => {
                    rej(err);
                },
            });
        });
    },
    changeResetedPassword(email: string, code: string, password: string, confirmPassword: string) {
        return new Promise((res, rej) => {
            const user = new CognitoUser({
                Username: email,
                Pool: userPool,
            });
            if (password !== confirmPassword) {
                console.error('Password are not the same');
            }
            user.confirmPassword(code, password, {
                onSuccess: (data) => {
                    res(data);
                },
                onFailure: (err) => {
                    rej(err);
                },
            });
        });
    },
    confirm({ email, oldPassword, newPassword }: IConfirmUserData) {
        return new Promise((resolve, reject) => {
            api.post(authRoutes.confirm, {
                email,
                oldPassword,
                newPassword,
            })
                .then((res) => {
                    this.login({ email, password: newPassword }).then((data) => {
                        resolve(data);
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    logout() {
        const user = userPool.getCurrentUser();
        if (user) {
            user.signOut();
        }
    },
    isAuthenticated(): Promise<boolean> {
        return new Promise((success, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (!cognitoUser) {
                reject(false);
                return;
            }
            cognitoUser.getSession((err: any, session: CognitoUserSession) => {
                if (err) {
                    reject(false);
                    return;
                }
                if (session.isValid()) {
                    success(true);
                } else {
                    reject(false);
                }
            });
        });
    },
    getTokenId(): Promise<string | null> {
        return new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (!user) {
                reject(null);
            } else {
                user.getSession((err: any, session: CognitoUserSession) => {
                    if (err || !session) {
                        reject(new Error('Failure getting Cognito session: ' + err));
                        return;
                    }
                    console.debug('Successfully got session');
                    const token = session.getIdToken().getJwtToken();
                    resolve(token);
                });
            }
        });
    },
    passwordChange(oldPassword: string, newPassword: string) {
        return new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (!user) {
                throw new Error('Can not recieve current user data');
            } else {
                user.getSession((err: any, session: CognitoUserSession) => {
                    user.changePassword(oldPassword, newPassword, function (err, result) {
                        if (err) {
                            reject(err);
                            return;
                        }
                        resolve(result);
                    });
                });
            }
        });
    },
    getCurrentUserName(): Promise<string> {
        return new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (user) {
                const username = user.getUsername();
                resolve(username);
            } else {
                reject(new Error('Failure getting Cognito User'));
            }
        });
    },
};

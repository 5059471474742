import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { changeUserRole } from '../../../../../app/user/actions';
import { userRolesEntries, UserRolesType } from '../../../../../enums';
import { useStyles } from './classes';

type ResetPasswordProps = {
    open: boolean;
    handleClose: () => void;
    id: string;
    role: UserRolesType;
};

export const ChangeRoleModal: FC<ResetPasswordProps> = ({ open, handleClose, id, role }) => {
    const classes = useStyles();
    const [userRole, setUserRole] = useState<UserRolesType>(role);
    const [loading, setLoading] = useState(false);
    const handleChangeRole = (event: React.ChangeEvent<any>) => {
        setUserRole(event.target.value);
    };
    const dispatch = useAppDispatch();

    const closeModal = () => {
        handleClose();
    };

    const changeRole = async () => {
        setLoading(true);
        await dispatch(changeUserRole(id, userRole));
        closeModal();
        setLoading(false);
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={open} onClose={closeModal} aria-labelledby="change-user-role-dialog">
            <DialogTitle className={classes.title}>Change user role</DialogTitle>
            <DialogContent className={classes.content}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">User role</InputLabel>
                    <Select
                        native
                        value={userRole}
                        onChange={handleChangeRole}
                        inputProps={{
                            name: 'role',
                            id: 'role-select',
                        }}
                    >
                        {userRolesEntries.map((role) => (
                            <option key={role.value} value={role.value}>
                                {role.view}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button onClick={closeModal} color="secondary">
                        Close
                    </Button>
                    <Button type="submit" color="primary" variant="contained" onClick={changeRole} disabled={loading}>
                        Change role
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export enum UserRoles {
    admin = 'admin',
    user = 'user',
}

export type UserRolesType = `${UserRoles}`;

export const UserRolesView: any = {
    [UserRoles.admin]: 'Admin',
    [UserRoles.user]: 'User',
};

export const userRolesEntries = Object.keys(UserRoles).map((v) => {
    const value = (UserRoles as any)[v];
    const view = UserRolesView[value];
    return { value, view };
});

import { MappingType } from '../app/csv/templates/reducer';
import { JsonFieldTypes } from '../enums';

export const convertToDefaultValue = (mapping: MappingType[]) => {
    let defaultMapping = [];
    for (let mappingItem of mapping) {
        if (
            mappingItem.source === '' &&
            mappingItem?.type !== JsonFieldTypes.stringConcat &&
            mappingItem?.type !== JsonFieldTypes.objectsArray &&
            mappingItem?.type !== JsonFieldTypes.intArray &&
            mappingItem?.type !== JsonFieldTypes.int
        ) {
            mappingItem.source = '';
            mappingItem.type = JsonFieldTypes.defaultValue;
        } else if (mappingItem?.type === JsonFieldTypes.int && mappingItem.source === '') {
            mappingItem.type = JsonFieldTypes.defaultValue;
            mappingItem.source = 0;
        } else if (mappingItem?.type === JsonFieldTypes.objectsArray && mappingItem.destination === 'phones') {
            let source = mappingItem.source as MappingType[][];
            let foundSource = source.find((item) => item.find((item) => item.source));
            if (foundSource === undefined) {
                mappingItem.source = [];
                mappingItem.type = JsonFieldTypes.defaultValue;
            }
        } else if (
            mappingItem?.type === JsonFieldTypes.stringConcat &&
            typeof mappingItem.source === 'object' &&
            mappingItem.source.length === 0
        ) {
            mappingItem.type = JsonFieldTypes.defaultValue;
            mappingItem.source = '';
        } else if (
            mappingItem?.type === JsonFieldTypes.intArray &&
            typeof mappingItem.source === 'object' &&
            mappingItem.source.length === 0
        ) {
            mappingItem.type = JsonFieldTypes.defaultValue;
            mappingItem.source = [];
        }
        defaultMapping.push(mappingItem);
    }
    return defaultMapping;
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    expandIcon: {
        position: 'absolute',
        left: '-10px',
        transition: 'transform .2s ease-in-out',
        border: 'none',
    },
    expanded: {
        transform: 'rotate(180deg)',
    },
    statusBlock: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'inherit',
        cursor: 'pointer',
        position: 'relative',
        padding: 0,
    },
    expandBlock: {
        padding: 0,
        gridColumn: '1 / 7',
        border: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stepLabel: {
        textTransform: 'capitalize',
    },
    containerError: {
        gridColumn: '1 / 7',
        backgroundColor: '#000',
        padding: '10px',
        color: 'rgb(242, 70, 70)',
        fontWeight: 600,
        width: '100%',
    },
    border: {
        border: 'none',
    },
    accordion: {
        width: '100%',
        margin: '0 auto',
    },
});

import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './classes';

type SelectorProps = {
    title?: string;
    name?: string;
    value: string;
    values: {
        value: string;
        view: string;
    }[];
    changeValue: (e: React.ChangeEvent<any>) => void;
};

export const SelectorForm: FC<SelectorProps> = ({ title, name, value, values, changeValue }) => {
    const classes = useStyles();
    return (
        <FormControl fullWidth className={classes.formControl}>
            {title && <InputLabel>{title}</InputLabel>}
            <Select native name={name} value={value} onChange={changeValue}>
                {values.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.view}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        position: 'relative',
    },
    wrapper: {
        width: 'calc(100% - 200px)',
        marginLeft: '210px',
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
        padding: '12px',
    },
});

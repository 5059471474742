import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    settingsBlock: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        maxWidth: '504px',
        width: '100%',
    },
    textInput: {
        marginBottom: '15px',
    },
    buttonConfrim: {
        maxWidth: '180px',
    },
    buttonIcon: {
        marginRight: '5px',
    },
});

import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Popover,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import {
    defaultMigrationStatusesVIew,
    defaultVendorsView,
    MigrationStatus,
    migrationStatusEntries,
    Vendors,
    vendorTypeEntries,
} from '../../../../enums';
import { useChartsFiltering } from '../../../../hooks/useChartsFiltering';
import { FilterType } from '../../../common/Tables/FilterBar';

type FilterChart = {
    setFilter: Dispatch<SetStateAction<FilterType>>;
    start: Date;
    setStart: Dispatch<SetStateAction<Date>>;
    end: Date;
    setEnd: Dispatch<SetStateAction<Date>>;
};

export const FilterChart = ({ setFilter, start, setStart, end, setEnd }: FilterChart) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleStartDate = (date: MaterialUiPickersDate) => {
        setStart(date!);
    };

    const handleEndDate = (date: MaterialUiPickersDate) => {
        setEnd(date!);
    };

    const { handleSelectAllStatuses, handleSelectAllVendors, handleStatus, handleVendor, statuses, vendors } =
        useChartsFiltering('line');
    const changeDataByFilter = () => {
        setFilter({ vendor: vendors, status: statuses });
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    label="Start date"
                    value={start}
                    onChange={handleStartDate}
                    autoOk
                    animateYearScrolling
                    format="MM/dd/yyyy"
                    style={{ width: '150px', paddingRight: '12px' }}
                />
                <DatePicker
                    label="End date"
                    value={end}
                    onChange={handleEndDate}
                    autoOk
                    animateYearScrolling
                    format="MM/dd/yyyy"
                    style={{ width: '150px' }}
                />
            </MuiPickersUtilsProvider>
            <Tooltip title="Filter">
                <IconButton aria-label="filter" aria-describedby={id} onClick={handleClick}>
                    <FilterList />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper style={{ padding: '12px' }}>
                    <Typography>Filter</Typography>
                    <FormControl fullWidth>
                        Vendor type
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={vendors.length === defaultVendorsView.length}
                                    onChange={handleSelectAllVendors}
                                />
                            }
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        {vendorTypeEntries.map((item) => (
                            <FormControlLabel
                                key={item.value}
                                label={item.view}
                                control={
                                    <Checkbox
                                        id={item.value}
                                        key={item.value}
                                        color="primary"
                                        checked={vendors.includes(item.value as Vendors)}
                                        onChange={handleVendor}
                                    />
                                }
                            />
                        ))}
                    </Box>
                    <FormControl fullWidth>
                        Status
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={statuses.length === defaultMigrationStatusesVIew.length}
                                    onChange={handleSelectAllStatuses}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {migrationStatusEntries.map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    label={item.view}
                                    control={
                                        <Checkbox
                                            id={item.value}
                                            key={item.value}
                                            color="primary"
                                            checked={(statuses as MigrationStatus[]).includes(
                                                item.value as MigrationStatus
                                            )}
                                            onChange={handleStatus}
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </FormControl>
                    <FormControl
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: '16px',
                        }}
                    >
                        <Button color="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button color="primary" variant="contained" onClick={changeDataByFilter}>
                            Search
                        </Button>
                    </FormControl>
                </Paper>
            </Popover>
        </div>
    );
};

import { AnyAction, Dispatch } from 'redux';
import { migrationApi, RunMigrationParams } from '../../api/migration-api';
import { Migration } from '../../model';
import { AppState } from '../root.reducer';
import {
    SET_MIGRATIONS,
    SET_IS_FETCHING,
    SET_LIMIT_OFFSET,
    STOP_MIGRATION_IN_PROGRESS,
    STOP_MIGRATION_SUCCESS,
    STOP_MIGRATION_FAILURE,
    CANCEL_MIGRATION_IN_PROGRESS,
    CANCEL_MIGRATION_SUCCESS,
    CANCEL_MIGRATION_FAILURE,
} from './action-types';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setError } from '../error/error.actions';

export const setIsFetching = (isFetching: boolean) => ({
    type: SET_IS_FETCHING,
    isFetching,
});

const setMigrations = (migrations: Migration[]) => ({
    type: SET_MIGRATIONS,
    migrations,
});

const setLimitOffset = (offset?: number, limit?: number) => ({
    type: SET_LIMIT_OFFSET,
    offset,
    limit,
});

export const fetchMigrations =
    (offset?: number, limit?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
        setLimitOffset(offset, limit);
        const { migrationStore } = getState();
        const { migrations } = migrationStore;
        try {
            if (migrations.length === 0) dispatch(setIsFetching(true));
            const res = await migrationApi.getAllMigrations(offset, limit);
            const { success, message } = res;
            if (success) {
                dispatch(setMigrations(message));
            } else {
                throw new Error(message);
            }
            dispatch(setIsFetching(false));
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
            dispatch(setIsFetching(false));
        }
    };

export const refreshMigrations = (dispatch: Dispatch, getState: () => AppState) => {
    const { migrationStore } = getState();
    const { offset, limit } = migrationStore;
    (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(fetchMigrations(offset, limit));
};

export const runMigration = (params: RunMigrationParams) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const res = await migrationApi.runMigration(params);
        const { success, message } = res;
        if (success) refreshMigrations(dispatch, getState);
        else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const restartMigration = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        await migrationApi.restartMigration(id);
        refreshMigrations(dispatch, getState);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const deleteMigration = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const res = await migrationApi.deleteMigrationById(id);
        const { success, message } = res;
        if (success) refreshMigrations(dispatch, getState);
        else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const downloadOutputJsons = (id: string, name: string) => async (dispatch: Dispatch) => {
    try {
        const res = await migrationApi.getOutputDownloadLink(id);
        const { success, message } = res;
        if (success) {
            const link = document.createElement('a');
            link.href = message;
            document.body.appendChild(link);
            link.click();
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

const stopMigrationInProgress = () => ({
    type: STOP_MIGRATION_IN_PROGRESS,
});

const stopMigrationSuccess = () => ({
    type: STOP_MIGRATION_SUCCESS,
});

const stopMigrationFailure = (error: Error) => ({
    type: STOP_MIGRATION_FAILURE,
    error,
});

export const stopMigration = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(stopMigrationInProgress());
    try {
        await migrationApi.stopMigration(id);
        dispatch(stopMigrationSuccess());
        refreshMigrations(dispatch, getState);
    } catch (error) {
        dispatch(stopMigrationFailure(error as Error));
    }
};

const cancelMigrationInProgress = () => ({
    type: CANCEL_MIGRATION_IN_PROGRESS,
});

const cancelMigrationSucccess = () => ({
    type: CANCEL_MIGRATION_SUCCESS,
});

const cancelMigrationFailure = (error: Error) => ({
    type: CANCEL_MIGRATION_FAILURE,
    error,
});

export const cancelMigration = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(cancelMigrationInProgress());
    try {
        await migrationApi.cancelMigration(id);

        dispatch(cancelMigrationSucccess());
        refreshMigrations(dispatch, getState);
    } catch (error) {
        dispatch(cancelMigrationFailure(error as Error));
    }
};

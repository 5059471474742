import { CircularProgress, Typography } from '@material-ui/core';
import { Close, InsertDriveFile } from '@material-ui/icons';
import { useState } from 'react';
import { Backup } from '../../../../model';
import { useStyles } from './classes';
import clsx from 'clsx';

interface UploadBackupsBarProps {
    backupInProgress: Backup[];
}

const UploadBackupBar = (props: UploadBackupsBarProps) => {
    const { backupInProgress } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(true);
    const handleClick = () => setIsOpen(false);
    return (
        <div className={clsx(classes.uploadBarContainer, !isOpen && classes.uploadBarContainerHidden)}>
            <div className={classes.header}>
                <div className={classes.headerText}>Upload backups</div>
                <div className={classes.headerIcon} onClick={handleClick}>
                    <Close fontSize="medium" />
                </div>
            </div>
            {backupInProgress?.map((backup) => {
                return (
                    <div className={classes.backupItem} key={backup.id}>
                        <div className={classes.backupInfo}>
                            <InsertDriveFile color="primary" fontSize="small" />
                            <Typography className={classes.backupName}>{backup.name}</Typography>
                        </div>

                        <div className={classes.backupIcon}>
                            <CircularProgress color="primary" size={30} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default UploadBackupBar;

import { makeStyles } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppState } from '../../app/root.reducer';
import { AppRoutes } from '../../enums';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
    },
});

export const Auth: FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { isAuthorized } = useSelector((state: AppState) => state.authStore);

    useEffect(() => {
        if (isAuthorized) {
            navigate(AppRoutes.migrations);
        }
    }, [isAuthorized, navigate]);

    return (
        <div className={classes.container}>
            <Outlet />
        </div>
    );
};

import { Card, CardContent, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { fetchAverageBackupSize } from '../../../../app/dashboard/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { formatSize } from '../../../../helpers/formatters';
import { useStyles } from './classes';

type BackupsSizeAverage = {
    averageBackupSize: string;
};

export const BackupsSizeAverage = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { averageBackupSize } = useAppSelector((state) => state.dashboardStore);
    useEffect(() => {
        dispatch(fetchAverageBackupSize());
    }, []);
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography gutterBottom align="center" variant="h6" component="h2">
                    Average backup size
                </Typography>
                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                    {averageBackupSize ? formatSize(averageBackupSize) : '-'}
                </Typography>
            </CardContent>
        </Card>
    );
};

import { Dispatch } from 'redux';
import { authApi, IConfirmUserData, ILoginUserData } from '../../api/auth-api';
import { setError } from '../error/error.actions';
import { SET_IS_AUTH, SET_IS_RESTORED_PASSWORD, SET_LOADING } from './auth.types';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../root.reducer';

export const setAuth = (isAuth: boolean) => ({
    type: SET_IS_AUTH,
    isAuth,
});

export const setLoading = (isLoading: boolean) => ({
    type: SET_LOADING,
    isLoading,
});

export const setIsRestoredPassword = (isRestoredPassword: boolean) => ({
    type: SET_IS_RESTORED_PASSWORD,
    isRestoredPassword,
});

export const getUserAuth = () => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
        const isAuth = await authApi.isAuthenticated();
        dispatch(setAuth(isAuth));
        dispatch(setLoading(false));
    } catch (err: any) {
        dispatch(setError(err.response?.data?.message || err.message));
        dispatch(setAuth(false));
        dispatch(setLoading(false));
    }
};

export const signInUser = (authData: ILoginUserData) => async (dispatch: Dispatch) => {
    try {
        await authApi.login(authData);
        (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(getUserAuth());
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const sendResetCode = (email: string) => async (dispatch: Dispatch) => {
    try {
        await authApi.getResetPasswordCode(email);
        dispatch(setIsRestoredPassword(true));
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const changePassword =
    (email: string, code: string, password: string, confirmPassword: string) => async (dispatch: Dispatch) => {
        try {
            await authApi.changeResetedPassword(email, code, password, confirmPassword);
            (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(signInUser({ email, password }));
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

export const confirmUser = (confirmData: IConfirmUserData) => async (dispatch: Dispatch) => {
    try {
        await authApi.confirm(confirmData);
        (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(getUserAuth());
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

import { Button, CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { FC } from 'react';
import { useStyles } from './classes';

type PropsType = {
    isFetching: boolean;
};

export const SaveButton: FC<PropsType> = ({ isFetching }) => {
    const classes = useStyles();
    return (
        <Button
            className={classes.buttonConfrim}
            size="small"
            variant="outlined"
            type="submit"
            color="primary"
            disabled={isFetching}
        >
            {isFetching ? (
                <CircularProgress size={20} className={classes.buttonIcon} />
            ) : (
                <Save fontSize="small" className={classes.buttonIcon} />
            )}
            Save
        </Button>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    formControl: {
        marginTop: '10px',
    },
    uploadBarContainer: {
        position: 'absolute',
        bottom: '64px',
        right: '24px',
        width: '350px',
        borderRadius: '2px',
        boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.5)',
    },
    uploadBarContainerHidden: {
        display: 'none',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '54px',
        backgroundColor: '#ff6125',
        borderRadius: '3px 3px 0 0',
    },
    headerText: {
        color: '#fff',
        padding: '0 24px',
        fontSize: '1rem',
    },
    headerIcon: {
        color: '#fff',
        paddingRight: '24px',
        cursor: 'pointer',
    },
    backupItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
        backgroundColor: 'white'
    },
    backupInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '24px',
    },
    backupName: {
        paddingLeft: '4px',
        textAlign: 'center',
    },
    backupIcon: {
        paddingRight: '24px',
    },
});

import { ConversionStatusType, JsonFieldTypes } from '../enums';

export interface ITemplate {
    createdAt: string;
    created_by_email: string;
    created_by_id: string;
    id: string;
    name: string;
    template: string;
    updatedAt: string;
    deleted: boolean;
}

export interface IConversion {
    createdAt: string;
    created_by_email: string;
    created_by_id: string;
    end_ts: string | null;
    error: string | null;
    id: string;
    inputs: string;
    name: string;
    output_s3_bucket: string | null;
    output_s3_key: string | null;
    start_ts: string | null;
    status: ConversionStatusType;
    template_id: string;
    updatedAt: string;
    deleted: boolean;
}

export const initialMapping = {
    customers: [
        {
            source: '',
            destination: 'customer_type',
        },
        {
            source: '',
            destination: 'first_name',
        },
        {
            source: '',
            destination: 'last_name',
        },
        {
            source: '',
            destination: 'short_company_name',
        },
        {
            source: '',
            destination: 'company',
        },
        {
            source: '',
            destination: 'id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'contact_email',
        },
        {
            source: '',
            destination: 'detail',
        },
        {
            source: '',
            destination: 'address_parts.number',
        },
        {
            source: '',
            destination: 'address_parts.complement',
        },
        {
            source: '',
            destination: 'address_parts.district',
        },
        {
            source: '',
            destination: 'address_parts.name',
        },
        {
            source: '',
            destination: 'address_city',
        },

        {
            source: '',
            destination: 'address_line1',
        },
        {
            source: '',
            destination: 'address_line2',
        },
        {
            source: '',
            destination: 'address_state',
        },
        {
            source: '',
            destination: 'address_type',
        },
        {
            source: '',
            destination: 'address_zip',
        },
        {
            source: '',
            destination: 'government_id',
        },
        {
            source: '',
            destination: 'tax_id',
        },
        {
            source: '',
            destination: 'phone',
        },
        {
            source: [],
            destination: 'address',
            type: JsonFieldTypes.stringConcat,
        },
        {
            source: [],
            destination: 'phones',
            type: JsonFieldTypes.objectsArray,
        },
    ],
    phones: [
        {
            source: '',
            destination: 'phone_number',
        },
        {
            source: '',
            destination: 'extension',
        },
        {
            source: '',
            destination: 'type',
        },
    ],
    vehicles: [
        {
            source: '',
            destination: 'id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'detail',
        },
        {
            source: '',
            destination: 'fleet_number',
        },
        {
            source: '',
            destination: 'year',
        },
        {
            source: '',
            destination: 'make',
        },
        {
            source: '',
            destination: 'model',
        },
        {
            source: '',
            destination: 'vin',
        },
        {
            source: '',
            destination: 'engine',
        },
        {
            source: '',
            destination: 'plate',
        },
        {
            source: [],
            destination: 'customer_ids',
            type: JsonFieldTypes.intArray,
        },
    ],
    inventory: [
        {
            source: '',
            destination: 'id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'number',
        },
        {
            source: '',
            destination: 'brand',
        },
        {
            source: '',
            destination: 'description',
        },
        {
            source: '',
            destination: 'tax_id',
        },
        {
            source: '',
            destination: 'unit_code',
        },
        {
            source: '',
            destination: 'quantity_on_hand',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'min_stock',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'max_stock',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'location',
        },
        {
            source: '',
            destination: 'cost_cents',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'list_price_cents',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'last_vendor_id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'misc_info',
        },
    ],
    technicians: [
        {
            source: '',
            destination: 'id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'first_name',
        },
        {
            source: '',
            destination: 'last_name',
        },
        {
            source: '',
            destination: 'contact_email',
        },
    ],
    vendors: [
        {
            source: '',
            destination: 'id',
            type: JsonFieldTypes.int,
        },
        {
            source: '',
            destination: 'name',
        },
        {
            source: '',
            destination: 'short_company_name',
        },
        {
            source: '',
            destination: 'address',
        },
        {
            source: '',
            destination: 'district',
        },
        {
            source: '',
            destination: 'city',
        },
        {
            source: '',
            destination: 'state',
        },
        {
            source: '',
            destination: 'account_number',
        },
        {
            source: '',
            destination: 'website',
        },
        {
            source: '',
            destination: 'phone',
        },
        {
            source: '',
            destination: 'tax_id',
        },
        {
            source: [],
            destination: 'info',
            type: JsonFieldTypes.stringConcat,
        },
    ],
};

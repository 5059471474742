import { AverageMigrationTime } from './AverageMigrationTime';
import { BackupsSizeAverage } from './BacupSizeAverage';
import { useStyles } from './classes';
import { CpuStatus } from './CpuStatus';

type Overview = {
    durations: number | null;
};

export const Overview = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <BackupsSizeAverage />
            <AverageMigrationTime />
            <CpuStatus />
        </div>
    );
};

import { User, UserInterface, UserSettings } from '../../model';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USERS = 'SET_USERS';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const DISABLE_USER = 'DISABLE_USER';
export const ENABLE_USER = 'ENABLE_USER';

interface SetCurrentUser {
    type: 'SET_CURRENT_USER';
    currentUser: User;
}

interface SetUsers {
    type: 'SET_USERS';
    users: UserInterface[];
}

interface SetUserSettings {
    type: 'SET_USER_SETTINGS';
    settings: UserSettings;
}

interface SetIsFetching {
    type: 'SET_IS_FETCHING';
    isFetching: boolean;
}

interface DisableUser {
    type: 'DISABLE_USER';
    disabledUser: User;
}

interface EnableUser {
    type: 'ENABLE_USER';
    enabledUser: User;
}

export type UserAction = 
    | SetCurrentUser
    | SetUserSettings
    | SetUsers
    | SetIsFetching
    | DisableUser
    | EnableUser;

import { FC } from 'react';
import { Paper, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Backup, ExitToApp, Settings, People, Dns, LocalHospital, Repeat, Dashboard } from '@material-ui/icons';
import swLogo from '../../../assets/img/swlogo.png';
import { useStyles } from './classes';
import { Link, useLocation } from 'react-router-dom';
import { AppRoutes, navigationRoutesEntries, UserRoles } from '../../../enums';
import { userPool } from '../../../api/config';
import { useSelector } from 'react-redux';
import { AppState } from '../../../app/root.reducer';

const renderServiceIcon = (route: string) => {
    switch (route) {
        case AppRoutes.migrations:
            return <Backup />;
        case AppRoutes.users:
            return <People />;
        case AppRoutes.backups:
            return <Dns />;
        case AppRoutes.health:
            return <LocalHospital />;
        case AppRoutes.settings:
            return <Settings />;
        case AppRoutes.csv:
            return <Repeat />;
        case AppRoutes.dashboard:
            return <Dashboard />;
        default:
            <Backup />;
    }
};

export const Navigation: FC = () => {
    const classes = useStyles();
    const location = useLocation();

    const { currentUser } = useSelector((store: AppState) => store.userStore);

    const handleLogoutButton = () => {
        const session = userPool.getCurrentUser();
        if (session) {
            session.signOut();
            window.location.reload();
        }
    };

    return (
        <Paper className={classes.paper}>
            <div className={classes.logoContainer}>
                <img className={classes.logo} src={swLogo} alt="ShopWare" />
            </div>
            <List component="nav" aria-label="main mailbox folders">
                {navigationRoutesEntries.map((route) =>
                    currentUser?.role !== UserRoles.admin && route.value === AppRoutes.users ? null : (
                        <Link to={route.value as string} key={route.value}>
                            <ListItem selected={route.value === location.pathname}>
                                <ListItemIcon>{renderServiceIcon(route.value as string)}</ListItemIcon>
                                <ListItemText primary={route.view as string} />
                            </ListItem>
                        </Link>
                    )
                )}
            </List>
            <Divider className={classes.dividerDown} />
            <List component="nav" aria-label="main mailbox folders">
                <ListItem button onClick={handleLogoutButton}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Sign out" />
                </ListItem>
            </List>
        </Paper>
    );
};

import { UploadPart } from '../model';
import api from './axios.main';

interface RestoreBackupBaseParams {
    name: string;
    vendorType: string;
    backupSource: string;
}

interface RestoreBackupFromFileParams extends RestoreBackupBaseParams {
    filename: string;
    partsCount: number;
}

interface RestoreBackupFromLink extends RestoreBackupBaseParams {
    downloadLink: string;
}

interface CompleteBackupMultipartUploadParams {
    id: string;
    uploadId: string;
    uploadParts: UploadPart[];
}

interface AbortBackupMultipartUploadParams {
    id: string;
    uploadId: string;
    error: string;
}

export const backupsApi = {
    async getAllBackups(offset?: number, limit?: number) {
        const res = await api.get(`/backups?offset=${offset}&limit=${limit}`).then((res) => res.data);
        return res;
    },

  async restoreBackup(params: RestoreBackupFromFileParams | RestoreBackupFromLink) {
    const res = await api.post(`/backups`, params).then((res) => res.data);
    return res;
  },

    async completeBackupMultipartUpload(params: CompleteBackupMultipartUploadParams) {
        const { id, uploadId, uploadParts } = params;
        const res = await api
            .post(`/backups/${id}/multipart-upload/${uploadId}/complete`, uploadParts)
            .then((res) => res.data);
        return res;
    },

    async abortBackupMultipartUpload(params: AbortBackupMultipartUploadParams) {
        const { id, uploadId, error } = params;
        const res = await api.post(`/backups/${id}/multipart-upload/${uploadId}/abort`, { error })
            .then((res) => res.data);
        return res;
    },

    async updateBackupStatus(id: string, status: string, lastCloudUpload?: string) {
        const res = await api.put(`/backups/${id}`, { status, lastCloudUpload }).then((res) => res.data);
        return res;
    },

    async deleteBackup(id: string) {
        const res = await api.delete(`/backups/${id}`).then((res) => res.data);
        return res;
    },
};

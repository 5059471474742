import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Replay, Block, Done } from '@material-ui/icons';
import { FC, useState } from 'react';
import { disableUser, enableUser } from '../../../../app/user/actions';
import { UserInterface } from '../../../../model';
import { ConfirmationModal } from '../../../common/ConfirmationModal';
import { ResetPasswordModal } from './UserModals/ResetPasswordModal';
import EditIcon from '@material-ui/icons/Edit';
import { ChangeRoleModal } from './UserModals/ChangeRoleModal';
import { useAppDispatch } from '../../../../app/hooks';

interface UserProps {
    user: UserInterface;
    index: number;
}

export const User: FC<UserProps> = ({ user, index }) => {
    const [resetModalOpen, setResetModalOpen] = useState(false);
    const handleModalOpen = () => setResetModalOpen(true);
    const handleModalClose = () => setResetModalOpen(false);
    const labelId = `enhanced-table-checkbox-${index}`;
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const handleConfirmationOpen = () => setConfirmationModalOpen(true);
    const handleConfirmationClose = () => setConfirmationModalOpen(false);
    const [changeRoleModalOpen, setChangeRoleModalOpen] = useState(false);
    const handleChangeRoleOpen = () => setChangeRoleModalOpen(true);
    const handleChangeRoleClose = () => setChangeRoleModalOpen(false);

    const dispatch = useAppDispatch();
    return (
        <>
            <TableRow hover tabIndex={-1} key={user.email}>
                <TableCell
                    padding="none"
                    align="center"
                    component="th"
                    id={labelId}
                    scope="row"
                    style={{ paddingRight: '26px' }}
                >
                    {user.email}
                </TableCell>
                <TableCell padding="none" align="center" style={{ paddingRight: '26px' }}>
                    {user.role}
                </TableCell>
                <TableCell padding="none" align="center" style={{ paddingRight: '26px' }}>
                    {user.confirmed ? 'confirmed' : 'not confirmed'}
                </TableCell>
                <TableCell padding="none" align="center" style={{ paddingRight: '26px' }}>
                    {user.disabled ? 'disabled' : 'active'}
                </TableCell>
                <TableCell padding="none" align="center" style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Tooltip title="Change role">
                        <IconButton onClick={handleChangeRoleOpen} aria-label="chenge role">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Change password">
                        <IconButton onClick={handleModalOpen} aria-label="reset">
                            <Replay />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={user.disabled ? 'Enable User' : 'Disable User'}>
                        <IconButton onClick={handleConfirmationOpen} aria-label="reset">
                            {user.disabled ? <Done /> : <Block />}
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <ChangeRoleModal
                open={changeRoleModalOpen}
                handleClose={handleChangeRoleClose}
                id={user.id}
                role={user.role}
            />
            <ResetPasswordModal open={resetModalOpen} handleClose={handleModalClose} email={user.email} />
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                text={`Are you sure, that you want ${user.disabled ? 'enable' : 'disable'} this user?`}
                onClose={handleConfirmationClose}
                onConfirm={() => {
                    const { disabled, id } = user;
                    if (disabled) {
                        dispatch(enableUser(id));
                    } else {
                        dispatch(disableUser(id));
                    }
                    handleConfirmationClose();
                }}
            />
        </>
    );
};

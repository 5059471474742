import { combineReducers } from 'redux';
import { AuthState, AuthReducer } from './auth/auth.reducer';
import { ErrorReducer, ErrorState } from './error/error.reducer';
import { BackupReducer, BackupState } from './backup/reducer';
import { MigrationReducer, MigrationState } from './migrations/reducer';
import UserReducer, { UserState } from './user/reducer';
import SettingsReducer, { AppSettingsState } from './appsettings/reducer';
import { SuccessReducer, SuccessState } from './success/success.reducer';
import { CsvTemplatesState, СsvTemplatesReducer } from './csv/templates/reducer';
import { CsvConversionsState, СsvConversionsReducer } from './csv/conversions/reducer';
import { DashboardReducer, DashboardState } from './dashboard/reducer';

export interface AppState {
    authStore: AuthState;
    backupStore: BackupState;
    errorStore: ErrorState;
    successStore: SuccessState;
    migrationStore: MigrationState;
    userStore: UserState;
    settingsStore: AppSettingsState;
    csvTemplatesStore: CsvTemplatesState;
    csvConversionsStore: CsvConversionsState;
    dashboardStore: DashboardState;
}

export const rootReducer = combineReducers({
    authStore: AuthReducer,
    backupStore: BackupReducer,
    errorStore: ErrorReducer,
    successStore: SuccessReducer,
    migrationStore: MigrationReducer,
    userStore: UserReducer,
    settingsStore: SettingsReducer,
    csvTemplatesStore: СsvTemplatesReducer,
    csvConversionsStore: СsvConversionsReducer,
    dashboardStore: DashboardReducer,
});

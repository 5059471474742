import { Memory, IConversion, Migration } from '../../model';

export const SET_MIGRATIONS_BY_DATE_RANGE = 'SET_MIGRATIONS_BY_DATE_RANGE';
export const SET_CONVERSIONS_BY_DATE_RANGE = 'SET_CONVERSIONS_BY_DATE_RANGE';
export const SET_AVERAGE_BACKUP_SIZE = 'SET_AVERAGE_BACKUP_SIZE';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_SYSTEM_INFO = 'SET_SYSTEM_INFO';
export const SET_IS_REPORT_CONVERTING = 'SET_IS_REPORT_CONVERTING';

interface SetMigrationsByDateRange {
    type: typeof SET_MIGRATIONS_BY_DATE_RANGE;
    migrations: Migration[];
}

interface SetIsFetching {
    type: typeof SET_IS_FETCHING;
    isFetching: boolean;
}

interface SetIsReportConvertingToCsv {
    type: typeof SET_IS_REPORT_CONVERTING;
    isReportConverting: boolean;
}

interface SetConversionsByDateRange {
    type: typeof SET_CONVERSIONS_BY_DATE_RANGE;
    conversions: IConversion[];
}

interface SetAverageBackupSize {
    type: typeof SET_AVERAGE_BACKUP_SIZE;
    averageBackupSize: number;
}

interface setSystemInfo {
    type: typeof SET_SYSTEM_INFO;
    cpu: number;
    disk: Memory;
    ram: Memory;
}

export type DashboardAction =
    | SetMigrationsByDateRange
    | SetAverageBackupSize
    | SetConversionsByDateRange
    | SetIsFetching
    | setSystemInfo
    | SetIsReportConvertingToCsv;

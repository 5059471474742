import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Collapse,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FC } from 'react';
import { useStyles } from './classes';

type BackupErrorPropsType = {
    expand: boolean;
    error: string;
};

export const BackupError: FC<BackupErrorPropsType> = ({ expand, error }) => {
    const classes = useStyles();
    let parsedError;
    try {
        parsedError = JSON.parse(error);
    } catch (error: any) {
        parsedError = error;
    }
    return (
        <TableRow className={classes.border}>
            <TableCell padding="none" className={classes.border} colSpan={7}>
                <Collapse in={expand} timeout="auto" unmountOnExit>
                    <TableRow className={classes.border} style={{ display: 'block' }}>
                        <TableCell className={classes.border} style={{ display: 'block' }} colSpan={7}>
                            <Accordion className={classes.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{parsedError?.message || parsedError?.name || 'Error'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className={classes.containerError}>
                                        {error?.replace(/:/g, ': ')}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </TableCell>
                    </TableRow>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

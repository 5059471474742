import { MigrationStatus, Vendors } from '../enums';
import api from './axios.main';

export interface ReportExportingToCsv {
    from: string;
    to: string;
    vendors: Vendors[];
    statuses: MigrationStatus[];
}

export const dashboardApi = {
    async getMigrationsByDateRange(from: string, to?: string) {
        const res = await api.get(`/dashboard/migrations?from=${from}&to=${to}`).then((res) => res.data);
        return res;
    },
    async getCoversionsByDateRange(from: string, to?: string) {
        const res = await api.get(`/dashboard/conversions?from=${from}&to=${to}`).then((res) => res.data);
        return res;
    },
    async getAverageBackupSize() {
        const res = await api.get(`/dashboard/size`).then((res) => res.data);
        return res;
    },
    async getSystemInfo() {
        const res = await api.get(`dashboard/workload`).then((res) => res.data);
        return res;
    },
    async reportExportingToCsv(params: ReportExportingToCsv) {
        const res = api.post(`dashboard/csv`, params).then((res) => res.data);
        return res;
    },
};

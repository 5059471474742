import { Paper } from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { defaultVendorsView } from '../../../../enums';
import { defaultGenericStatusesView } from '../../../../enums/dashboard';
import { ChartPieByStatus } from './ChartPieByStatus';
import { useStyles } from './classes';
import { Filter } from './Filter';

const initialFilter = {
    status: defaultGenericStatusesView,
    vendor: defaultVendorsView,
};

export const ChartPie = () => {
    const classes = useStyles();
    const { migrations } = useAppSelector((state) => state.migrationStore);
    const { backups } = useAppSelector((state) => state.backupStore);
    const { conversions } = useAppSelector((state) => state.csvConversionsStore);
    const [filter, setFilter] = useState(initialFilter);
    return (
        <Paper className={classes.container}>
            <ChartPieByStatus datasets={migrations} title="Migrations" filter={filter} />
            <ChartPieByStatus datasets={backups} title="Backups" filter={filter} />
            <ChartPieByStatus datasets={conversions} title="Csv conversions" filter={filter} />
            <Filter setFilter={setFilter} />
        </Paper>
    );
};

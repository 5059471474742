import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Popover,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Check, FilterList } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import {
    defaultMigrationStatusesVIew,
    defaultStatusesView,
    defaultVendorsView,
    migrationStatusEntries,
    BackupStatusEntries,
    VendorType,
    vendorTypeEntries,
} from '../../../enums';
import { SearchField } from '../Forms/SearchField';
import { useStyles } from './classes';

export type FilterType = {
    vendor: VendorType[];
    status: string[];
    startDate?: Date | null;
    endDate?: Date | null;
    searchByName?: string;
    deleted?: boolean;
};

type FilterBarProps = {
    filter: FilterType;
    setFilter: Dispatch<SetStateAction<FilterType>>;
    model?: 'Backups' | 'Migrations';
};

export const FilterBar = (props: FilterBarProps) => {
    const classes = useStyles();
    const { filter, setFilter, model } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [isVendorsCheckAll, setIsVendorsCheckAll] = useState(true);
    const [isStatusesCheckAll, setIsStatusesCheckAll] = useState(true);

    const resetFilter = () => {
        setFilter({
            vendor: defaultVendorsView,
            status: model === 'Backups' ? defaultStatusesView : defaultMigrationStatusesVIew,
            startDate: null,
            endDate: null,
            searchByName: '',
            deleted: false,
        });
    };

    const handleSelectAllVendors = () => {
        setIsVendorsCheckAll(!isVendorsCheckAll);
        setFilter({ ...filter, vendor: defaultVendorsView });
        if (isVendorsCheckAll) {
            setFilter({ ...filter, vendor: [] });
        }
    };

    const handleSelectAllStatuses = () => {
        setIsStatusesCheckAll(!isStatusesCheckAll);
        setFilter({ ...filter, status: model === 'Backups' ? defaultStatusesView : defaultMigrationStatusesVIew });
        if (isStatusesCheckAll) {
            setFilter({ ...filter, status: [] });
        }
    };

    const handleVendor = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setFilter({ ...filter, vendor: [...filter.vendor, id as VendorType] });
        if (!checked) {
            setFilter({ ...filter, vendor: filter.vendor.filter((vendor) => vendor !== id) });
        }
    };

    const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setFilter({ ...filter, status: [...filter.status, id] });
        if (!checked) {
            setFilter({ ...filter, status: filter.status.filter((status) => status !== id) });
        }
    };

    const handleStartDate = (date: MaterialUiPickersDate) => {
        setFilter({ ...filter, startDate: date });
    };

    const handleEndDate = (date: MaterialUiPickersDate) => {
        setFilter({ ...filter, endDate: date });
    };

    const handleSearchByName = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFilter({ ...filter, searchByName: e.target.value });
    };

    const handleDeleted = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter({ ...filter, deleted: !filter.deleted });
    };

    return (
        <div className={classes.filterBarContainer}>
            <SearchField searchValue={filter.searchByName!} handleSearch={handleSearchByName} />
            <Tooltip title="Filter">
                <IconButton aria-label="filter" aria-describedby={id} onClick={handleClick}>
                    <FilterList />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper className={classes.paper}>
                    <Typography>Filter</Typography>
                    <FormControl fullWidth>
                        <FormControlLabel
                            label="show deleted"
                            control={<Checkbox color="primary" checked={filter.deleted} onChange={handleDeleted} />}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        Vendor type
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filter.vendor.length === defaultVendorsView.length}
                                    onChange={handleSelectAllVendors}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {vendorTypeEntries.map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    label={item.view}
                                    control={
                                        <Checkbox
                                            id={item.value}
                                            key={item.value}
                                            color="primary"
                                            checked={filter.vendor.includes(item.value as VendorType)}
                                            onChange={handleVendor}
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </FormControl>
                    <FormControl fullWidth>
                        Status
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={
                                        model === 'Backups'
                                            ? filter.status.length === defaultStatusesView.length
                                            : filter.status.length === defaultMigrationStatusesVIew.length
                                    }
                                    onChange={handleSelectAllStatuses}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {(model === 'Backups' ? BackupStatusEntries : migrationStatusEntries).map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    label={item.view}
                                    control={
                                        <Checkbox
                                            id={item.value}
                                            key={item.value}
                                            color="primary"
                                            checked={filter.status.includes(item.value)}
                                            onChange={handleStatus}
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </FormControl>
                    {model === 'Backups' ? 'Last upload' : 'Started migrations'}
                    <FormControl className={classes.dateContainer}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                className={classes.dateItem}
                                label="Start date"
                                value={filter.startDate}
                                onChange={handleStartDate}
                                autoOk
                                clearable
                                animateYearScrolling
                                format="MM/dd/yyyy"
                            />
                            <DatePicker
                                label="End date"
                                value={filter.endDate}
                                onChange={handleEndDate}
                                autoOk
                                clearable
                                animateYearScrolling
                                format="MM/dd/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '16px' }}
                    >
                        <Button color="secondary" className={classes.buttonsItem} onClick={handleClose}>
                            Close
                        </Button>
                        <Button color="primary" variant="contained" onClick={resetFilter}>
                            Reset
                        </Button>
                    </FormControl>
                </Paper>
            </Popover>
        </div>
    );
};

import { AppSettingsState } from '../app/appsettings/reducer';
import api from './axios.main';

export enum settinsRoutes {
    settings = '/settings',
}

export const settingsApi = {
    async getSettings() {
        const res = await api.get(settinsRoutes.settings).then((res) => res.data);
        return res;
    },
    async setSettings(settings: AppSettingsState) {
        const res = await api.post(settinsRoutes.settings, settings).then((res) => res.data);
        return res;
    },
};

import { Button, Paper, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ILoginUserData } from '../../api/auth-api';
import { signInUser } from '../../app/auth/auth.actions';
import { useAppDispatch } from '../../app/hooks';
import { AppRoutes } from '../../enums';
import { useStyles } from './classes';
import { Logo, PasswordField } from './common';
import { singInValidation } from './validation';

export const Login: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const defaultValues: ILoginUserData = {
        email: '',
        password: '',
    };

    const formik = useFormik<ILoginUserData>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            dispatch(signInUser(values));
        },
        validationSchema: singInValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit } = formik;
    const { email, password } = values;

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Paper className={classes.paper}>
            <Logo />
            <form className={classes.form} onSubmit={handleSubmit}>
                <h1>Sign In</h1>
                <TextField
                    required
                    className={classes.formInput}
                    type="email"
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors?.email}
                />
                <PasswordField
                    title="Password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    showPassword={showPassword}
                    error={!!errors.password}
                    helperText={errors?.password}
                    handleShowPassword={handleClickShowPassword}
                />
                <Link className={classes.textLink} to={AppRoutes.authConfirm}>
                    Is this your first time here? Click to confirm your account.
                </Link>
                <Button variant="outlined" className={classes.button} color="primary" type="submit">
                    Sign In
                </Button>
                <Link className={classes.textLink} to={AppRoutes.resetPassword}>
                    Forgot your password?
                </Link>
            </form>
        </Paper>
    );
};

export enum GenericStatuses {
    done = 'done',
    inProgress = 'in progress',
    deleted = 'deleted',
    error = 'error',
}

export type GenericStatus = `${GenericStatuses}`;

export const defaultGenericStatusesView = Object.values(GenericStatuses);

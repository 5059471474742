import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { ChangeEvent } from 'react';

type SearchFieldType = {
    searchValue: string;
    handleSearch: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const SearchField = ({ searchValue, handleSearch }: SearchFieldType) => (
    <TextField
        type="text"
        margin="normal"
        variant="outlined"
        label="Search"
        size="small"
        fullWidth
        value={searchValue}
        onChange={handleSearch}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <Search />
                </InputAdornment>
            ),
        }}
    />
);

import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ChangeEvent, FC } from 'react';
import swLogo from '../../assets/img/swlogo.png';
import { useStyles } from './classes';

type PasswordFieldProps = {
    title: string;
    showPassword: boolean;
    error?: boolean;
    helperText?: string;
    handleShowPassword: () => void;
    name: string;
    value: string;
    onChange: (e: ChangeEvent<any>) => void;
};

export const PasswordField: FC<PasswordFieldProps> = ({
    title,
    showPassword,
    error,
    helperText,
    handleShowPassword,
    name,
    value,
    onChange,
}) => {
    const classes = useStyles();
    return (
        <TextField
            required
            className={classes.formInput}
            label={title}
            name={name}
            value={value}
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            error={error}
            helperText={helperText}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const Logo = () => {
    return (
        <div>
            <img src={swLogo} alt="ShopWare" />
        </div>
    );
};

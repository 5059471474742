import { BackupAutodelete, GeneralSettingsKeys } from '../../enums/general-settings';
import { AppSettingsActions, SET_GENERAL_SETTINGS } from './action-types';

export type AppSettingsState = {
    [key in GeneralSettingsKeys]: string;
};

const initialState: AppSettingsState = {
    BACKUP_EXPIRES_TIME: BackupAutodelete.forever,
};

export default (state: AppSettingsState = initialState, action: AppSettingsActions) => {
    switch (action.type) {
        case SET_GENERAL_SETTINGS:
            return {
                ...state,
                ...action.settings,
            };
        default:
            return state;
    }
};

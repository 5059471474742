import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    chartPieContainer: {
        maxWidth: '300px',
        maxHeight: '300px',
        margin: '10px',
    },
});

import { IconButton, Tooltip } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { ChangeEvent, createRef, FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { fetchMigrations } from '../../../app/migrations/actions';
import { defaultMigrationStatusesVIew, defaultVendorsView } from '../../../enums';
import { filterData } from '../../../helpers/filterData';
import { useData } from '../../../hooks/useData';
import { usePagination } from '../../../hooks/usePagination';
import { Migration } from '../../../model/migration';
import { FilterBar, FilterType } from '../../common/Tables/FilterBar';
import { NoMatchFound } from '../../common/Tables/NoMatchFound';
import { Loader } from '../../Loader/Loader';
import { useStyles } from './classes';
import { MigrationElement } from './MigrationElement/MigrationElement';
import { MigrationModal } from './MigrationModalAdd/MigrationModalAdd';

const UPDATE_MIGRATIONS_INTERVAL = 10000;
const PER_PAGE = 11;

const initialFilter = {
    vendor: defaultVendorsView,
    status: defaultMigrationStatusesVIew,
    startDate: null,
    endDate: null,
    searchByName: '',
    deleted: false,
};

const headerItem = ['name', 'vendor', 'status', 'started', 'duration', 'started by', 'actions'];

export const MigrationsHeader = () => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            {headerItem.map((item) => (
                <span key={item}>{item}</span>
            ))}
        </div>
    );
};

export const Migrations: FC = () => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<FilterType>(initialFilter);
    const ref = createRef<HTMLDivElement>();
    useData(UPDATE_MIGRATIONS_INTERVAL, fetchMigrations);
    const { isFetching, migrations } = useAppSelector((state) => state.migrationStore);

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const filteredMigrations = filterData(migrations, filter);

    const [page, setPage] = useState(1);
    const count = Math.ceil(filteredMigrations.length / PER_PAGE);
    const _DATA = usePagination(filteredMigrations, PER_PAGE, ref);

    const handleChange = (e: ChangeEvent<unknown>, p: number) => {
        setPage(p);
        _DATA.jump(p);
        _DATA.scroll();
    };
    useEffect(() => {
        setPage(1);
        _DATA.jump(1);
    }, [filteredMigrations.length]);

    return (
        <>
            <div ref={ref} className={classes.migrationHeader}>
                <div className={classes.addButton}>
                    <Tooltip title="Add migration">
                        <IconButton edge="end" size="small" color="primary" onClick={handleModalOpen}>
                            <AddCircle className={classes.addIcon} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
                <FilterBar filter={filter} setFilter={setFilter} model={'Migrations'} />
            </div>
            <MigrationsHeader />
            <div className={classes.migrationBody}>
                {isFetching ? (
                    <Loader />
                ) : (
                    <>
                        {_DATA.currentData().map((migration: Migration) => (
                            <MigrationElement key={migration.id} migration={migration} />
                        ))}
                        {filteredMigrations.length === 0 && !isFetching && <NoMatchFound />}
                    </>
                )}
            </div>
            {!isFetching && filteredMigrations.length !== 0 && (
                <Pagination
                    className={classes.pagination}
                    count={count}
                    size="large"
                    page={page}
                    variant="text"
                    color="primary"
                    onChange={handleChange}
                />
            )}
            <MigrationModal open={openModal} handleClose={handleModalClose} />
        </>
    );
};

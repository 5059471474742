import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    noMatchFound: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
    },
    filterBarContainer: {
        display: 'flex',
        width: '100%',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        width: '400px',
        padding: '20px',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dateItem: {
        paddingRight: '20px',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '16px',
    },
    buttonsItem: {
        marginRight: '16px',
    },
    pagination: {
        marginTop: 'auto',
    },
});

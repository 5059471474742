import { UserRolesType } from '../enums';
import { UserInterface } from '../model';
import api from './axios.main';

export enum userRoutes {
    users = '/users',
    resetUserPassword = '/users/reset',
}

export const userApi = {
    async getUsers(): Promise<UserInterface[] | null> {
        const res = await api.get(userRoutes.users).then((res) => res.data);
        return res;
    },
    async createUser(email: string, role: string) {
        const res = await api.post(userRoutes.users, { email, role }).then((res) => res.data);
        return res;
    },
    async getCurrentUser(email: string) {
        const res = await api.get(`${userRoutes.users}/${email}`).then((res) => res.data);
        return res;
    },
    async setUserSettings(id: string, options: string) {
        const res = await api.put(`${userRoutes.users}/${id}`, { options }).then((res) => res.data);
        return res;
    },
    async resetUserPassword(email: string, newPassword: string) {
        const res = await api.post(userRoutes.resetUserPassword, { email, newPassword }).then((res) => res.data);
        return res;
    },
    async disableUser(id: string) {
        const res = await api.post(`/users/${id}/disable`).then((res) => res.data);
        return res;
    },
    async enableUser(id: string) {
        const res = await api.post(`/users/${id}/enable`).then((res) => res.data);
        return res;
    },
    async changeRole(id: string, role: UserRolesType) {
        const res = await api.put(`${userRoutes.users}/${id}/${role}/role`).then((res) => res.data);
        return res;
    },
};

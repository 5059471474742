import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    statusWrapper: {
        display: 'grid',
        padding: '4px 12px',
        paddingRight: '15px',
        borderRadius: '50px',
        gridTemplateColumns: 'max-content 1fr',
        columnGap: '10px',
        alignItems: 'center',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#fff',
        '& path': {
            fill: '#fff',
        },
        maxWidth: 'max-content',
        marginLeft: '22px',
    },
    container: {
        padding: '8px 12px',
        display: 'grid',
        gridTemplateColumns: 'minmax(215px, 1fr) 70px 160px 1fr 1fr 170px 80px',
        rowGap: '40px',
        columnGap: '20px',
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginTop: '10px',
        alignItems: 'center',
        minHeight: '64px',
    },
    statusBlock: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        backgroundColor: '#fff',
        cursor: 'pointer',
        position: 'relative',
        padding: 0,
    },
    expandIcon: {
        position: 'absolute',
        left: '-10px',
        transition: 'transform .2s ease-in-out',
    },
    expandBlock: {
        padding: 0,
        gridColumn: '1 / 8',
    },
    expanded: {
        transform: 'rotate(180deg)',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    stepLabel: {
        textTransform: 'capitalize',
    },
});

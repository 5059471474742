import { ChartPie } from './ChartPie/ChartPie';
import { useStyles } from './classes';
import { Graphs } from './Graphs/Graphs';
import { Overview } from './Overview/Overview';
import { Report } from './Report/Report';
import { RunningProcesses } from './RunningProcesses/RunningProcesses';
import { ToolRealTimeInfo } from './ToolRealTimeInfo/ToolRealTimeInfo';

export const Dashboard = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Graphs />
            <Report />
            <ChartPie />
            <Overview />
            <ToolRealTimeInfo />
            <RunningProcesses />
        </div>
    );
};

import axios from 'axios';

const FILE_CHUNK_SIZE = 10_000_000; // 10 MB

export const uploadParts = async (file: File, urls: Record<number, string>) => {
    const instance = axios.create();
    delete axios.defaults.headers.put['Content-Type'];

    const keys = Object.keys(urls);
    const promises = [];

    for (const iStr of keys) {
        const i = parseInt(iStr);
        const start = i * FILE_CHUNK_SIZE;
        const end = (i + 1) * FILE_CHUNK_SIZE;
        const blob = i < keys.length ? file.slice(start, end) : file.slice(start);

        promises.push(instance.put(urls[i], blob));   
    };

    const resParts = await Promise.all(promises);
    return resParts.map((part, i) => ({
        ETag: part.headers['etag'],
        PartNumber: i + 1,
    }));
}

export const calculatePartsCount = (size: number) => Math.ceil(size / FILE_CHUNK_SIZE);

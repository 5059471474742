import { JsonsNamesType } from '../../../enums';
import { IConversion } from '../../../model';
import {
    CsvAction,
    FETCH_CONVERSIONS_FAILURE,
    SET_LIMIT_OFFSET,
    FETCH_CONVERSIONS_SUCCESS,
    SET_IS_FETCHING,
    SET_NEW_CONVERSION,
} from './actions-types';

export interface MappingType {
    destination: string;
    type?: string;
    source: string[] | string | MappingType[][];
}

export interface TemplateType {
    model: JsonsNamesType;
    mapping: MappingType[];
}

export interface CsvFiles {
    model: string | null;
    csv: File | null;
    headers: string[];
    mapping: MappingType[];
}

export interface CsvConversionsState {
    conversions: IConversion[];
    newConversion?: IConversion;
    uploadURLs?: string[];
    limit?: number;
    offset?: number;
    isFetching: boolean;
    fetchConversionsError?: Error | null;
}

const initialState: CsvConversionsState = {
    conversions: [],
    isFetching: false,
};

export const СsvConversionsReducer = (
    state: CsvConversionsState = initialState,
    action: CsvAction
): CsvConversionsState => {
    switch (action.type) {
        case SET_LIMIT_OFFSET:
            return {
                ...state,
                limit: action.limit,
                offset: action.offset,
            };
        case FETCH_CONVERSIONS_SUCCESS:
            return {
                ...state,
                conversions: action.conversions,
            };
        case SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case FETCH_CONVERSIONS_FAILURE:
            return {
                ...state,
                fetchConversionsError: action.error,
            };
        case SET_NEW_CONVERSION:
            return {
                ...state,
                newConversion: action.newConversion,
                uploadURLs: action.uploadURLs,
            };
        default:
            return state;
    }
};

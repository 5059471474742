import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Dispatch, SetStateAction } from 'react';

interface FilterBarProps {
    searchBar: string;
    setSearchBar: Dispatch<SetStateAction<string>>;
}

export const FilterBar = (props: FilterBarProps) => {
    const { searchBar, setSearchBar } = props;
    return (
        <TextField
            fullWidth
            type="text"
            margin="normal"
            variant="outlined"
            label="Search"
            size="small"
            value={searchBar}
            onChange={(e) => setSearchBar(e.currentTarget.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
        />
    );
};

import { Container, Paper } from '@material-ui/core';
import { FC, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchCurrentUser } from '../../app/user/actions';
import { useStyles } from './classes';
import { Navigation } from './Navigation/Navigation';

import { fetchGlobalSettings } from '../../app/appsettings/actions';
import { useAppDispatch } from '../../app/hooks';

export const Main: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        dispatch(fetchCurrentUser());
        dispatch(fetchGlobalSettings());
    }, [dispatch]);

    return (
        <Container className={classes.root} maxWidth="lg">
            <Navigation />
            <div className={classes.wrapper}>
                <Paper className={classes.contentContainer}>
                    <Outlet />
                </Paper>
            </div>
        </Container>
    );
};

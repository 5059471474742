export enum Vendors {
    mitchell = 'mitchell',
    pace = 'pace',
    ls = 'ls',
    roWriter = 'roWriter',
    napatracs = 'napatracs',
    identifix = 'identifix',
    protractor = 'protractor',
    identifix_xlsx = 'identifix_xlsx',
}

export type VendorType = `${Vendors}`;

export const defaultVendorsView = Object.values(Vendors);

export const VendorBackupExtension: any = {
    [Vendors.pace]: 'zip',
    [Vendors.ls]: 'zip',
    [Vendors.roWriter]: ['mdb', 'bak'],
    [Vendors.identifix]: 'zip',
    [Vendors.napatracs]: 'bak',
    [Vendors.mitchell]: 'bak',
    [Vendors.protractor]: 'bak',
    [Vendors.identifix_xlsx]: 'xlsx',
};

export const vendorTypeEntries = Object.keys(Vendors).map((v) => ({
    value: v,
    view: (Vendors as any)[v] as string,
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    root: {
        maxWidth: '600px',
        minWidth: '350px',
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'flex-start',
        zIndex: 9999,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    text: {
        marginBottom: '5px',
    },
});

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useStyles } from './classes';

type PreviewCsvPropsT = {
    headers: string[];
    body: string[][];
    model: string;
    openPreview: boolean;
    handlePreviewClose: () => void;
};

export const PreviewCsv = ({ headers, body, model, openPreview, handlePreviewClose }: PreviewCsvPropsT) => {
    const classes = useStyles();
    return (
        <Dialog maxWidth="lg" open={openPreview} onClose={handlePreviewClose}>
            <DialogTitle>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>Preview {model} CSV</div>
                    <Tooltip title="Close">
                        <IconButton edge="start" onClick={handlePreviewClose}>
                            <Clear fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent>
                <Paper className={classes.previewContainer}>
                    <TableContainer className={classes.previewTable}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, index) => (
                                        <TableCell key={index} align="right">
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {body.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {row.map((column, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        align="right"
                                                        className={classes.previewText}
                                                    >
                                                        {column ? column : '-'}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

import { AnyAction, Dispatch } from 'redux';
import { backupsApi } from '../../api/backup-api';
import { BackupSource, BackupStatus } from '../../enums';
import { Backup, UploadPart } from '../../model';
import { AppState } from '../root.reducer';
import {
    DELETE_BACKUP_FAILURE,
    DELETE_BACKUP_IN_PROGRESSS,
    DELETE_BACKUP_SUCCESS,
    FETCH_BACKUPS_FAILURE,
    FETCH_BACKUPS_IN_PROGRESS,
    FETCH_BACKUPS_SUCCESS,
    RESTORE_BACKUP_FAILURE,
    RESTORE_BACKUP_IN_PROGRESS,
    RESTORE_BACKUP_SUCCESS,
} from './action-types';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setError } from '../error/error.actions';
import { calculatePartsCount, uploadParts } from '../../helpers/backup';

const fetchBackupsInProgress = (offset?: number, limit?: number) => ({
    type: FETCH_BACKUPS_IN_PROGRESS,
    offset,
    limit,
});

const fetchBackupsSuccess = (backups: Backup[]) => ({
    type: FETCH_BACKUPS_SUCCESS,
    backups,
});

const fetchBackupsFailure = (error: Error) => ({
    type: FETCH_BACKUPS_FAILURE,
    error,
});

export const fetchBackups = (offset?: number, limit?: number) => async (dispatch: Dispatch) => {
    dispatch(fetchBackupsInProgress(offset, limit));

    try {
        const res = await backupsApi.getAllBackups(offset, limit);
        const { success, message } = res;
        if (success) {
            dispatch(fetchBackupsSuccess(message));
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(fetchBackupsFailure(error));
        dispatch(setError(error.response?.data.message || error.message));
    }
};

const restoreBackupInProgress = (id: string) => ({
    type: RESTORE_BACKUP_IN_PROGRESS,
    id,
});

const restoreBackupSuccess = (id: string) => ({
    type: RESTORE_BACKUP_SUCCESS,
    id,
});

const restoreBackupFailure = (error: Error, id: string) => ({
    type: RESTORE_BACKUP_FAILURE,
    error,
    id,
});

interface RestoreBackupActionParams {
    name: string;
    vendorType: string;
    backupSource: string;
    downloadLink: string;
    file: File | undefined;
}

export const restoreBackup =
    ({ name, vendorType, backupSource, downloadLink, file }: RestoreBackupActionParams) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
        let restoreBackupsParams;
        try {
            switch (backupSource) {
                case BackupSource.localFile:
                    restoreBackupsParams = {
                        name,
                        vendorType,
                        backupSource,
                        filename: file!.name,
                        partsCount: calculatePartsCount(file!.size),
                    };
                    break;
                case BackupSource.s3Link:
                    restoreBackupsParams = {
                        name,
                        vendorType,
                        backupSource,
                        downloadLink: downloadLink!,
                    };
                    break;
                default:
                    throw new Error(`Unknown backup source: ${backupSource}`);
            }

            const res = await backupsApi.restoreBackup(restoreBackupsParams);
            const { success, message } = res;
            const { backup, uploadId, uploadUrls } = message;
            const { id } = backup;
            if (success) {
                if (backupSource === BackupSource.localFile) {
                    dispatch(restoreBackupInProgress(id));
                    await uploadParts(file!, uploadUrls)
                        .then(async (parts: UploadPart[]) =>
                            backupsApi.completeBackupMultipartUpload({ id, uploadId, uploadParts: parts }),
                        )
                        .then(async () => {
                            await backupsApi.updateBackupStatus(
                                id,
                                BackupStatus.uploadedToCloud,
                                new Date().toISOString(),
                            );
                            dispatch(restoreBackupSuccess(id));
                        })
                        .catch(async (error: Error) => {
                            await backupsApi.abortBackupMultipartUpload({ id, uploadId, error: error.message });
                            dispatch(restoreBackupFailure(error, id));
                        });
                }

                refreshBackups(dispatch, getState);
            } else {
                throw new Error(message);
            }
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

const deleteBackupInProgress = () => ({
    type: DELETE_BACKUP_IN_PROGRESSS,
});

const deleteBackupSuccess = () => ({
    type: DELETE_BACKUP_SUCCESS,
});

const deleteBackupFailure = (error: Error) => ({
    type: DELETE_BACKUP_FAILURE,
    error,
});

export const deleteBackup =
    (...ids: string[]) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(deleteBackupInProgress());

        try {
            await Promise.all(ids.map((id) => backupsApi.deleteBackup(id)));
            dispatch(deleteBackupSuccess());
            refreshBackups(dispatch, getState);
        } catch (error: any) {
            dispatch(deleteBackupFailure(error));
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

export const refreshBackups = (dispatch: Dispatch, getState: () => AppState) => {
    const { backupStore } = getState();
    const { offset, limit } = backupStore;
    (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(fetchBackups(offset, limit));
};

import { Memory, IConversion, Migration } from '../../model';
import {
    DashboardAction,
    SET_AVERAGE_BACKUP_SIZE,
    SET_CONVERSIONS_BY_DATE_RANGE,
    SET_IS_FETCHING,
    SET_IS_REPORT_CONVERTING,
    SET_MIGRATIONS_BY_DATE_RANGE,
    SET_SYSTEM_INFO,
} from './action-types';

export interface DashboardState {
    migrations: Migration[];
    conversions: IConversion[];
    averageBackupSize: number | null;
    isFetching: boolean;
    cpu: number;
    disk: Memory;
    ram: Memory;
    isReportConverting: boolean;
}

const initialState: DashboardState = {
    migrations: [],
    conversions: [],
    averageBackupSize: null,
    isFetching: false,
    cpu: 0,
    disk: {
        total: '',
        used: '',
    },
    ram: {
        total: '',
        used: '',
    },
    isReportConverting: false,
};

export const DashboardReducer = (state: DashboardState = initialState, action: DashboardAction): DashboardState => {
    switch (action.type) {
        case SET_MIGRATIONS_BY_DATE_RANGE:
            return {
                ...state,
                migrations: action.migrations,
            };
        case SET_CONVERSIONS_BY_DATE_RANGE:
            return {
                ...state,
                conversions: action.conversions,
            };
        case SET_AVERAGE_BACKUP_SIZE:
            return {
                ...state,
                averageBackupSize: action.averageBackupSize,
            };
        case SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case SET_IS_REPORT_CONVERTING:
            return {
                ...state,
                isReportConverting: action.isReportConverting,
            };
        case SET_SYSTEM_INFO:
            return {
                ...state,
                cpu: action.cpu,
                disk: action.disk,
                ram: action.ram,
            };
        default:
            return state;
    }
};

import { ICreateTemplate } from "../api/csv-api";
import { MappingType } from "../app/csv/templates/reducer";
import { JsonFieldTypes } from "../enums";

export const checkCsvFieldValue = (template: ICreateTemplate) => {
  let isFill: boolean[] = [];
            for (let currentItem of template.template) {
                for (let currentMapping of currentItem.mapping) {
                    if (currentMapping?.type === JsonFieldTypes.objectsArray) {
                        let b = currentMapping.source as MappingType[][];
                        let c = b.find((item) => item.find((item) => item.source));
                        if (c) {
                            isFill.push(true);
                            break;
                        }
                    }
                    if (
                        (currentMapping?.type === JsonFieldTypes.stringConcat &&
                            typeof currentMapping.source === 'object' &&
                            currentMapping.source.length !== 0) ||
                        (currentMapping?.type === JsonFieldTypes.intArray && typeof currentMapping.source === 'object' && currentMapping.source.length !== 0)
                    ) {
                        isFill.push(true);
                        break;
                    }
                    if (
                        currentMapping.source !== '' &&
                        currentMapping?.type !== JsonFieldTypes.stringConcat &&
                        currentMapping?.type !== JsonFieldTypes.objectsArray
                    ) {
                        isFill.push(true);
                        break;
                    }
                }
            }
            return isFill.length !== template.template.length;
}
import { REMOVE_SUCCESS, SET_SUCCESS } from './success.types';

export const setSuccess = (message: string) => ({
    type: SET_SUCCESS,
    message,
});

export const removeSuccess = () => ({
    type: REMOVE_SUCCESS,
});

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { ChangeEvent, FC, useState } from 'react';
import { MappingType } from '../../../../app/csv/templates/reducer';
import { JsonFieldTypes } from '../../../../enums';

type PropsType = {
    isOpen: boolean;
    close: () => void;
    board: MappingType;
};

export const DefaultFieldModal: FC<PropsType> = ({ isOpen, close, board }) => {
    const [defaultField, setDefaultField] = useState('');
    const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => setDefaultField(e.target.value);
    const addDefaultValueToBoard = () => {
        board.source = defaultField;
        board.type = JsonFieldTypes.defaultValue;
        close();
    };
    return (
        <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={close} aria-labelledby="add-default-field">
            <DialogTitle>Add default field value</DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    label="Default field value"
                    placeholder="Enter default field here"
                    type="text"
                    autoFocus
                    onChange={handleChangeField}
                    value={defaultField}
                    fullWidth
                />
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        Close
                    </Button>
                    <Button
                        onClick={addDefaultValueToBoard}
                        color="primary"
                        variant="contained"
                        disabled={!defaultField}
                    >
                        Add default field value
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

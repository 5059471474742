import { setSuccess } from './../../success/success.actions';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import { csvApi } from '../../../api/csv-api';
import { IConversion } from '../../../model';
import { setError } from '../../error/error.actions';
import { AppState } from '../../root.reducer';
import {
    FETCH_CONVERSIONS_FAILURE,
    FETCH_CONVERSIONS_SUCCESS,
    SET_IS_FETCHING,
    SET_LIMIT_OFFSET,
    SET_NEW_CONVERSION,
} from './actions-types';

const setIsFetching = (isFetching: boolean) => ({
    type: SET_IS_FETCHING,
    isFetching,
});

export const setLimitOffset = (offset?: number, limit?: number) => ({
    type: SET_LIMIT_OFFSET,
    offset,
    limit,
});

export const fetchConversionsSuccess = (conversions: IConversion[]) => ({
    type: FETCH_CONVERSIONS_SUCCESS,
    conversions,
});

export const fetchConversionsFailure = (error: Error) => ({
    type: FETCH_CONVERSIONS_FAILURE,
    error,
});

export const fetchConversions =
    (offset?: number, limit?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setLimitOffset(offset, limit));
        const { conversions } = getState().csvConversionsStore;
        if (conversions.length === 0) dispatch(setIsFetching(true));
        try {
            const res = await csvApi.getAllConversions(offset, limit);
            const { success, message } = res;
            if (success) {
                dispatch(fetchConversionsSuccess(message));
                dispatch(setIsFetching(false));
            } else {
                throw new Error(message);
            }
        } catch (error: any) {
            dispatch(fetchConversionsFailure(error));
            dispatch(setError(error.response?.data?.message || error.message));
        }
        dispatch(setIsFetching(false));
    };

export const refreshConversions = (dispatch: Dispatch, getState: () => AppState) => {
    const { limit, offset } = getState().csvConversionsStore;
    (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(fetchConversions(offset, limit));
};

export const deleteConversions = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const res = await csvApi.deleteConversionById(id);
        const { success, message } = res;
        if (success) refreshConversions(dispatch, getState);
        else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export type CreateConversion = {
    id: string;
    files: { model: string; name: string }[];
    csvFiles: File[];
};

export const setNewConversion = (newConversion: IConversion, uploadURLs: string[]) => ({
    type: SET_NEW_CONVERSION,
    newConversion,
    uploadURLs,
});

export const createConversion =
    ({ id, files, csvFiles }: CreateConversion) =>
    async (dispatch: any) => {
        try {
            const res = await csvApi.createConversion({ id, files });
            const { success, message } = res;
            if (success) {
                const { conversion, urlRes } = message;
                const promises = urlRes.map((url: any, index: number) => csvApi.uploadCsv(url.url, csvFiles[index]));
                Promise.all(promises)
                    .then(() => dispatch(completeUploadCsv(conversion.id)))
                    .then(() => dispatch(runConversion({ id: conversion.id })));
            } else throw new Error(message);
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

export const completeUploadCsv = (id: string) => async (dispatch: Dispatch) => {
    try {
        const res = await csvApi.completeUploadCsvToS3(id);
        const { success, message } = res;
        if (success) dispatch(setSuccess('Csv files is uploaded!'));
        else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export type RunConversion = {
    id: string;
};

export const runConversion =
    ({ id }: RunConversion) =>
    async (dispatch: Dispatch, getState: () => AppState) => {
        let runConversionParams;
        try {
            runConversionParams = {
                id,
            };
            const res = await csvApi.runConversion(runConversionParams);
            const { success, message } = res;
            if (success) refreshConversions(dispatch, getState);
            else throw new Error(message);
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

export const downloadOutputJsons = (id: string) => async (dispatch: Dispatch) => {
    try {
        const res = await csvApi.getOutputDownloadLink(id);
        const { success, message } = res;
        if (success) {
            const link = document.createElement('a');
            link.href = message;
            document.body.appendChild(link);
            link.click();
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

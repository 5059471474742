export enum AppRoutes {
    auth = '/auth',
    authLogin = '/auth/login',
    authConfirm = '/auth/confirm',
    resetPassword = '/auth/reset',
    main = '/main',
    migrations = '/main/migrations',
    users = '/main/users',
    settings = '/main/settings',
    backups = '/main/backups',
    health = '/main/health',
    csv = '/main/csv',
    dashboard = '/main/dashboard',
}

enum NavigationRoutes {
    migrations = '/main/migrations',
    backups = '/main/backups',
    csv = '/main/csv',
    health = '/main/health',
    settings = '/main/settings',
    users = '/main/users',
    dashboard = '/main/dashboard',
}

const NavigationRoutesView: any = {
    [NavigationRoutes.migrations]: 'Migrations',
    [NavigationRoutes.users]: 'Users',
    [NavigationRoutes.backups]: 'Backups',
    [NavigationRoutes.health]: 'Health Check',
    [NavigationRoutes.settings]: 'Settings',
    [NavigationRoutes.csv]: 'CSV',
    [NavigationRoutes.dashboard]: 'Dashboard',
};

export const navigationRoutesEntries = Object.keys(NavigationRoutes).map((v) => {
    const value = (NavigationRoutes as any)[v];
    const view = NavigationRoutesView[value];
    return { value, view };
});

export enum AppRouteNames {
    auth = 'auth',
    authLogin = 'login',
    authConfirm = 'confirm',
    reset = 'reset',
    main = 'main',
    migrations = 'migrations',
    users = 'users',
    settings = 'settings',
    backups = 'backups',
    code = 'code',
    health = 'health',
    csv = 'csv',
    root = '/',
    dashboard = 'dashboard',
}

export enum BackupStatus {
    uploadingToCloud = 'UPLOADING_TO_CLOUD',
    uploadedToCloud = 'UPLOADED_TO_CLOUD',
    extracting = 'EXTRACTING',
    extracted = 'EXTRACTED',
    error = 'ERROR',
    deleteQueued = 'DELETE_QUEUED',
    deleteInProgress = 'DELETE_IN_PROGRESS',
    deleteError = 'DELETE_ERROR',
    deleted = 'DELETED',
}

export enum BackupStatusColor {
    failed = 'rgb(242, 70, 70)',
    success = 'rgb(4, 155, 74)',
    processing = 'rgb(127, 127, 127)',
}

export type BackupStatusType = `${BackupStatus}`;

export const defaultStatusesView = Object.values(BackupStatus);

export const BackupStatusView: any = {
    [BackupStatus.uploadingToCloud]: 'Uploading to cloud',
    [BackupStatus.uploadedToCloud]: 'Uploaded to cloud',
    [BackupStatus.extracting]: 'Extracting',
    [BackupStatus.extracted]: 'Extracted',
    [BackupStatus.error]: 'Error',
    [BackupStatus.deleteQueued]: 'Delete queued',
    [BackupStatus.deleteInProgress]: 'Delete In Progress',
    [BackupStatus.deleteError]: 'Delete Error',
    [BackupStatus.deleted]: 'Deleted',
};

export const BackupStatusEntries = Object.keys(BackupStatusView).map((v) => {
    const value = v;
    const view = BackupStatusView[value];
    return { value, view };
});

import { useStyles } from './classes';

type Title = {
    title?: string;
};

export const NoMatchFound = ({ title }: Title) => {
    const classes = useStyles();
    return <div className={classes.noMatchFound}>{title ? title : 'No match found'}</div>;
};

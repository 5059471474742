import { FC } from 'react';
import { useStyles } from './classes';
import { CheckCircle, Error, QueryBuilder, SyncAlt, RemoveCircle, Cancel, DeleteForever } from '@material-ui/icons';
import { MigrationStatus, MigrationStatusView } from '../../../../enums/migration-status';

enum Backgrounds {
    error = 'rgb(242, 70, 70)',
    done = 'rgb(4, 155, 74)',
    default = 'rgb(127 127 127)',
}

export type StatusComponentProps = {
    status: string;
};

export const StatusElement: FC<StatusComponentProps> = ({ status }) => {
    const renderSwitchIcon = () => {
        switch (status) {
            case MigrationStatus.validationError:
            case MigrationStatus.error:
                return <Error />;
            case MigrationStatus.done:
                return <CheckCircle />;
            case MigrationStatus.inProgress:
                return <QueryBuilder />;
            case MigrationStatus.queued:
                return <SyncAlt />;
            case MigrationStatus.stopped:
                return <RemoveCircle />;
            case MigrationStatus.canceled:
                return <Cancel />;
            case MigrationStatus.deleted:
                return <DeleteForever />;
            default:
                return null;
        }
    };

    const switchBgColor = () => {
        switch (status) {
            case MigrationStatus.validationError:
            case MigrationStatus.error:
            case MigrationStatus.deleted:
                return Backgrounds.error;
            case MigrationStatus.done:
                return Backgrounds.done;
            default:
                return Backgrounds.default;
        }
    };

    const classes = useStyles();
    return (
        <div className={classes.statusWrapper} style={{ backgroundColor: `${switchBgColor()}` }}>
            {renderSwitchIcon()} {MigrationStatusView[status as MigrationStatus]}
        </div>
    );
};

import { FC } from 'react';
import { useStyles } from './classes';

type MigrationErrorProps = {
    error?: string;
};

export const MigrationError: FC<MigrationErrorProps> = ({ error }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <p>{error}</p>
        </div>
    );
};

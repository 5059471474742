import { Alert } from '@material-ui/lab';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './classes';

type NotificationProps = {
    message: string;
    removeMessage: () => void;
    typeMessage: 'error' | 'success';
};

export const Notification: FC<NotificationProps> = ({ message, removeMessage, typeMessage }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleAlertClose = () => {
        dispatch(removeMessage() as any);
    };

    useEffect(() => {
        const timeFade = setTimeout(() => {
            handleAlertClose();
        }, 3000);
        return () => {
            clearTimeout(timeFade);
        };
    }, [dispatch]);

    return (
        <Alert severity={typeMessage} onClose={handleAlertClose} className={classes.root}>
            <div className={classes.container}>
                <span className={classes.text}>{message}</span>
            </div>
        </Alert>
    );
};

import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: 'Required field',
    },
    string: {
        min: 'Enter at least ${min} characters',
        max: 'Maximum ${max} characters',
    },
});

export const newPasswordValidation = yup.object().shape({
    newPassword: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
});

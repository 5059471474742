import { CognitoUserPool } from 'amazon-cognito-identity-js';

export const apiConfig = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  clientId: process.env.REACT_APP_CLIENT_ID || '',
};

export const userPool = new CognitoUserPool({
  UserPoolId: apiConfig.userPoolId,
  ClientId: apiConfig.clientId,
});
 
import { User, UserInterface, UserSettings } from '../../model';
import { userApi } from '../../api/user-api';
import { setError } from '../error/error.actions';
import {
    DISABLE_USER,
    ENABLE_USER,
    SET_CURRENT_USER,
    SET_IS_FETCHING,
    SET_USERS,
    SET_USER_SETTINGS,
} from './action-types';
import { authApi } from '../../api/auth-api';
import { AppState } from '../root.reducer';
import { parseUserOptions } from '../../helpers/formatters';
import { setSuccess } from '../success/success.actions';
import { UserRolesType } from '../../enums';
import { AppDispatch } from '../store';

const setCurrentUser = (currentUser: User) => ({
    type: SET_CURRENT_USER,
    currentUser,
});

const setUsers = (users: UserInterface[]) => ({
    type: SET_USERS,
    users,
});

const setUserSettings = (settings: UserSettings) => ({
    type: SET_USER_SETTINGS,
    settings,
});

const setIsFetching = (isFetching: boolean) => ({
    type: SET_IS_FETCHING,
    isFetching,
});

const disableUserAction = (user: User) => ({
    type: DISABLE_USER,
    disabledUser: user,
});

const enableUserAction = (user: User) => ({
    type: ENABLE_USER,
    enabledUser: user,
});

export const fetchUsers = () => async (dispatch: AppDispatch) => {
    dispatch(setIsFetching(true));
    const users = await userApi.getUsers().then((data) => {
        if (data) return data;
    });
    dispatch(setUsers(users as UserInterface[]));
    dispatch(setIsFetching(false));
};

export const fetchCurrentUser = () => async (dispatch: AppDispatch) => {
    dispatch(setIsFetching(true));
    try {
        const email = await authApi.getCurrentUserName();
        const res = await userApi.getCurrentUser(email);
        const { success, message } = res;
        if (success) {
            const { user } = message;
            dispatch(setCurrentUser(user));
            const options = parseUserOptions(user as User);
            dispatch(setUserSettings(options));
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
    dispatch(setIsFetching(false));
};

export const patchUserSettings = (settings: UserSettings) => async (dispatch: AppDispatch, getState: () => AppState) => {
    const { currentUser } = getState().userStore;
    if (currentUser) {
        try {
            const parsedOptions = JSON.stringify(settings);
            const res = await userApi.setUserSettings(currentUser.id, parsedOptions);
            const { success, message } = res;
            if (success) {
                dispatch(setCurrentUser(message));
                const options = parseUserOptions(message as User);
                dispatch(setUserSettings(options));
                dispatch(setSuccess('Migrations settings saved successfully!'));
            } else throw new Error(message);
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    } else {
        dispatch(setError('Current User Is Not Defined'));
    }
};

export const changePassByAdmin = (email: string, newPassword: string) => async (dispatch: AppDispatch) => {
    try {
        await userApi.resetUserPassword(email, newPassword);
        dispatch(setSuccess('Password change was successful!'));
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const disableUser = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const { message: user } = await userApi.disableUser(id);
        dispatch(disableUserAction(user));
    } catch (error: any) {
        dispatch(setError(error.response?.data.message || error.message));
    }
};

export const enableUser = (id: string) => async (dispatch: AppDispatch) => {
    try {
        const { message: user } = await userApi.enableUser(id);
        dispatch(enableUserAction(user));
    } catch (error: any) {
        dispatch(setError(error.response?.data.message || error.message));
    }
};

export const refreshUsers = (dispatch: AppDispatch) => {
    dispatch(fetchUsers());
};

export const changeUserRole = (id: string, role: UserRolesType) => async (dispatch: AppDispatch) => {
    try {
        const res = await userApi.changeRole(id, role);
        const { success, message } = res;
        if (success) {
            dispatch(setSuccess('The user role changed successfully!'));
            refreshUsers(dispatch);
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data.message || error.message));
    }
};

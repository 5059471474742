import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { convertMemoryStatus } from '../../../../helpers/formatters';
import { Memory } from '../../../../model';

ChartJS.register(ArcElement, Tooltip, Legend);

type MemoryStatus = {
    memory: Memory;
    title: string;
    labels: string[]
};

export const MemoryStatus = ({ memory, labels, title }: MemoryStatus) => {
    const {dimension, freeGb, freePercentage, used, usedPercentage, usedToNumber} = convertMemoryStatus(memory)
    const data = {
        labels: labels,
        datasets: [
            {
                label: '# of Votes',
                data: [usedToNumber, freeGb],
                backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
                borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                borderWidth: 1,
                hoverOffset: 15,
            },
        ],
    };
    return (
        <div>
            <Doughnut
                data={data}
                options={{
                    plugins: {
                        legend: {
                            position: 'bottom',
                            onClick: () => {
                                return;
                            },
                        },
                        title: {
                            display: true,
                            text: title,
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    return context.label === labels[0]
                                        ? `${context.label}: ${used} (${usedPercentage.toFixed(2)}%)`
                                        : `${context.label}: ${freeGb.toFixed(
                                              2
                                          )} ${dimension} (${freePercentage.toFixed(2)}%)`;
                                },
                            },
                        },
                    },
                    responsive: true,
                }}
            />
        </div>
    );
};

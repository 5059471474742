import DateFnsUtils from '@date-io/date-fns';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ChangeEvent, FC, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchBackups } from '../../../../app/backup/actions';
import { setError } from '../../../../app/error/error.actions';
import { useAppDispatch } from '../../../../app/hooks';
import { runMigration } from '../../../../app/migrations/actions';
import { AppState } from '../../../../app/root.reducer';
import {
    BackupStatus,
    includedJSONsNames,
    MigrationModel,
    MigrationModelView,
    OutputJSONs,
    outputJSONsEntries,
    OutputJSONsType,
    Vendors,
    vendorTypeEntries,
    WorkOrderDateRange,
    workOrderEntries,
    WorkOrderType,
} from '../../../../enums';
import { convertDateToDateISO } from '../../../../helpers/formatters';
import { removeCannedJobsModel } from '../../../../helpers/migrations';
import { Backup, BackupInfo, MigrationOptios } from '../../../../model';
import { SelectorForm } from '../../../common/Forms/SelectorForm';
import { useStyles } from './classes';

export type MigrationModalProps = {
    open: boolean;
    handleClose: () => void;
};

export const MigrationModal: FC<MigrationModalProps> = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { default_vendor, chunk_size } = useSelector((state: AppState) => state.userStore);
    const [vendor, setVendor] = useState<string>(default_vendor);
    const [restoreBackup, setRestoreBackup] = useState<Backup | null>(null);
    const [storeId, setStoreId] = useState<string | null>(null);
    const [workOrder, setWorkOrder] = useState<WorkOrderType>(WorkOrderDateRange.allTime);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [outputJSONs, setOutputJSONs] = useState<OutputJSONsType>(OutputJSONs.all);
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [isCheck, setIsCheck] = useState<string[]>(includedJSONsNames);

    useEffect(() => {
        setVendor(default_vendor);
    }, [default_vendor]);

    const closeModal = () => {
        handleClose();
        setVendor(default_vendor);
        setStartDate(null);
        setEndDate(null);
        setIsCheck(includedJSONsNames);
        setWorkOrder(WorkOrderDateRange.allTime);
        setOutputJSONs(OutputJSONs.all);
        setRestoreBackup(null);
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(includedJSONsNames.map((li) => li));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setIsCheck([id, ...isCheck]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };

    const { backups } = useSelector((state: AppState) => state.backupStore);
    useLayoutEffect(() => {
        dispatch(fetchBackups());
    }, []);

    const changeVendor = (event: React.ChangeEvent<any>) => {
        setVendor(event.target.value);
        setRestoreBackup(null);
        setStoreId(null);
    };

    const changeOutpusJSONs = (e: React.ChangeEvent<any>) => setOutputJSONs(e.target.value);
    const changeWorkOrder = (e: React.ChangeEvent<any>) => setWorkOrder(e.target.value);

    const filtered_bakups = backups.filter((backup) => {
        return backup.vendor_type.toUpperCase() === vendor.toUpperCase() && backup.status === BackupStatus.extracted;
    });

    const handleStart = () => {
        if (!restoreBackup) dispatch(setError('Select a backup for migration'));
        else if (workOrder === WorkOrderDateRange.specificTime && (!startDate || !endDate))
            dispatch(setError('Start end time is required!'));
        else {
            let { name, id: backupId } = restoreBackup;
            const migrationOptions: MigrationOptios = {
                chunk_size,
            };
            if (startDate && endDate && workOrder === WorkOrderDateRange.specificTime) {
                const specific_time = {
                    start: convertDateToDateISO(startDate),
                    end: convertDateToDateISO(endDate),
                };
                migrationOptions.specific_time = specific_time;
            }
            if (outputJSONs === OutputJSONs.custom && includedJSONsNames.length !== isCheck.length) {
                migrationOptions.not_included_JSONs = includedJSONsNames.filter(
                    (i) => isCheck.indexOf(i) === -1 && i !== MigrationModelView.Inventory_Without_Quantity,
                );
                migrationOptions.inventory_with_quantity =
                    isCheck.indexOf(MigrationModelView.Inventory_Without_Quantity) === -1;
            }
            if (storeId) {
                migrationOptions.store_id = storeId;
                name = `${name} (Store ID-${storeId})`;
            }
            dispatch(runMigration({ name, backupId, options: JSON.stringify(migrationOptions) }));
            closeModal();
        }
    };

    return (
        <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Add Migration</DialogTitle>
            <DialogContent>
                <SelectorForm
                    title="Vendor type"
                    value={vendor}
                    values={vendorTypeEntries}
                    changeValue={changeVendor}
                />
                <SelectorForm
                    title="Included in conversion"
                    value={outputJSONs}
                    values={outputJSONsEntries}
                    changeValue={changeOutpusJSONs}
                />
                {outputJSONs === OutputJSONs.custom && (
                    <FormControl>
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={isCheck.length === includedJSONsNames.length}
                                    onChange={handleSelectAll}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {includedJSONsNames.map((item) => {
                                if (item === MigrationModel.cannedJobs && removeCannedJobsModel(vendor as Vendors))
                                    return null;
                                return (
                                    <FormControlLabel
                                        key={item}
                                        // label={item === 'Inventory_With_Quantity' ? item : MigrationModelView[item]}
                                        label={MigrationModelView[item]}
                                        control={
                                            <Checkbox
                                                id={item}
                                                key={item}
                                                color="primary"
                                                checked={isCheck.includes(item)}
                                                onChange={handleSelect}
                                            />
                                        }
                                    />
                                );
                            })}
                        </Box>
                    </FormControl>
                )}
                <SelectorForm
                    title="Work Order Date Range"
                    value={workOrder}
                    values={workOrderEntries}
                    changeValue={changeWorkOrder}
                />
                {workOrder === WorkOrderDateRange.specificTime && (
                    <FormControl fullWidth className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                clearable
                                label="Start"
                                format="MM/dd/yyyy"
                                value={startDate}
                                onChange={setStartDate}
                                animateYearScrolling
                            />
                            <KeyboardDatePicker
                                autoOk
                                clearable
                                format="MM/dd/yyyy"
                                label="End"
                                value={endDate}
                                onChange={setEndDate}
                                animateYearScrolling
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                )}
                <FormControl margin="normal" fullWidth className={classes.formControl}>
                    <Autocomplete
                        options={filtered_bakups}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        value={restoreBackup}
                        onChange={(event: any, newValue: Backup | null) => {
                            setRestoreBackup(newValue);
                            setStoreId(null);
                        }}
                        renderInput={(params) => <TextField {...params} label="Backup" variant="outlined" />}
                    />
                </FormControl>
                {vendor && vendor === Vendors.pace && (
                    <FormControl margin="normal" fullWidth className={classes.formControl}>
                        <Autocomplete
                            disabled={Boolean(!restoreBackup)}
                            value={storeId}
                            options={
                                restoreBackup && restoreBackup.info
                                    ? (JSON.parse(restoreBackup.info) as BackupInfo)?.storeIds || []
                                    : []
                            }
                            fullWidth
                            onChange={(event: any, newValue: string | null) => {
                                setStoreId(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Store ID" variant="outlined" />}
                        />
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
                <Button onClick={handleStart} color="primary" variant="contained">
                    Start
                </Button>
            </DialogActions>
        </Dialog>
    );
};

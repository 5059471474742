import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../..';
import { fetchGlobalSettings } from '../../../app/appsettings/actions';
import { useAppDispatch } from '../../../app/hooks';
import { AppState } from '../../../app/root.reducer';
import { fetchCurrentUser } from '../../../app/user/actions';
import { UserRoles } from '../../../enums';
import { GeneralSettingsForm } from './Forms/GeneralSettingsForm';
import { MigrationSettingsForm } from './Forms/MigrationSettingsForm';
import { PasswordChangeForm } from './Forms/PasswordChangeForm';

export const Settings: FC = () => {
    const dispatch = useAppDispatch();
    const { currentUser, chunk_size, backup_source, default_vendor, isFetching } = useSelector(
        (store: AppState) => store.userStore
    );
    const { BACKUP_EXPIRES_TIME } = useSelector((store: AppState) => store.settingsStore);
    useEffect(() => {
        dispatch(fetchCurrentUser());
        dispatch(fetchGlobalSettings());
    }, [dispatch]);
    return (
        <>
            {isFetching ? (
                <Loader />
            ) : (
                <>
                    <MigrationSettingsForm
                        chunk_size={chunk_size}
                        backup_source={backup_source}
                        default_vendor={default_vendor}
                    />
                    {currentUser?.role === UserRoles.admin && (
                        <GeneralSettingsForm BACKUP_EXPIRES_TIME={BACKUP_EXPIRES_TIME} />
                    )}
                    <PasswordChangeForm />
                </>
            )}
        </>
    );
};

import { ChangeEvent, useState } from 'react';
import { CSVTabs, CSVTabsType } from '../../../enums';
import { Conversions } from './Conversions/Conversions';
import { CreateTemplate } from './CreateTemplate/CreateTemplate';
import { TabsPanel } from './CSVTabs/Tabs';
import { Templates } from './Templates/Templates';

export const CSV = () => {
    const [csvTab, setCsvTab] = useState<CSVTabsType>(CSVTabs.allTemplates);
    const handleChangeTabs = (event: ChangeEvent<{}>, newTab: CSVTabsType | null) => {
        if (newTab !== null) {
            setCsvTab(newTab);
        }
    };

    const switchTab = () => {
        switch (csvTab) {
            case CSVTabs.createTemplate:
                return <CreateTemplate setCsvTab={setCsvTab} />;
            case CSVTabs.allTemplates:
                return <Templates csvTab={csvTab} setCsvTab={setCsvTab} />;
            case CSVTabs.conversions:
                return <Conversions csvTab={csvTab} setCsvTab={setCsvTab} />;
            default:
                return null;
        }
    };
    return (
        <>
            <TabsPanel csvTab={csvTab} handleChangeTabs={handleChangeTabs} />
            {switchTab()}
        </>
    );
};

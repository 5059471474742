import { IconButton, Tooltip } from '@material-ui/core';
import { CancelOutlined, Delete, ExpandMore, Replay } from '@material-ui/icons';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { format } from 'timeago.js';
import { migrationApi } from '../../../../api/migration-api';
import { useAppDispatch } from '../../../../app/hooks';
import {
    cancelMigration,
    deleteMigration,
    downloadOutputJsons,
    restartMigration,
    stopMigration
} from '../../../../app/migrations/actions';
import { MigrationStatus } from '../../../../enums/migration-status';
import { calculateDuration, emailToUserName, shortEnglishHumanizer } from '../../../../helpers/formatters';
import { deletedStyle } from '../../../../helpers/styles';
import { Migration } from '../../../../model';
import { DownloadGroup } from '../../../common/DownloadGroup';
import { Loader } from '../../../Loader/Loader';
import { MigrationError } from '../MigrationError/MigrationError';
import { useStyles } from './classes';
import { ProgressBar } from './ProgressBar';
import { StatusElement } from './StatusElement';

export const STOPPABLE_STATUSES = [MigrationStatus.restoring, MigrationStatus.inProgress] as string[];
export const CANCELABLE_STATUS = MigrationStatus.queued;
enum CancelButtonTarget {
    stop = 'STOP',
    cancel = 'CANCEL',
}

export type MigrationElementProps = {
    migration: Migration;
};

export const MigrationElement: FC<MigrationElementProps> = ({ migration }) => {
    const {
        status,
        name,
        backup_vendor_type,
        start_ts,
        end_ts,
        created_by_email,
        id,
        error,
        current_model,
        options,
        deleted,
    } = migration;
    const [expand, setExpand] = useState<boolean>(false);
    const [isCoppied, setIsCoppied] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const duration = calculateDuration(start_ts, end_ts);

    const handleExpandClick = () => {
        setExpand(!expand);
    };

    const handleDownloadButton = () => {
        dispatch(downloadOutputJsons(id, name));
    };

    const handleDeleteButton = () => {
        dispatch(deleteMigration(id));
    };

    const handleLinkCopyClick = async () => {
        const res = await migrationApi.getOutputDownloadLink(id);
        const { message } = res;
        navigator.clipboard.writeText(message);
        setIsCoppied(true);
        setTimeout(() => {
            setIsCoppied(false);
        }, 2500);
    };

    const cancelButtonTarget =
        status === CANCELABLE_STATUS
            ? CancelButtonTarget.cancel
            : STOPPABLE_STATUSES.indexOf(status) !== -1
            ? CancelButtonTarget.stop
            : null;

    const handleCancelButton = () => {
        switch (cancelButtonTarget) {
            case CancelButtonTarget.stop:
                return () => dispatch(stopMigration(id));
            case CancelButtonTarget.cancel:
                return () => dispatch(cancelMigration(id));
        }
    };

    const handleRestartButton = async () => {
        setIsFetching(true);
        await dispatch(restartMigration(id));
        setIsFetching(false);
    };
    const classes = useStyles();

    return (
        <>
            {isFetching ? (
                <Loader />
            ) : (
                <div className={classes.container} style={{ opacity: deletedStyle(deleted) }}>
                    <span>{name}</span>
                    <span>{backup_vendor_type}</span>
                    <button className={classes.statusBlock} type="button" onClick={handleExpandClick}>
                        <ExpandMore className={clsx(classes.expandIcon, expand && classes.expanded)} fontSize="small" />
                        <StatusElement status={status} />
                    </button>
                    <span>{format(start_ts as string) || '-'}</span>
                    <span>
                        {(status === MigrationStatus.done || status === MigrationStatus.validationError
                            ? shortEnglishHumanizer(duration, { round: true, largest: 1 })
                            : '-') || '-'}
                    </span>
                    <span>{emailToUserName(created_by_email)}</span>
                    {!deleted && (
                        <div className={classes.actionsContainer}>
                            {(status === MigrationStatus.done) && (
                                <DownloadGroup
                                    handleDownloadButton={handleDownloadButton}
                                    handleLinkCopyClick={handleLinkCopyClick}
                                    isCoppied={isCoppied}
                                />
                            )}
                            {(status === MigrationStatus.validationError) && (
                                <DownloadGroup
                                    handleDownloadButton={handleDownloadButton}
                                    handleLinkCopyClick={handleLinkCopyClick}
                                    isCoppied={isCoppied}
                                />
                            )}
                            {cancelButtonTarget && (
                                <Tooltip title={cancelButtonTarget === CancelButtonTarget.stop ? 'Stop' : 'Cancel'}>
                                    <IconButton edge="start" onClick={handleCancelButton()}>
                                        <CancelOutlined />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {status === MigrationStatus.error && (
                                <Tooltip title="Restart">
                                    <IconButton onClick={handleRestartButton}>
                                        <Replay />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="Delete">
                                <IconButton onClick={handleDeleteButton}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}

                    {expand && (
                        <ProgressBar
                            status={status}
                            model={current_model}
                            error={error}
                            options={options}
                            vendor={backup_vendor_type}
                        />
                    )}
                    {expand && error && <MigrationError error={error} />}
                </div>
            )}
        </>
    );
};

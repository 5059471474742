import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles({
    header: {
        display: 'grid',
        gridTemplateColumns: '265px 1fr 1fr 1fr 100px',
        columnGap: '20px',
        backgroundColor: '#eaeaea',
        padding: '8px',
        borderRadius: '4px',
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    pagination: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
    },
    content: {
        minHeight: 'calc(100vh - 104px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
});

import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import BackupIcon from '@material-ui/icons/Backup';
import { useStyles } from './classes';
import { FilterBar, FilterType } from '../../../common/Tables/FilterBar';

interface ToolBarProps {
    numSelected: number;
    onDelete: () => void;
    setIsModalOpen: (value: boolean) => void;
    filter: FilterType;
    setFilter: Dispatch<SetStateAction<FilterType>>;
}

export const ToolBar = (props: ToolBarProps) => {
    const classes = useStyles();
    const { numSelected, onDelete, filter, setFilter } = props;

    return (
        <Toolbar className={clsx(classes.toolbar)}>
            <Tooltip title="Restore Backup">
                <IconButton
                    className={classes.openRestoreBackupModalButton}
                    size="small"
                    color="primary"
                    onClick={() => props.setIsModalOpen(true)}
                >
                    <BackupIcon className={classes.restoreBackupIcon} fontSize="large" />
                </IconButton>
            </Tooltip>
            {numSelected > 0 && (
                <Typography color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton className={classes.deleteBackupButton} aria-label="delete" onClick={onDelete}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <FilterBar filter={filter} setFilter={setFilter} model={'Backups'} />
            )}
        </Toolbar>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    cardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    card: {
        width: '162px',
        height: '100px',
        borderRadius: '29px',
        marginRight: '18px',
        marginBottom: '10px',
        boxShadow: '4px 6px 11px 1px rgba(0, 0, 0, 0.15)',
        position: 'relative',
    },
    inputFile: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    cardActiv: {
        boxShadow: '4px 6px 11px 1px #FFDFD3',
    },
    cardAction: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    runBtn: {
        borderRadius: '42px',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    accordion: {
        marginBottom: '16px',
        boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
    },
    accordionTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '20px',
        lineHeight: '21px',
        color: '#757575',
        letterSpacing: '0.1px',
        textTransform: 'capitalize',
    },
    accordionSubtitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '21px',
        color: '#757575',
        letterSpacing: '0.1px',
        textTransform: 'capitalize',
        marginBottom: '12px',
    },
    dragElement: {
        height: '20px',
        background: '#C4C4C4',
        borderRadius: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px',
        display: 'flex',
        alignItems: 'center',
        color: '#050505',
        margin: '4px',
        padding: '8px',
        cursor: 'move',
    },
    dragsElContainer: {
        maxHeight: '300px',
        width: '600px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'scroll',
        overflowX: 'hidden',
    },
    dropBoard: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '22px',
        margin: '5px 0 5px',
    },
    phonesItems: {
        marginTop: '40px',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        margin: 'auto 0',
    },
    previewContainer: {
        width: '100%',
        overflow: 'hidden',
    },
    previewTable: {
        maxHeight: '440px',
    },
    previewText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
    },
});

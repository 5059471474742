import { Paper } from '@material-ui/core';
import { addMonths, endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { fetchMigrationsByDateRange } from '../../../../app/dashboard/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { defaultMigrationStatusesVIew, defaultVendorsView, Vendors, VendorType } from '../../../../enums';
import { filterMigrationsByCustomRange } from '../../../../helpers/dateRange';
import { Migration } from '../../../../model';
import { FilterType } from '../../../common/Tables/FilterBar';
import { FilterChart } from './FilterChart';
import { LineChart } from './LineChart';

const backgroundColor = (vendor: string) => {
    switch (vendor) {
        case Vendors.mitchell:
            return 'rgba(255, 143, 107, 0.4)';
        case Vendors.pace:
            return 'rgba(14, 159, 110, 0.4)';
        case Vendors.ls:
            return 'rgba(225, 20, 217, 0.4)';
        case Vendors.roWriter:
            return 'rgba(224, 238, 60, 0.4)';
        case Vendors.napatracs:
            return 'rgba(36, 70, 219, 0.4)';
        default:
            return 'rgba(255, 143, 107, 0.4)';
    }
};

const borderColor = (vendor: string) => {
    switch (vendor) {
        case Vendors.mitchell:
            return 'rgba(255, 143, 107, 1)';
        case Vendors.pace:
            return 'rgba(14, 159, 110, 1)';
        case Vendors.ls:
            return 'rgba(225, 20, 217, 1)';
        case Vendors.roWriter:
            return 'rgba(224, 238, 60, 1)';
        case Vendors.napatracs:
            return 'rgba(36, 70, 219, 1)';
        default:
            return 'rgba(255, 143, 107, 1)';
    }
};

const filterMigrations = (data: Migration[], filter: FilterType) => {
    let sortedData = data;
    sortedData = data.filter((dataItem) => filter.vendor.includes(dataItem?.backup_vendor_type as VendorType));

    sortedData = sortedData.filter((dataItem) => filter.status.includes(dataItem.status));
    return sortedData;
};

const initialFilter = {
    vendor: defaultVendorsView,
    status: defaultMigrationStatusesVIew,
};

export const Graphs = () => {
    const dispatch = useAppDispatch();
    const [start, setStart] = useState<Date>(startOfMonth(addMonths(new Date(), -1)));
    const [end, setEnd] = useState<Date>(new Date());
    const [filter, setFilter] = useState<FilterType>(initialFilter);
    const { migrations } = useAppSelector((state) => state.dashboardStore);
    let { dates, startDate, endDate, labels, title, isRangeByMouths } = filterMigrationsByCustomRange(start, end);
    const filteredMigrations = filterMigrations(migrations, filter);
    const changeData = () => {
        return {
            labels,
            datasets: filter.vendor.map((vendor) => ({
                label: vendor,
                data: dates.map((date) => {
                    return filteredMigrations.filter((migration) => {
                        if (isRangeByMouths) {
                            return (
                                migration.backup_vendor_type === vendor &&
                                Date.parse(migration.start_ts) >= Date.parse(startOfMonth(date).toISOString()) &&
                                Date.parse(migration.start_ts) <= Date.parse(endOfMonth(date).toISOString())
                            );
                        } else {
                            return (
                                migration.backup_vendor_type === vendor &&
                                Date.parse(migration.createdAt) >= Date.parse(startOfDay(date).toISOString()) &&
                                Date.parse(migration.createdAt) <= Date.parse(endOfDay(date).toISOString())
                            );
                        }
                    }).length;
                }),
                borderColor: borderColor(vendor),
                backgroundColor: backgroundColor(vendor),
                yAxisID: 'y',
            })),
        };
    };
    const [data, setData] = useState(changeData());
    useEffect(() => {
        dispatch(fetchMigrationsByDateRange(startDate, endDate));
        setData(changeData());
    }, [startDate, endDate]);
    useEffect(() => {
        setData(changeData());
    }, [filter.vendor.length, filter.status.length, migrations]);
    return (
        <Paper>
            <FilterChart setFilter={setFilter} start={start} setStart={setStart} end={end} setEnd={setEnd} />
            <LineChart title={title} data={data} />
        </Paper>
    );
};

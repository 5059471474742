import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import { csvApi, ICreateTemplate } from '../../../api/csv-api';
import { ITemplate } from '../../../model';
import { setError } from '../../error/error.actions';
import { AppState } from '../../root.reducer';
import { createConversion } from '../conversions/actions';
import {
    FETCH_TEMPLATES_FAILURE,
    SET_LIMIT_OFFSET,
    FETCH_TEMPLATES_SUCCESS,
    SET_IS_FETCHING,
    SET_CREATED_TEMPLATE,
    SET_IS_TEMPLATE_CREATED,
} from './actions-types';

const setIsFetching = (isFetching: boolean) => ({
    type: SET_IS_FETCHING,
    isFetching,
});

const setLimitOffset = (offset?: number, limit?: number) => ({
    type: SET_LIMIT_OFFSET,
    offset,
    limit,
});

const fetchTemplatesSuccess = (templates: ITemplate[]) => ({
    type: FETCH_TEMPLATES_SUCCESS,
    templates: templates,
});

const fetchTemplatesFailure = (error: Error) => ({
    type: FETCH_TEMPLATES_FAILURE,
    error,
});

const setCreatedTemplate = (createdTemplate: ITemplate) => ({
    type: SET_CREATED_TEMPLATE,
    createTemplate,
});

const setIsTemplateCreated = (isTemplateCreated: boolean) => ({
    type: SET_IS_TEMPLATE_CREATED,
    isTemplateCreated,
});

export const fetchTemplates =
    (offset?: number, limit?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
        dispatch(setLimitOffset(offset, limit));
        const { templates } = getState().csvTemplatesStore;
        if (templates.length === 0) dispatch(setIsFetching(true));
        try {
            const res = await csvApi.getAllTemplates(offset, limit);
            const { success, message } = res;
            if (success) {
                dispatch(fetchTemplatesSuccess(message));
            } else {
                throw new Error(message);
            }
        } catch (error: any) {
            dispatch(fetchTemplatesFailure(error));
            dispatch(setError(error.response?.data?.message || error.message));
        }
        setIsFetching(false);
    };

export const refreshTemplates = (dispatch: Dispatch, getState: () => AppState) => {
    const { limit, offset } = getState().csvTemplatesStore;
    (dispatch as ThunkDispatch<AppState, unknown, AnyAction>)(fetchTemplates(offset, limit));
};

export const deleteTemplate = (id: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const res = await csvApi.deleteTemplateById(id);
        const { success, message } = res;
        if (success) refreshTemplates(dispatch, getState);
        else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const createTemplate = (params: ICreateTemplate) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const res = await csvApi.createTemplate(params);
        const { success, message } = res;
        if (success) {
            dispatch(setCreatedTemplate(message));
            dispatch(setIsTemplateCreated(true));
            refreshTemplates(dispatch, getState);
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
        dispatch(setIsTemplateCreated(false));
    }
};

export const updateTemplate =
    (id: string, newTemplate: ICreateTemplate) => async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            const res = await csvApi.updateTemplateById(id, newTemplate);
            const { success, message } = res;
            if (success) {
                refreshTemplates(dispatch, getState);
            } else throw new Error(message);
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.message));
        }
    };

export const createTemplateAndRun =
    (params: ICreateTemplate, files: { model: string; name: string }[], csvFiles: File[]) =>
    async (dispatch: any, getState: () => AppState) => {
        try {
            const res = await csvApi.createTemplate(params);
            const { success, message } = res;
            if (success) {
                const { id } = message;
                dispatch(setCreatedTemplate(message));
                dispatch(setIsTemplateCreated(true));
                refreshTemplates(dispatch, getState);
                dispatch(createConversion({ id, files, csvFiles }));
            } else throw new Error(message);
        } catch (error: any) {
            dispatch(setError(error.response?.data?.message || error.response));
        }
    };

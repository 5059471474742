import classes from './loader.module.css';
import { FC } from 'react';

export const Loader: FC = () => {
  return (
    <div className={classes.ldsEllipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

import {
    Box,
    Button,
    Card,
    CardActionArea,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography
} from '@material-ui/core';
import { CheckCircleOutline, PostAdd } from '@material-ui/icons';
import clsx from 'clsx';
import Papa from 'papaparse';
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Loader } from '../../..';
import { createTemplate, createTemplateAndRun, updateTemplate } from '../../../../app/csv/templates/actions';
import { CsvFiles, MappingType } from '../../../../app/csv/templates/reducer';
import { setError } from '../../../../app/error/error.actions';
import { useAppDispatch } from '../../../../app/hooks';
import { CSVTabs, CSVTabsType, jsonsNamesEntries, JsonsNamesType } from '../../../../enums';
import { checkCsvFieldValue } from '../../../../helpers/checkCsvFieldValue';
import { cloneObj } from '../../../../helpers/cloneObj';
import { convertToDefaultValue } from '../../../../helpers/convertToDefaultValue';
import { initialMapping } from '../../../../model';
import { useStyles } from './classes';
import { MappingTable } from './MappingTable';

type PropsType = {
    setCsvTab: Dispatch<SetStateAction<CSVTabsType>>;
    templateName?: string;
    editedTemplate?: {
        model: JsonsNamesType;
        mapping: MappingType[];
    }[];
    templateId?: string;
    isEdit?: boolean;
    close?: () => void;
};

export const CreateTemplate: FC<PropsType> = ({
    setCsvTab,
    templateName,
    editedTemplate,
    templateId,
    isEdit,
    close,
}) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    useEffect(() => {
        setCsvFiles(
            editedTemplate?.map((item) => ({
                model: item.model,
                headers: [],
                mapping: item.mapping,
                csv: null,
                body: [],
            })) || []
        );
        setIncludedJsons(editedTemplate?.map((item) => item.model) || []);
        setPhonesMapping([Array.from([...cloneObj(initialMapping.phones)])]);
    }, []);

    const [isFetching, setIsFetching] = useState(false);
    const [name, setName] = useState<string>(templateName || '');
    const changeName = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
    };
    const [includedJsons, setIncludedJsons] = useState<JsonsNamesType[]>(
        editedTemplate?.map((item) => item.model) || []
    );
    const [csvFiles, setCsvFiles] = useState<CsvFiles[]>(
        editedTemplate?.map((item) => ({
            model: item.model,
            headers: [],
            mapping: item.mapping,
            csv: null,
            body: [],
        })) || []
    );
    const [phonesMapping, setPhonesMapping] = useState<any[][]>([Array.from([...cloneObj(initialMapping.phones)])]);
    const handleAddPhoneItem = () => {
        setPhonesMapping([...phonesMapping, Array.from([...cloneObj(initialMapping.phones)])]);
    };
    const handleDeletePhoneItem = (indexDel: number) => {
        setPhonesMapping(phonesMapping.filter((item, index) => indexDel !== index));
    };
    const handleSelectJsons = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setIncludedJsons([...includedJsons, id as JsonsNamesType]);
        setCsvFiles([
            ...csvFiles,
            {
                model: id,
                csv: null,
                headers: [],
                mapping: cloneObj(initialMapping[id as JsonsNamesType]),
                body: [],
            },
        ]);
        if (!checked) {
            setIncludedJsons(includedJsons.filter((json) => json !== id));
            setCsvFiles(csvFiles.filter((csv) => csv.model !== id));
        }
    };

    const handleUploadCsv = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];
            const name = e.target.name;
            Papa.parse(file, {
                preview: 5,
                complete: (results) => {
                    setCsvFiles(
                        csvFiles.map((item) => {
                            if (item.model === name) {
                                return {
                                    ...item,
                                    csv: file as File,
                                    headers: results.data[0] as string[],
                                    body: results.data.slice(1) as string[][],
                                };
                            } else {
                                return item;
                            }
                        })
                    );
                },
            });
        }
    };

    const createCurrentTemplate = () => {
        const template = {
            name: name,
            vendorType: null,
            template: csvFiles.map((item) => ({
                model: item.model as JsonsNamesType,
                mapping: item.mapping.map((item) =>
                    item.destination === 'phones'
                        ? { ...item, source: phonesMapping.map((item) => convertToDefaultValue(item)) }
                        : item
                ),
            })),
        };
        return template;
    };

    const createPreviewTemplate = () => {
        const template = createCurrentTemplate();
        const optimizedTemplate = {
            ...template,
            template: template.template.map((item) => ({
                model: item.model as JsonsNamesType,
                mapping: convertToDefaultValue(item.mapping),
            })),
        };
        return optimizedTemplate;
    };

    const handleCreateTemplate = async () => {
        const template = createCurrentTemplate();
        const files = csvFiles.map((item) => ({ model: item.model!, name: item.csv?.name }));
        if (files.length !== csvFiles.length || files.length === 0 || csvFiles.find((item) => item.csv === null))
            return dispatch(setError('Upload CSV files!'));
        if (checkCsvFieldValue(template)) {
            return dispatch(setError('Fill in at least one field for each selected model!'));
        }
        if (!name) {
            return dispatch(setError('Enter template name!'));
        } else {
            setIsFetching(true);
            const optimizedTemplate = {
                ...template,
                template: template.template.map((item) => ({
                    model: item.model as JsonsNamesType,
                    mapping: convertToDefaultValue(item.mapping),
                })),
            };
            await dispatch(createTemplate(optimizedTemplate));
            close && close();
            setCsvFiles([]);
            setIncludedJsons([]);
            setIsFetching(false);
            setCsvTab(CSVTabs.allTemplates);
        }
    };

    const handleSaveEditTemplate = async () => {
        const template = createCurrentTemplate();
        const files = csvFiles.map((item) => ({ model: item.model!, name: item.csv?.name }));
        if (files.length !== csvFiles.length || files.length === 0 || csvFiles.find((item) => item.csv === null))
            return dispatch(setError('Upload CSV files!'));
        if (checkCsvFieldValue(template)) {
            return dispatch(setError('Fill in at least one field for each selected model!'));
        }
        if (!name) {
            return dispatch(setError('Enter template name!'));
        } else {
            setIsFetching(true);
            const optimizedTemplate = {
                ...template,
                template: template.template.map((item) => ({
                    model: item.model as JsonsNamesType,
                    mapping: convertToDefaultValue(item.mapping),
                })),
            };
            await dispatch(updateTemplate(templateId!, optimizedTemplate));
            close && close();
            setCsvFiles([]);
            setIncludedJsons([]);
            setIsFetching(false);
            setCsvTab(CSVTabs.allTemplates);
        }
    };

    const handleSaveAndRun = async () => {
        const template = createCurrentTemplate();
        const files = csvFiles.map((item) => ({ model: item.model!, name: item.csv?.name }));
        const filescsv = csvFiles.map((item) => item.csv!);
        if (files.length !== csvFiles.length || files.length === 0 || csvFiles.find((item) => item.csv === null))
            return dispatch(setError('Upload CSV files!'));
        if (checkCsvFieldValue(template)) {
            return dispatch(setError('Fill in at least one field for each selected model!'));
        }
        if (!name) {
            return dispatch(setError('Enter template name!'));
        } else {
            setIsFetching(true);
            const optimizedTemplate = {
                ...template,
                template: template.template.map((item) => ({
                    model: item.model as JsonsNamesType,
                    mapping: convertToDefaultValue(item.mapping),
                })),
            };
            await dispatch(
                createTemplateAndRun(
                    optimizedTemplate,
                    files as {
                        model: string;
                        name: string;
                    }[],
                    filescsv
                )
            );
            setIsFetching(false);
            setCsvTab(CSVTabs.conversions);
        }
    };
    return (
        <>
            <Box style={{ marginTop: '12px' }}>
                <TextField
                    required
                    label="Template name"
                    id="template name"
                    value={name}
                    onChange={changeName}
                    helperText="Enter template name"
                />
            </Box>
            <Box>
                {jsonsNamesEntries.map((jsonName) => (
                    <FormControlLabel
                        key={jsonName}
                        label={jsonName}
                        control={
                            <Checkbox
                                id={jsonName}
                                key={jsonName}
                                name={jsonName}
                                color="primary"
                                checked={includedJsons.includes(jsonName as JsonsNamesType)}
                                onChange={handleSelectJsons}
                            />
                        }
                    />
                ))}
            </Box>
            <Box className={classes.cardsContainer}>
                {includedJsons.map((json) => {
                    return (
                        <Card
                            key={json}
                            className={clsx(
                                classes.card,
                                csvFiles.find((csv) => {
                                    return csv.model === json;
                                })?.csv && classes.cardActiv
                            )}
                        >
                            <CardActionArea className={classes.cardAction}>
                                {!csvFiles.find((csv) => {
                                    return csv.model === json;
                                })?.csv && (
                                    <label className={classes.inputFile}>
                                        <input
                                            accept=".csv"
                                            onChange={handleUploadCsv}
                                            name={json}
                                            type="file"
                                            hidden
                                        />
                                    </label>
                                )}
                                {csvFiles.find((csv) => csv.model === json)?.csv ? (
                                    <>
                                        <CheckCircleOutline color="primary" fontSize="large" />
                                        <Typography variant="body2" color="primary" component="h5">
                                            {json}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <PostAdd fontSize="large" color="secondary" />
                                        <Typography variant="body2" color="textSecondary" component="h5">
                                            Upload {json} CSV
                                        </Typography>
                                    </>
                                )}
                            </CardActionArea>
                        </Card>
                    );
                })}
            </Box>
            <Box>
                {csvFiles.map((csv) => {
                    if (!csv.csv) {
                        return null;
                    } else {
                        return (
                            <MappingTable
                                key={csv.model}
                                csv={csv.csv}
                                model={csv.model}
                                mapping={csv.mapping}
                                headers={csv.headers}
                                body={csv.body}
                                setCsvFiles={setCsvFiles}
                                phonesMapping={phonesMapping}
                                handleAddPhoneItem={handleAddPhoneItem}
                                handleDeletePhoneItem={handleDeletePhoneItem}
                                createPreviewTemplate={createPreviewTemplate}
                            />
                        );
                    }
                })}
            </Box>
            {isEdit ? (
                <Box style={{ marginTop: '10px', marginBottom: '16px' }}>
                    <Button
                        className={classes.runBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEditTemplate}
                    >
                        Save template
                    </Button>
                    <Button
                        className={classes.runBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleCreateTemplate}
                        style={{ marginLeft: '12px' }}
                    >
                        Save as new template
                    </Button>
                </Box>
            ) : (
                <Box style={{ marginTop: '10px', marginBottom: '16px' }}>
                    <Button
                        className={classes.runBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleCreateTemplate}
                    >
                        Save template
                    </Button>
                    <Button
                        className={classes.runBtn}
                        variant="contained"
                        color="primary"
                        onClick={handleSaveAndRun}
                        style={{ marginLeft: '12px' }}
                    >
                        Save template and run conversion
                    </Button>
                </Box>
            )}

            {isFetching && <Loader />}
        </>
    );
};

import { Card, CardContent, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../app/hooks';
import { useStyles } from './classes';

export const CpuStatus = () => {
    const classes = useStyles();
    const { cpu } = useAppSelector((state) => state.dashboardStore);
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography gutterBottom align="center" variant="h6" component="h2">
                    CPU usage
                </Typography>
                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                    {cpu ? `${cpu.toFixed(1)}%` : '-'}
                </Typography>
            </CardContent>
        </Card>
    );
};

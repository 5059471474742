import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Popover,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { defaultVendorsView, Vendors, vendorTypeEntries } from '../../../../enums';
import { defaultGenericStatusesView, GenericStatuses } from '../../../../enums/dashboard';
import { useChartsFiltering } from '../../../../hooks/useChartsFiltering';

type Filter = {
    setFilter: Dispatch<
        SetStateAction<{
            status: GenericStatuses[];
            vendor: Vendors[];
        }>
    >;
};

export const Filter = ({ setFilter }: Filter) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { handleSelectAllStatuses, handleSelectAllVendors, handleStatus, handleVendor, statuses, vendors } =
        useChartsFiltering('pie');
    const changeDataByFilter = () => {
        setFilter({ vendor: vendors, status: statuses as GenericStatuses[] });
    };
    return (
        <div>
            <Tooltip title="Filter">
                <IconButton aria-label="filter" aria-describedby={id} onClick={handleClick}>
                    <FilterList />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Paper style={{ padding: '12px' }}>
                    <Typography>Filter</Typography>
                    <FormControl fullWidth>
                        Vendor type
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={vendors.length === defaultVendorsView.length}
                                    onChange={handleSelectAllVendors}
                                />
                            }
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        {vendorTypeEntries.map((item) => (
                            <FormControlLabel
                                key={item.value}
                                label={item.view}
                                control={
                                    <Checkbox
                                        id={item.value}
                                        key={item.value}
                                        color="primary"
                                        checked={vendors.includes(item.value as Vendors)}
                                        onChange={handleVendor}
                                    />
                                }
                            />
                        ))}
                    </Box>
                    <FormControl fullWidth>
                        Status
                        <FormControlLabel
                            label="all"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={statuses.length === defaultGenericStatusesView.length}
                                    onChange={handleSelectAllStatuses}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            {defaultGenericStatusesView.map((item) => (
                                <FormControlLabel
                                    key={item}
                                    label={item}
                                    control={
                                        <Checkbox
                                            id={item}
                                            key={item}
                                            color="primary"
                                            checked={(statuses as GenericStatuses[]).includes(item as GenericStatuses)}
                                            onChange={handleStatus}
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    </FormControl>
                    <FormControl
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            marginTop: '16px',
                        }}
                    >
                        <Button color="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button color="primary" variant="contained" onClick={changeDataByFilter}>
                            Search
                        </Button>
                    </FormControl>
                </Paper>
            </Popover>
        </div>
    );
};

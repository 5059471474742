import { REMOVE_ERROR, SET_ERROR } from './error.types';

export const setError = (message: string) => ({
    type: SET_ERROR,
    message,
});

export const removeError = () => ({
    type: REMOVE_ERROR,
});

import { Migration } from '../../model';

export const DELETE_MIGRATION = 'DELETE_MIGRATION';
export const SET_MIGRATIONS = 'SET_MIGRATIONS';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_LIMIT_OFFSET = 'SET_LIMIT_OFFSET';

export const STOP_MIGRATION_IN_PROGRESS = 'STOP_MIGRATION_IN_PROGRESS';
export const STOP_MIGRATION_SUCCESS = 'STOP_MIGRATION_SUCCESS';
export const STOP_MIGRATION_FAILURE = 'STOP_MIGRATION_FAILURE';

export const CANCEL_MIGRATION_IN_PROGRESS = 'CANCEL_MIGRATION_IN_PROGRESS';
export const CANCEL_MIGRATION_SUCCESS = 'CANCEL_MIGRATION_SUCCESS';
export const CANCEL_MIGRATION_FAILURE = 'CANCEL_MIGRATION_FAILURE';

interface SetIsFetching {
    type: 'SET_IS_FETCHING';
    isFetching: boolean;
}

interface SetLimitOffset {
    type: 'SET_LIMIT_OFFSET';
    offset?: number;
    limit?: number;
}

interface SetMigrations {
    type: 'SET_MIGRATIONS';
    migrations: Migration[];
}

interface StopMigrationInProgress {
    type: 'STOP_MIGRATION_IN_PROGRESS';
}

interface StopMigrationSuccess {
    type: 'STOP_MIGRATION_SUCCESS';
}

interface StopMigrationFailure {
    type: 'STOP_MIGRATION_FAILURE';
    error: Error;
}

interface CancelMigrationInProgress {
    type: 'CANCEL_MIGRATION_IN_PROGRESS';
}

interface CancelMigrationSuccess {
    type: 'CANCEL_MIGRATION_SUCCESS';
}

interface CancelMigrationFailure {
    type: 'CANCEL_MIGRATION_FAILURE';
    error: Error;
}

export type MigrationAction =
    | SetMigrations
    | SetIsFetching
    | SetLimitOffset
    | StopMigrationInProgress
    | StopMigrationSuccess
    | StopMigrationFailure
    | CancelMigrationInProgress
    | CancelMigrationSuccess
    | CancelMigrationFailure;

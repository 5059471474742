import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { BackupStatus, ConversionStatus, MigrationStatus, Vendors } from '../../../../enums';
import { GenericStatuses } from '../../../../enums/dashboard';
import { Backup, IConversion, Migration } from '../../../../model';
import { useStyles } from './classes';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

type ChatPie = {
    datasets: Migration[] | Backup[] | IConversion[];
    title: string;
    filter: {
        status: GenericStatuses[];
        vendor: Vendors[];
    };
};

const backgroundColor = (status: string) => {
    switch (status) {
        case GenericStatuses.done:
            return 'rgba(74, 243, 15, 0.4)';
        case GenericStatuses.inProgress:
            return 'rgba(98, 110, 217, 0.4)';
        case GenericStatuses.deleted:
            return 'rgba(237, 132, 35, 0.4)';
        case GenericStatuses.error:
            return 'rgba(250, 4, 19, 0.4)';
        default:
            return 'rgba(98, 110, 217, 0.4)';
    }
};

const borderColor = (status: string) => {
    switch (status) {
        case GenericStatuses.done:
            return 'rgba(74, 243, 15, 1)';
        case GenericStatuses.inProgress:
            return 'rgba(98, 110, 217, 1)';
        case GenericStatuses.deleted:
            return 'rgba(237, 132, 35, 1)';
        case GenericStatuses.error:
            return 'rgba(250, 4, 19, 1)';
        default:
            return 'rgba(98, 110, 217, 1)';
    }
};

export const ChartPieByStatus = ({ datasets, title, filter }: ChatPie) => {
    const classes = useStyles();
    let filteredData: any[] = [];
    if (title !== 'Csv conversions') {
        filteredData = (datasets as any[]).filter((dataItem) =>
            filter.vendor.includes(dataItem?.backup_vendor_type || dataItem?.vendor_type)
        );
    } else {
        filteredData = datasets;
    }
    const labels = filter.status;
    const sumItems = filteredData.length;
    let values: any = {};
    filter.status.forEach((item) => {
        values = { ...values, [item]: 0 };
    });
    filteredData.forEach((data) => {
        switch (data.status) {
            case MigrationStatus.done:
            case BackupStatus.extracted:
            case ConversionStatus.finished:
                values[GenericStatuses.done]++;
                break;
            case MigrationStatus.inProgress:
            case MigrationStatus.queued:
            case MigrationStatus.restoring:
            case BackupStatus.uploadingToCloud:
            case BackupStatus.uploadedToCloud:
            case BackupStatus.extracting:
            case ConversionStatus.queued:
            case ConversionStatus.uploadingInput:
            case ConversionStatus.inputUploaded:
            case ConversionStatus.processing:
                values[GenericStatuses.inProgress]++;
                break;
            case MigrationStatus.deleted:
            case BackupStatus.deleted:
            case ConversionStatus.deleted:
                values[GenericStatuses.deleted]++;
                break;
            case MigrationStatus.error:
            case BackupStatus.error:
            case ConversionStatus.error:
                values[GenericStatuses.error]++;
                break;
            default:
                break;
        }
    });

    const data = {
        labels: labels,
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(values),
                backgroundColor: labels.map((label) => backgroundColor(label)),
                borderColor: labels.map((label) => borderColor(label)),
                borderWidth: 1,
                hoverOffset: 15,
            },
        ],
    };

    return (
        <div>
            <Pie
                className={classes.chartPieContainer}
                data={data}
                options={{
                    plugins: {
                        legend: {
                            position: 'bottom',
                            onClick: () => {
                                return;
                            },
                        },
                        title: {
                            display: true,
                            text: `${title} by status`,
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let index = context.dataIndex;
                                    return `${context.label}: ${context.dataset.data[index]} (${(
                                        (context.dataset.data[index] / sumItems) *
                                        100
                                    ).toFixed(1)})%`;
                                },
                            },
                        },
                    },
                    responsive: true,
                }}
            />
        </div>
    );
};

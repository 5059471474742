import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Checkbox, FormControlLabel, Paper, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { downloadReport } from '../../../../app/dashboard/actions';
import { useAppDispatch } from '../../../../app/hooks';
import { MigrationStatus, migrationStatusEntries, Vendors, vendorTypeEntries } from '../../../../enums';
import { convertShortDate } from '../../../../helpers/formatters';
import { useChartsFiltering } from '../../../../hooks/useChartsFiltering';
import { useStyles } from './classes';

export const Report = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [from, setFrom] = useState<Date>(addDays(new Date(), -7));
    const [to, setTo] = useState<Date>(new Date());
    const handleStartDate = (date: MaterialUiPickersDate) => {
        setFrom(date!);
    };
    const handleEndDate = (date: MaterialUiPickersDate) => {
        setTo(date!);
    };
    const { handleVendor, handleStatus, vendors, statuses } = useChartsFiltering('line');
    const handleDownloadreport = () => {
        dispatch(
            downloadReport({
                from: convertShortDate(from),
                to: convertShortDate(to),
                vendors,
                statuses: statuses as MigrationStatus[],
            })
        );
    };

    const btnIsDisabled = () => {
        return !statuses.length || !vendors.length ? true : false;
    };
    return (
        <Paper className={classes.container}>
            <h3 style={{ textAlign: 'center' }}>Export report as csv</h3>
            <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        label="From"
                        value={from}
                        onChange={handleStartDate}
                        autoOk
                        animateYearScrolling
                        format="MM/dd/yyyy"
                        style={{ width: '150px', paddingRight: '12px' }}
                    />
                    <DatePicker
                        label="To"
                        value={to}
                        onChange={handleEndDate}
                        autoOk
                        animateYearScrolling
                        format="MM/dd/yyyy"
                        style={{ width: '150px' }}
                    />
                </MuiPickersUtilsProvider>
            </Box>
            <Box style={{ marginTop: '12px' }}>
                <Typography>Vendors:</Typography>
                {vendorTypeEntries.map((vendor) => {
                    return (
                        <FormControlLabel
                            key={vendor.value}
                            label={vendor.view}
                            control={
                                <Checkbox
                                    id={vendor.value}
                                    key={vendor.value}
                                    color="primary"
                                    checked={vendors.includes(vendor.value as Vendors)}
                                    onChange={handleVendor}
                                />
                            }
                        />
                    );
                })}
            </Box>
            <Box>
                <Typography>Statuses:</Typography>
                {migrationStatusEntries.map((status) => {
                    return (
                        <FormControlLabel
                            key={status.value}
                            label={status.view}
                            control={
                                <Checkbox
                                    id={status.value}
                                    key={status.value}
                                    color="primary"
                                    checked={(statuses as MigrationStatus[]).includes(status.value as MigrationStatus)}
                                    onChange={handleStatus}
                                />
                            }
                        />
                    );
                })}
            </Box>
            <Button color="primary" onClick={handleDownloadreport} disabled={btnIsDisabled()} variant="contained">
                Download
            </Button>
        </Paper>
    );
};

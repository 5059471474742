import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { MouseEvent } from 'react';
import { Order } from '../../../helpers/tables';
import { Backup, UserInterface } from '../../../model';
import { useStyles } from './classes';

export type Property = {
    property: keyof UserInterface | keyof Backup;
};

export type HeadCell = {
    disablePadding: boolean;
    id: keyof UserInterface | keyof Backup | string;
    label: string;
};

export interface EnhancedTableProps {
    numSelected?: number;
    onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
    showSelectAllCheckbox?: boolean;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    const classes = useStyles();
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, showSelectAllCheckbox } =
        props;
    const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {showSelectAllCheckbox && (
                    <TableCell align="center" padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected! > 0 && numSelected! < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                )}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

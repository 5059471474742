import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { patchUserSettings } from '../../../../app/user/actions';
import { backupSourceEntries, vendorTypeEntries } from '../../../../enums';
import { DefaultVendor, UploadMethod } from '../../../../model';
import { useStyles } from './classes';
import { SaveButton } from './SaveButton';

type SelectPropsT = {
    data: { value: string; view: string }[];
    value: string;
    onChange: (e: ChangeEvent<any>) => void;
    title: string;
    name: string;
};

const CustomSelect: FC<SelectPropsT> = ({ data, value, onChange, title, name }) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.textInput}>
            <InputLabel>{title}</InputLabel>
            <Select name={name} value={value} onChange={onChange}>
                {data.map((entry) => (
                    <MenuItem key={entry.value} value={entry.value}>
                        {entry.view}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

type PropsType = {
    chunk_size: number;
    backup_source: UploadMethod;
    default_vendor: DefaultVendor;
};

export const MigrationSettingsForm: FC<PropsType> = ({ chunk_size, backup_source, default_vendor }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [isFetching, setIsFetching] = useState(false);
    const settingsFormik = useFormik({
        initialValues: {
            chunk_size,
            backup_source,
            default_vendor,
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsFetching(true);
            await dispatch(patchUserSettings(values));
            setIsFetching(false);
        },
    });
    return (
        <div className={classes.settingsBlock}>
            <form onSubmit={settingsFormik.handleSubmit} className={classes.settingsBlock}>
                <h3>Migraton settings</h3>
                <TextField
                    className={classes.textInput}
                    label="Chunk size"
                    type="number"
                    name="chunk_size"
                    value={settingsFormik.values.chunk_size}
                    onChange={settingsFormik.handleChange}
                    error={settingsFormik.touched.backup_source && Boolean(settingsFormik.errors.chunk_size)}
                    helperText={
                        (settingsFormik.touched.chunk_size && settingsFormik.errors.chunk_size) ||
                        'The number of records in a single JSON file'
                    }
                />
                <CustomSelect
                    data={vendorTypeEntries}
                    value={settingsFormik.values.default_vendor}
                    onChange={settingsFormik.handleChange}
                    title="Default Vendor"
                    name="default_vendor"
                />
                <CustomSelect
                    data={backupSourceEntries}
                    value={settingsFormik.values.backup_source}
                    onChange={settingsFormik.handleChange}
                    title="Default backup source"
                    name="backup_source"
                />
                <SaveButton isFetching={isFetching} />
            </form>
        </div>
    );
};

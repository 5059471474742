import { createStore, applyMiddleware } from 'redux';
import { ThunkAction, Action, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { thunk } from 'redux-thunk';
import { AppState, rootReducer } from './root.reducer';

const middlewares = [thunk];
const middlewareEnchancer = applyMiddleware(...middlewares);
export const store = createStore(rootReducer, composeWithDevTools(middlewareEnchancer));

export type AppDispatch = ThunkDispatch<AppState, unknown, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { Dispatch } from 'redux';
import { dashboardApi, ReportExportingToCsv } from '../../api/dashboard-api';
import { IConversion, Migration, Memory } from '../../model';
import { setError } from '../error/error.actions';
import {
    SET_MIGRATIONS_BY_DATE_RANGE,
    SET_AVERAGE_BACKUP_SIZE,
    SET_CONVERSIONS_BY_DATE_RANGE,
    SET_IS_FETCHING,
    SET_IS_REPORT_CONVERTING,
    SET_SYSTEM_INFO,
} from './action-types';

const setIsFetching = (isFetching: boolean) => ({
    type: SET_IS_FETCHING,
    isFetching,
});

const setIsReportConverting = (isReportConverting: boolean) => ({
    type: SET_IS_REPORT_CONVERTING,
    isReportConverting,
});

const setMigrationsByDateRange = (migrations: Migration[]) => ({
    type: SET_MIGRATIONS_BY_DATE_RANGE,
    migrations,
});

const setAverageBackupSize = (averageBackupSize: number) => ({
    type: SET_AVERAGE_BACKUP_SIZE,
    averageBackupSize,
});

const setConversionsByDateRange = (conversions: IConversion[]) => ({
    type: SET_CONVERSIONS_BY_DATE_RANGE,
    conversions,
});

const setSystemInfo = (cpu: number, disk: Memory, ram: Memory) => ({
    type: SET_SYSTEM_INFO,
    cpu,
    disk,
    ram,
});

export const fetchMigrationsByDateRange = (from: string, to?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setIsFetching(true));
        const res = await dashboardApi.getMigrationsByDateRange(from, to);
        const { success, message } = res;
        if (success) {
            dispatch(setMigrationsByDateRange(message));
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    } finally {
        dispatch(setIsFetching(false));
    }
};

export const fetchConversionsByDateRange = (from: string, to?: string) => async (dispatch: Dispatch) => {
    try {
        const res = await dashboardApi.getCoversionsByDateRange(from, to);
        const { success, message } = res;
        if (success) {
            dispatch(setConversionsByDateRange(message));
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const fetchAverageBackupSize = () => async (dispatch: Dispatch) => {
    try {
        const res = await dashboardApi.getAverageBackupSize();
        const { success, message } = res;
        if (success) {
            dispatch(setAverageBackupSize(message));
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const fetchSystemInfo = () => async (dispatch: Dispatch) => {
    try {
        const res = await dashboardApi.getSystemInfo();
        const { success, message } = res;
        if (success) {
            const { CPU, DISK, RAM } = message as { CPU: number; DISK: Memory; RAM: Memory };
            dispatch(setSystemInfo(CPU, DISK, RAM));
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const downloadReport = (params: ReportExportingToCsv) => async (dispatch: Dispatch) => {
    try {
        dispatch(setIsReportConverting(true));
        const res = await dashboardApi.reportExportingToCsv(params);
        const { success, message } = res;
        if (success) {
            const link = document.createElement('a');
            link.href = message;
            document.body.appendChild(link);
            link.click();
        } else {
            throw new Error(message);
        }
    } catch (error: any) {
        dispatch(error.message || error.response?.data?.message);
    } finally {
        dispatch(setIsReportConverting(false));
    }
};

import Papa from 'papaparse';
import { Dispatch, SetStateAction } from 'react';
import { MappingType } from '../app/csv/templates/reducer';
import { JsonFieldTypes } from '../enums';

const mapValue = (row: string[], mapping: MappingType, headers: any) => {
    try {
        switch (mapping.type) {
            case JsonFieldTypes.string:
                return row[headers[mapping.source as string]];
            case JsonFieldTypes.int:
                return parseInt(row[headers[mapping.source as string]]);
            case JsonFieldTypes.objectsArray: {
                const objArray = (mapping.source as MappingType[][]).map((nestedMapping: any) => {
                    let newObj = {};
                    nestedMapping.map((item: any) => {
                        const sourceValue =
                            item.type === JsonFieldTypes.defaultValue ? item.source : row[headers[item.source]];
                        newObj = {
                            ...newObj,
                            [item.destination]: item.type === JsonFieldTypes.int ? parseInt(sourceValue) : sourceValue,
                        };
                    });
                    return newObj;
                });
                return objArray;
            }
            case JsonFieldTypes.intArray: {
                const intArray: Array<number> = [];
                (mapping.source as string[]).map((v) => intArray.push(parseInt(row[headers[v]])));
                return intArray;
            }
            case JsonFieldTypes.singleIntArray: {
                return [parseInt(row[headers[mapping.source as string]])];
            }
            case JsonFieldTypes.stringConcat: {
                return (mapping.source as string[]).map((v) => row[headers[v]]).join(' ');
            }
            case JsonFieldTypes.defaultValue: {
                return mapping.source;
            }
            default:
                return row[headers[mapping.source as string]];
        }
    } catch (error) {
        throw error;
    }
};

export const mapModel = (csvData: File, mapping: MappingType[], setPreviewJson: Dispatch<SetStateAction<any[]>>) => {
    Papa.parse(csvData, {
        preview: 5,
        complete: (results) => {
            let cleanedCsv: any = results.data;
            let rows = cleanedCsv.slice(1, cleanedCsv.length);
            if (rows[rows.length - 1][0] === '') {
                rows.pop();
            }

            const headers = cleanedCsv[0].reduce((object: any, header: string, index: number) => {
                object[header] = index;
                return object;
            }, {});

            const res = rows.map((row: string[]) => {
                let item = {};
                mapping.map((v) => {
                    item = { ...item, [v.destination]: mapValue(row, v, headers) };
                });
                return item;
            });
            setPreviewJson(res);
        },
    });
};

import { Button, Paper, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { sendResetCode } from '../../app/auth/auth.actions';
import { useAppDispatch } from '../../app/hooks';
import { AppState } from '../../app/root.reducer';
import { useStyles } from './classes';
import { Logo } from './common';
import { ConfirmForgotPassword } from './ConfirmForgotPassword';
import { sendResetCodeValidation } from './validation';

interface FormValues {
    email: string;
}

export const Restore: FC = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { isRestoredPassword } = useSelector((state: AppState) => state.authStore);

    const defaultValues: FormValues = {
        email: '',
    };

    const formik = useFormik<FormValues>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            await dispatch(sendResetCode(values.email));
        },
        validationSchema: sendResetCodeValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit } = formik;
    const { email } = values;

    return (
        <Paper className={classes.paper}>
            <Logo />
            {!isRestoredPassword && (
                <form className={classes.form} onSubmit={handleSubmit}>
                    <h1>Reset your password</h1>
                    <p>Please enter your email address to reset your password:</p>
                    <TextField
                        required
                        className={classes.formInput}
                        type="email"
                        label="Email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        error={!!errors?.email}
                        helperText={!!errors?.email}
                    />
                    <Button variant="outlined" className={classes.button} color="primary" type="submit">
                        Reset password
                    </Button>
                </form>
            )}
            {isRestoredPassword && <ConfirmForgotPassword email={email} />}
        </Paper>
    );
};

import { REMOVE_SUCCESS, SET_SUCCESS, SuccessActions } from './success.types';

export type SuccessState = {
    successMessage: string | null;
};

const initialState: SuccessState = {
    successMessage: null,
};

export const SuccessReducer = (state: SuccessState = initialState, action: SuccessActions) => {
    switch (action.type) {
        case SET_SUCCESS:
            return {
                ...state,
                successMessage: action.message,
            };
        case REMOVE_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return {
                ...state,
            };
    }
};

import { ITemplate } from '../../../model';

export const SET_LIMIT_OFFSET = 'SET_LIMIT_OFFSET';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_CREATED_TEMPLATE = 'SET_CREATED_TEMPLATE';
export const SET_IS_TEMPLATE_CREATED = 'SET_IS_TEMPLATE_CREATED';

interface SetIsFetching {
    type: typeof SET_IS_FETCHING;
    isFetching: boolean;
}

interface SetLimitOffset {
    type: typeof SET_LIMIT_OFFSET;
    offset?: number;
    limit?: number;
}

interface FetchTemplatesSuccess {
    type: typeof FETCH_TEMPLATES_SUCCESS;
    templates: ITemplate[];
}

interface FetchTemplatesFailure {
    type: typeof FETCH_TEMPLATES_FAILURE;
    error: Error;
}

interface SetCreatedTemplate {
    type: typeof SET_CREATED_TEMPLATE;
    createdTemplate: ITemplate;
}

interface SetIsTemplateCreated {
    type: typeof SET_IS_TEMPLATE_CREATED;
    isTemplateCreated: boolean;
}

export type CsvTemplatesAction =
    | SetLimitOffset
    | FetchTemplatesSuccess
    | FetchTemplatesFailure
    | SetIsFetching
    | SetCreatedTemplate
    | SetIsTemplateCreated;

import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles({
    filterContainer: {
        display: 'grid',
        gridTemplateColumns: '3fr auto',
        columnGap: '20px',
        alignItems: 'center',
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 150px',
        columnGap: '20px',
        backgroundColor: '#eaeaea',
        padding: '8px',
        borderRadius: '4px',
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    container: {
        padding: '8px 12px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 150px',
        rowGap: '40px',
        columnGap: '20px',
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        marginTop: '10px',
        alignItems: 'center',
        minHeight: '64px',
    },
    content: {
        minHeight: 'calc(100vh - 104px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    pagination: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
    },
    formControl: {
        marginTop: '10px',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

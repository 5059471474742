import { AccessTime, Check, CloudDone, CloudUpload, DeleteForever, Error } from '@material-ui/icons';
import { FC } from 'react';
import {
    ConversionStatus,
    ConversionStatusColor,
    ConversionStatusType,
    ConversionStatusView,
} from '../../../../../enums';
import { useStyles } from './classes';

type StatusType = {
    status: ConversionStatusType;
};

export const Status: FC<StatusType> = ({ status }) => {
    const classes = useStyles();

    const renderStatusIcon = () => {
        switch (status) {
            case ConversionStatus.uploadingInput:
                return <CloudUpload />;
            case ConversionStatus.inputUploaded:
                return <CloudDone />;
            case ConversionStatus.processing:
                return <AccessTime />;
            case ConversionStatus.finished:
                return <Check />;
            case ConversionStatus.error:
            case ConversionStatus.inputUploadFailed:
                return <Error />;
            case ConversionStatus.deleted:
                return <DeleteForever />;
            default:
                return null;
        }
    };

    const switchBgColor = () => {
        switch (status) {
            case ConversionStatus.queued:
            case ConversionStatus.uploadingInput:
            case ConversionStatus.inputUploaded:
            case ConversionStatus.processing:
                return ConversionStatusColor.processing;
            case ConversionStatus.finished:
                return ConversionStatusColor.success;
            case ConversionStatus.error:
            case ConversionStatus.inputUploadFailed:
            case ConversionStatus.deleted:
                return ConversionStatusColor.failed;
            default:
                return null;
        }
    };

    return (
        <div className={classes.statusWrapper} style={{ backgroundColor: `${switchBgColor()}` }}>
            {renderStatusIcon()} {ConversionStatusView[status]}
        </div>
    );
};

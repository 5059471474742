import axios, { AxiosError, InternalAxiosRequestConfig , AxiosResponse } from 'axios';
import { store } from '../app/store';
import { apiConfig, userPool } from './config';
import { authApi } from './auth-api';

const api = axios.create({
    baseURL: apiConfig.apiUrl,
    headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
    async (req: InternalAxiosRequestConfig) => {
        try {
            const token = await authApi.getTokenId();
            if (!token) {
                return req;
            } else {
                req.headers!['Authorization'] = token;
                return req;
            }
        } catch (err) {
            return req;
        }
    },
    (err) => {
        return Promise.reject(err);
    }
);

export const responseInterceptor = api.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: any) => {
        if (error?.response?.status === 403 && error?.response?.data?.message === 'Disabled') {
            const session = userPool.getCurrentUser();
            if (session) {
                session.signOut();
                window.location.reload();
                throw error;
            }
        }
        if (error.response && error.response.data) {
            const message: string = error.response.data.message;
            store.dispatch({
                type: 'SET_ERROR',
                message,
            });
            return Promise.reject(error);
        }
    }
);

export default api;

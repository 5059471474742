import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    FormHelperText,
    TextField,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { restoreBackup } from '../../../../app/backup/actions';
import { useAppDispatch } from '../../../../app/hooks';
import { AppState } from '../../../../app/root.reducer';
import {
    backupSourceEntries,
    BackupSourceType,
    VendorBackupExtension,
    Vendors,
    vendorTypeEntries,
} from '../../../../enums';
import { SelectorForm } from '../../../common/Forms/SelectorForm';
import { BackupInput } from './BackupInput';
import { restoreBackupValidation } from './validation';

interface Props {
    isOpen: boolean;
    close: () => void;
}

export interface FormValues {
    backupName: string;
    vendorType: string;
    backupSource: string;
    localFile: File | undefined;
    downloadLink: string;
}

export const RestoreBackupModal: FC<Props> = ({ isOpen, close }) => {
    const dispatch = useAppDispatch();
    const { backup_source, default_vendor } = useSelector((state: AppState) => state.userStore);

    const defaultFormValues: FormValues = {
        backupName: '',
        vendorType: default_vendor,
        backupSource: backup_source,
        localFile: undefined,
        downloadLink: '',
    };

    const formik = useFormik<FormValues>({
        initialValues: defaultFormValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: true,
        onSubmit: (values) => {
            dispatch(restoreBackup({ ...values, name: values.backupName, file: values.localFile }));
            close();
            resetForm();
        },
        validationSchema: restoreBackupValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, submitForm, setFieldValue, resetForm } = formik;
    const { backupName, vendorType, backupSource, localFile, downloadLink } = values;
    const onLocalFileChange = (e: any) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFieldValue('localFile', files[0]);
        }
    };

    const resetFile = () => {
        setFieldValue('localFile', null);
    };

    const onClose = () => {
        resetForm();
        close();
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={onClose} aria-labelledby="add-user-dialog">
            <DialogTitle style={{ paddingBottom: 0 }}>Restore Backup</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ marginBottom: 0 }}>
                    If backup exceeds 5gb - use 'local file' source
                </DialogContentText>
                <TextField
                    margin="normal"
                    label="Backup Name"
                    placeholder="Enter backup name here"
                    type="text"
                    name="backupName"
                    onChange={handleChange}
                    value={backupName}
                    error={!!errors.backupName}
                    helperText={errors.backupName}
                    fullWidth
                    style={{ marginTop: 0 }}
                />
                <SelectorForm
                    title="Vendor Type"
                    name="vendorType"
                    value={vendorType}
                    values={vendorTypeEntries}
                    changeValue={handleChange}
                />
                <FormHelperText>
                    You should select file with {vendorType === Vendors.roWriter ? 'mdb or bak' : VendorBackupExtension[vendorType]} extension for this vendor
                </FormHelperText>
                <SelectorForm
                    title="Backup Source"
                    name="backupSource"
                    value={backupSource}
                    values={backupSourceEntries}
                    changeValue={handleChange}
                />
                <BackupInput
                    linkType={backupSource as BackupSourceType}
                    downloadLink={downloadLink}
                    handleChange={handleChange}
                    errors={errors}
                    localFile={localFile}
                    resetFile={resetFile}
                    onLocalFileChange={onLocalFileChange}
                />
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        Close
                    </Button>
                    <Button onClick={submitForm} color="primary" variant="contained" startIcon={<BackupIcon />}>
                        Restore
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { FC } from 'react';
import { useStyles } from './classes';

type PreviewJSONType = {
    model: string | null;
    previewJson: any[];
    openPreviewJson: boolean;
    handlePreviewJsonClose: () => void;
};

export const PreviewJSON: FC<PreviewJSONType> = ({ model, previewJson, openPreviewJson, handlePreviewJsonClose }) => {
    const classes = useStyles();
    return (
        <Dialog maxWidth="sm" fullWidth open={openPreviewJson} onClose={handlePreviewJsonClose}>
            <DialogTitle>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>Preview output {model} JSON</div>
                    <Tooltip title="Close">
                        <IconButton edge="start" onClick={handlePreviewJsonClose}>
                            <Clear fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent>
                <Paper className={classes.previewContainer}>
                    <div>[</div>
                    {previewJson.map((json) => {
                        const keys = Object.keys(json);
                        return (
                            <div style={{ padding: '0 16px 16px 16px' }}>
                                <span>{'{'}</span>
                                {keys.map((key) => {
                                    if (typeof json[key] == 'object') {
                                        return (
                                            <div style={{ paddingLeft: '16px' }}>
                                                <b>{key}:</b>{' '}
                                                {json[key].length > 0
                                                    ? json[key].map((item: any) => {
                                                          const keys = Object.keys(item);
                                                          if (keys.length == 0) {
                                                              return <i>{item}, </i>;
                                                          } else {
                                                              return (
                                                                  <div style={{ paddingLeft: '16px' }}>
                                                                      {keys.map((key) => {
                                                                          return (
                                                                              <div>
                                                                                  <b>{key}:</b>{' '}
                                                                                  <i>{item[key] || '" "'}</i>
                                                                              </div>
                                                                          );
                                                                      })}
                                                                  </div>
                                                              );
                                                          }
                                                      })
                                                    : '[]'}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div style={{ paddingLeft: '16px' }}>
                                            <b>{key}:</b> <i>{json[key] === '' ? '" "' : json[key]}</i>
                                        </div>
                                    );
                                })}
                                <span>{'},'}</span>
                            </div>
                        );
                    })}
                    <div>]</div>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

import humanizeDuration from 'humanize-duration';
import { GeneralToolSettings } from '../enums/general-settings';
import { Memory, User } from '../model';

export const calculateDuration = (started: string, ended?: string) => {
    const msStart = new Date(started).getTime();
    const msEnd = ended ? new Date(ended).getTime() : new Date().getTime();
    return msEnd - msStart;
};

export const emailToUserName = (email: string) => email.split('@')[0];

export const parseUserOptions = (user: User) => JSON.parse(user.options);

export const parseDbSettings = (dbSettings: GeneralToolSettings[]) => {
    const settingsObj: { [key: string]: string | null } = {};
    dbSettings.forEach((item) => {
        settingsObj[item.setting_key] = item.setting_value;
    });
    return settingsObj;
};

export const convertDateToDateISO = (date: Date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const convertShortDate = (date: Date) => {
    return convertDateToDateISO(date).slice(0, 10);
};

export const formatSize = (size: number) => {
    var i = 0,
        type = ['B', 'KB', 'MB', 'GB'];
    while ((size / 1000) | 0 && i < type.length - 1) {
        size /= 1024;
        i++;
    }
    return size.toFixed(2) + ' ' + type[i];
};

export const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
        shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        },
    },
});

export const convertMemoryStatus = (memory: Memory) => {
    const { total, used } = memory;
    const totalToNumber = Number(total.split(' ')[0]);
    const usedToNumber = Number(used.split(' ')[0]);
    const dimension = total.split(' ')[1];
    const freeGb = totalToNumber - usedToNumber;
    const usedPercentage = (usedToNumber / totalToNumber) * 100;
    const freePercentage = 100 - usedPercentage;
    return { used, usedToNumber, dimension, freeGb, usedPercentage, freePercentage };
};

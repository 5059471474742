export enum MigrationStatus {
    queued = 'QUEUED',
    restoring = 'RESTORING',
    inProgress = 'IN_PROGRESS',
    done = 'DONE',
    error = 'ERROR',
    stopped = 'STOPPED',
    canceled = 'CANCELED',
    deleted = 'DELETED',
    validationError = 'VALIDATION_ERROR',
}

export type MigrationStatusType = `${MigrationStatus}`;

export const defaultMigrationStatusesVIew = Object.values(MigrationStatus);

export const MigrationStatusView: any = {
    [MigrationStatus.queued]: 'Queued',
    [MigrationStatus.restoring]: 'Restoring',
    [MigrationStatus.inProgress]: 'In Progress',
    [MigrationStatus.done]: 'Done',
    [MigrationStatus.error]: 'Error',
    [MigrationStatus.stopped]: 'Stopped',
    [MigrationStatus.canceled]: 'Canceled',
    [MigrationStatus.deleted]: 'Deleted',
    [MigrationStatus.validationError]: 'Validation Error',
};

export const migrationStatusEntries = Object.keys(MigrationStatusView).map((v) => {
    const value = v;
    const view = MigrationStatusView[value];
    return { value, view };
});

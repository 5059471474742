export enum MigrationModel {
    customers = 'customers',
    technicians = 'technicians',
    inventory = 'inventory',
    vendors = 'vendors',
    vehicles = 'vehicles',
    workorders = 'workorders',
    cannedJobs = 'cannedjobs',
    validation = 'validation',
}

export type MigrationModelType = `${MigrationModel}`;

export const MigrationModelView: any = {
    [MigrationModel.customers]: 'Customers',
    [MigrationModel.technicians]: 'Technicians',
    [MigrationModel.inventory]: 'Inventory',
    [MigrationModel.vendors]: 'Vendors',
    [MigrationModel.vehicles]: 'Vehicles',
    [MigrationModel.workorders]: 'Workorders',
    [MigrationModel.cannedJobs]: 'Canned jobs',
    [MigrationModel.validation]: 'Validation',
    Inventory_Without_Quantity: 'Inventory_Without_Quantity',
};

export const includedJSONsNames: string[] = [
    'inventory',
    'Inventory_Without_Quantity',
    'vendors',
    'technicians',
    'cannedjobs',
];

export enum WorkOrderDateRange {
    allTime = 'all_time',
    specificTime = 'specific_time',
}

export type WorkOrderType = `${WorkOrderDateRange}`;

export const WorkOrderDateRangeView: any = {
    [WorkOrderDateRange.allTime]: 'all time',
    [WorkOrderDateRange.specificTime]: 'specific time',
};

export const workOrderEntries = Object.keys(WorkOrderDateRange).map((v) => {
    const value = (WorkOrderDateRange as any)[v];
    const view = WorkOrderDateRangeView[value];
    return { value, view };
});

export enum OutputJSONs {
    all = 'all JSONs',
    custom = 'select JSONs',
}

export type OutputJSONsType = `${OutputJSONs}`;

export const outputJSONsEntries = Object.values(OutputJSONs).map((v) => ({
    value: v,
    view: v,
}));

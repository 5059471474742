import { AppName } from '../enums';
import api from './axios.main';

export const appsManagerApi = {
    async stopExtractionApp(appName: AppName) {
        const res = await api.post(`/eam/stop?name=${appName}`);
        return res;
    },
    async startExtractionApp(appName: AppName) {
        const res = await api.post(`/eam/start?name=${appName}`);
        return res;
    },
    async restartExtractionApp(appName: AppName) {
        const res = await api.post(`/eam/restart?name=${appName}`);
        return res;
    },
};

import { Backup } from '../../model';

export const FETCH_BACKUPS_IN_PROGRESS = 'FETCH_BACKUPS_IN_PROGRESS';
export const FETCH_BACKUPS_SUCCESS = 'FETCH_BACKUPS_SUCCESS';
export const FETCH_BACKUPS_FAILURE = 'FETCH_BACKUPS_FAILURE';

export const RESTORE_BACKUP_IN_PROGRESS = 'RESTORE_BACKUP_IN_PROGRESS';
export const RESTORE_BACKUP_SUCCESS = 'RESTORE_BACKUP_SUCCESS';
export const RESTORE_BACKUP_FAILURE = 'RESTORE_BACKUP_FAILURE';

export const DELETE_BACKUP_IN_PROGRESSS = 'DELETE_BACKUP_IN_PROGRESS';
export const DELETE_BACKUP_SUCCESS = 'DELETE_BACKUP_SUCCESS';
export const DELETE_BACKUP_FAILURE = 'DELETE_BACKUP_FAILURE';

interface FetchBackupsInProgress {
    type: 'FETCH_BACKUPS_IN_PROGRESS';
    offset?: number;
    limit?: number;
}

interface FetchBackupsSuccess {
    type: 'FETCH_BACKUPS_SUCCESS';
    backups: Backup[];
}

interface FetchBackupsFailure {
    type: 'FETCH_BACKUPS_FAILURE';
    error: Error;
}

interface RestoreBackupInProgress {
    type: 'RESTORE_BACKUP_IN_PROGRESS';
    id: string;
}

interface RestoreBackupSuccess {
    type: 'RESTORE_BACKUP_SUCCESS';
    id: string;
}

interface RestoreBackupFailure {
    type: 'RESTORE_BACKUP_FAILURE';
    id: string;
    error: Error;
}

interface DeleteBackupInProgress {
    type: 'DELETE_BACKUP_IN_PROGRESS';
}

interface DeleteBackupSuccess {
    type: 'DELETE_BACKUP_SUCCESS';
}

interface DeleteBackupFailure {
    type: 'DELETE_BACKUP_FAILURE';
    error: Error;
}

export type BackupAction =
    | FetchBackupsInProgress
    | FetchBackupsSuccess
    | FetchBackupsFailure
    | RestoreBackupInProgress
    | RestoreBackupSuccess
    | RestoreBackupFailure
    | DeleteBackupInProgress
    | DeleteBackupSuccess
    | DeleteBackupFailure;

import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Dispatch, SetStateAction } from 'react';
import { useStyles } from './classes';
import { FilterBar } from './FilterBar';

interface ToolbarProps {
    numSelected?: number;
    handleModalOpen: () => void;
    searchBar: string;
    setSearchBar: Dispatch<SetStateAction<string>>;
}

export const ToolBar = (props: ToolbarProps) => {
    const classes = useStyles();
    const { numSelected, handleModalOpen, searchBar, setSearchBar } = props;

    return (
        <Toolbar className={classes.toolbar}>
            <Tooltip title="Add user">
                <IconButton className={classes.addUserButton} size="small" color="primary" onClick={handleModalOpen}>
                    <AddCircle className={classes.addUserIcon} fontSize="large" />
                </IconButton>
            </Tooltip>
            {numSelected && numSelected > 0 && (
                <Typography color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            )}
            {numSelected && numSelected > 0 ? (
                <Tooltip title="Disable">
                    <IconButton className={classes.disableButton} aria-label="delete">
                        <RemoveCircleIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <FilterBar searchBar={searchBar} setSearchBar={setSearchBar} />
            )}
        </Toolbar>
    );
};

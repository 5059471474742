import { IconButton, Paper, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FC, useState } from 'react';
import { format } from 'timeago.js';
import { csvApi } from '../../../../../api/csv-api';
import { deleteConversions, downloadOutputJsons } from '../../../../../app/csv/conversions/actions';
import { useAppDispatch } from '../../../../../app/hooks';
import { ConversionStatus } from '../../../../../enums';
import { emailToUserName } from '../../../../../helpers/formatters';
import { deletedStyle } from '../../../../../helpers/styles';
import { IConversion } from '../../../../../model';
import { DownloadGroup } from '../../../../common/DownloadGroup';
import { useStyles } from './classes';
import { Status } from './ConversionStatus';

type ConverionProps = {
    conversion: IConversion;
};

export const Conversion: FC<ConverionProps> = ({ conversion }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [isCoppied, setIsCoppied] = useState(false);
    const { name, created_by_email, status, id, createdAt, deleted } = conversion;
    const handleDeleteButton = () => {
        dispatch(deleteConversions(id));
    };
    const handleDownloadButton = () => {
        dispatch(downloadOutputJsons(id));
    };
    const handleLinkCopyClick = async () => {
        const res = await csvApi.getOutputDownloadLink(id);
        const { message } = res;
        navigator.clipboard.writeText(message);
        setIsCoppied(true);
        setTimeout(() => {
            setIsCoppied(false);
        }, 2500);
    };

    return (
        <Paper style={{ opacity: deletedStyle(deleted) }} className={classes.container}>
            <span>{name}</span>
            <Status status={status} />
            <span>{format(createdAt as string) || '-'}</span>
            <span>{emailToUserName(created_by_email)}</span>
            {!deleted && (
                <div className={classes.actionsContainer}>
                    {status === ConversionStatus.finished && (
                        <DownloadGroup
                            handleDownloadButton={handleDownloadButton}
                            handleLinkCopyClick={handleLinkCopyClick}
                            isCoppied={isCoppied}
                        />
                    )}
                    <Tooltip title="Delete">
                        <IconButton onClick={handleDeleteButton}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Paper>
    );
};

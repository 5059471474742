import { Button, FormControl, InputAdornment, TextField } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import { FormikErrors } from 'formik';
import { FC } from 'react';
import { BackupSource, BackupSourceType } from '../../../../enums';
import { useStyles } from './classes';
import { FormValues } from './RestoreBackupModal';

type FileInputProps = {
    localFile: File | undefined;
    resetFile: () => void;
    errors: FormikErrors<FormValues>;
    onLocalFileChange: (e: any) => void;
};

type BackupInputProps = {
    linkType: BackupSourceType;
    downloadLink: string;
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    errors: FormikErrors<FormValues>;
    localFile: File | undefined;
    resetFile: () => void;
    onLocalFileChange: (e: any) => void;
};

const RenderFileInput: FC<FileInputProps> = ({ localFile, resetFile, errors, onLocalFileChange }) => {
    const classes = useStyles();
    if (localFile) {
        return (
            <TextField
                margin="normal"
                type="filename"
                value={localFile?.name || ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <ClearIcon onClick={resetFile} />
                        </InputAdornment>
                    ),
                }}
                error={!!errors.localFile}
                helperText={errors.localFile}
                fullWidth
            />
        );
    }

    return (
        <label htmlFor="backup-file-button" style={{ width: '100%' }}>
            <input type="file" id="backup-file-button" name="localFile" onChange={onLocalFileChange} hidden />
            <FormControl className={classes.formControl} fullWidth>
                <Button color="primary" component="span" variant="contained" startIcon={<AttachFileIcon />}>
                    Select file
                </Button>
            </FormControl>
        </label>
    );
};

export const BackupInput: FC<BackupInputProps> = ({
    linkType,
    downloadLink,
    errors,
    handleChange,
    localFile,
    resetFile,
    onLocalFileChange,
}) => {
    switch (linkType) {
        case BackupSource.s3Link:
            return (
                <TextField
                    label="S3 link"
                    placeholder="Paste S3 link here"
                    type="text"
                    name="downloadLink"
                    onChange={handleChange}
                    value={downloadLink}
                    error={!!errors.downloadLink}
                    helperText={errors.downloadLink}
                    fullWidth
                />
            );
        case BackupSource.localFile:
            return (
                <RenderFileInput
                    localFile={localFile}
                    resetFile={resetFile}
                    errors={errors}
                    onLocalFileChange={onLocalFileChange}
                />
            );
        default:
            return (
                <RenderFileInput
                    localFile={localFile}
                    resetFile={resetFile}
                    errors={errors}
                    onLocalFileChange={onLocalFileChange}
                />
            );
    }
};

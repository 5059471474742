import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    statusWrapper: {
        width: '100%',
        // margin: 'auto',
        // height: '24px',
        display: 'grid',
        padding: '4px 12px',
        paddingRight: '15px',
        borderRadius: '50px',
        gridTemplateColumns: 'max-content 1fr',
        columnGap: '10px',
        alignItems: 'center',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#fff',
        '& path': {
            fill: '#fff',
        },
        maxWidth: 'max-content',
        marginLeft: '22px',
    },
});

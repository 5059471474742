import { ChangeEvent, useState } from 'react';
import { defaultMigrationStatusesVIew, defaultVendorsView, MigrationStatus, Vendors } from '../enums';
import { defaultGenericStatusesView, GenericStatuses } from '../enums/dashboard';

enum charts {
    pie = 'pie',
    line = 'line',
}

type ChartsType = `${charts}`;

export const useChartsFiltering = (chartsType: ChartsType) => {
    const [isVendorsCheckAll, setIsVendorsCheckAll] = useState(true);
    const [isStatusesCheckAll, setIsStatusesCheckAll] = useState(true);
    const [vendors, setVendors] = useState(defaultVendorsView);
    const [statuses, setStatuses] = useState(
        chartsType === charts.line ? defaultMigrationStatusesVIew : defaultGenericStatusesView
    );
    const handleSelectAllVendors = () => {
        setIsVendorsCheckAll(!isVendorsCheckAll);
        setVendors(defaultVendorsView);
        if (isVendorsCheckAll) {
            setVendors([]);
        }
    };

    const handleSelectAllStatuses = () => {
        setIsStatusesCheckAll(!isStatusesCheckAll);
        setStatuses(chartsType === charts.line ? defaultMigrationStatusesVIew : defaultGenericStatusesView);
        if (isStatusesCheckAll) {
            setStatuses([]);
        }
    };
    const handleVendor = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        setVendors([...vendors, id as Vendors]);
        if (!checked) {
            setVendors(vendors.filter((vendor) => vendor !== id));
        }
    };

    const handleStatus = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = e.target;
        if (chartsType === charts.line) {
            setStatuses([...(statuses as MigrationStatus[]), id as MigrationStatus]);
            if (!checked) {
                setStatuses((statuses as MigrationStatus[]).filter((status: MigrationStatus) => status !== id));
            }
        } else {
            setStatuses([...(statuses as GenericStatuses[]), id as GenericStatuses]);
            if (!checked) {
                setStatuses((statuses as GenericStatuses[]).filter((status: GenericStatuses) => status !== id));
            }
        }
    };
    return {
        isVendorsCheckAll,
        isStatusesCheckAll,
        vendors,
        statuses,
        handleSelectAllVendors,
        handleSelectAllStatuses,
        handleVendor,
        handleStatus,
    };
};

import * as yup from 'yup';
import { BackupSource, VendorBackupExtension, Vendors } from '../../../../enums';
/*eslint-disable */
yup.setLocale({
    mixed: {
        required: 'Required field',
    },
    string: {
        min: 'Enter at least ${min} characters',
        max: 'Maximum ${max} characters',
    },
});

export const restoreBackupValidation = yup.object().shape({
    backupName: yup.string().min(1).max(50).required(),
    vendorType: yup.string().oneOf(Object.values(Vendors)).required(),
    backupSource: yup.string().oneOf(Object.values(BackupSource)).required(),
    localFile: yup
        .mixed()
        .test('localFile', 'You should select file with matching extension for vendor', function (value: any) {
            const { backupSource, vendorType } = this.parent;
            const vendorExtension = VendorBackupExtension[vendorType];
            if (backupSource === BackupSource.localFile) {
                if (vendorType === Vendors.roWriter) {
                    return value && vendorExtension.includes(value.name.split('.').pop().toLowerCase());
                }
                return value && value.name.split('.').pop().toLowerCase() === vendorExtension;
            }
            return true;
        }),
    downloadLink: yup.string().test('downloadLink', function (value, { createError }) {
        const { backupSource, vendorType } = this.parent;
        switch (backupSource) {
            case BackupSource.s3Link:
                const vendorExtension = vendorType === Vendors.roWriter
                    ? VendorBackupExtension[vendorType].join('|')
                    : VendorBackupExtension[vendorType];
                const regex = new RegExp(
                    `^https://(www\\.)?([\\w\\d\\-]+\\.s3\\.[\\w\\d\\-]+\\.)amazonaws\\.com(/[^?]+\\.(?:${vendorExtension})?)\\?response-content-disposition=inline.*$`,
                    'gim',
                );
                return !!value?.match(regex)
                    ? true
                    : createError({
                          message: "It's not S3 link or link does not match vendor extension",
                          path: 'downloadLink',
                      });
            default:
                return true;
        }
    }),
});

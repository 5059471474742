import { MappingType } from '../app/csv/templates/reducer';
import { JsonFieldTypes, JsonsNamesType } from '../enums';
import { initialMapping } from '../model';
import { cloneObj } from './cloneObj';

export const convertCsvTemplateToObj = (stringifyTemplate: string) => {
    const parsedTemplate: {
        model: JsonsNamesType;
        mapping: MappingType[];
    }[] = JSON.parse(stringifyTemplate);
    const template = parsedTemplate.map((templateModel) => {
        return {
            model: templateModel.model,
            mapping: templateModel.mapping.map((mappingItem) =>
                mappingItem.type === JsonFieldTypes.defaultValue
                    ? cloneObj(initialMapping[templateModel.model]).find(
                          (el: any) => el.destination === mappingItem.destination
                      )
                    : mappingItem
            ),
        };
    });
    return template;
};

import { Button, Paper, TableCell, TableRow, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React from 'react';
import { format } from 'timeago.js';
import { Order } from '../../../../helpers/tables';
import { Backup } from '../../../../model/backup';
import { EnhancedTableHead } from '../../../common/Tables/EnhancedTableHead';
import { NoMatchFound } from '../../../common/Tables/NoMatchFound';
import { headCells } from '../../Backups/Backups';
import { BackupStatusElement } from '../../Backups/BackupStatusElement/backup-status-element';
import { useStyles } from './classes';

type RunningRestore = {
    runningRestoreBackups: Backup[];
};

export const RunningRestore = ({ runningRestoreBackups }: RunningRestore) => {
    const classes = useStyles();
    const [lastIndex, setLastIndex] = React.useState(5);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Backup>('last_cloud_upload');
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property as keyof Backup);
    };
    const showMoreMigrations = () => {
        setLastIndex((prev) => prev + 5);
    };

    if (runningRestoreBackups.length) {
        return (
            <Paper style={{ padding: '10px' }}>
                <Typography>Running restore DB</Typography>
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={runningRestoreBackups.length}
                            headCells={headCells}
                            showSelectAllCheckbox={false}
                        />
                        <TableBody>
                            {runningRestoreBackups.slice(0, lastIndex).map((row) => {
                                return (
                                    <TableRow key={row.id} hover tabIndex={-1} id={row.id}>
                                        <TableCell
                                            align="center"
                                            component="td"
                                            padding="none"
                                            style={{ paddingRight: '26px' }}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="center" style={{ paddingRight: '26px' }}>
                                            {row.vendor_type}
                                        </TableCell>
                                        <TableCell align="center">
                                            <BackupStatusElement status={row.status as string} />
                                        </TableCell>
                                        <TableCell align="center" style={{ paddingRight: '26px' }}>
                                            {(row.last_cloud_upload && format(row.last_cloud_upload as string)) || '-'}
                                        </TableCell>
                                        <TableCell align="center" style={{ paddingRight: '26px' }}>
                                            {(row.last_extract && format(row.last_extract as string)) || '-'}
                                        </TableCell>
                                        <TableCell align="center" style={{ paddingRight: '26px' }}>
                                            {row.restored_by_email}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {runningRestoreBackups.length === 0 && <NoMatchFound />}
                    {runningRestoreBackups.length > lastIndex && (
                        <Button className={classes.btn} variant="outlined" color="primary" onClick={showMoreMigrations}>
                            Show more
                        </Button>
                    )}
                </TableContainer>
            </Paper>
        );
    }
    return null;
};

import { IconButton, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, FileCopyOutlined, GetApp } from '@material-ui/icons';
import { FC } from 'react';

type ClipboardButtonProps = {
    isCoppied: boolean;
    handleCopyClick: () => void;
    itemToCopy: string;
};

export const ClipboardButton: FC<ClipboardButtonProps> = ({ isCoppied, handleCopyClick, itemToCopy }) => {
    return (
        <>
            {!isCoppied ? (
                <Tooltip title={`Copy ${itemToCopy}`}>
                    <IconButton edge="start" onClick={handleCopyClick}>
                        <FileCopyOutlined />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Copied!">
                    <IconButton edge="start" onClick={handleCopyClick}>
                        <CheckCircleOutline style={{ color: 'green' }} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

type DownloadGroupType = {
    handleDownloadButton: () => void;
    handleLinkCopyClick: () => Promise<void>;
    isCoppied: boolean;
};

export const DownloadGroup = ({ handleDownloadButton, handleLinkCopyClick, isCoppied }: DownloadGroupType) => {
    return (
        <>
            <Tooltip title="Download">
                <IconButton edge="start" onClick={handleDownloadButton}>
                    <GetApp />
                </IconButton>
            </Tooltip>
            <ClipboardButton isCoppied={isCoppied} handleCopyClick={handleLinkCopyClick} itemToCopy="link" />
        </>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    paper: {
        padding: '15px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '360px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formInput: {
        marginBottom: '15px',
        width: '100%',
    },
    button: {
        alignSelf: 'center',
        marginBottom: '10px',
        marginTop: '10px',
    },
    textLink: {
        color: '#FF6125',
        alignSelf: 'center',
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

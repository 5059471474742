import { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

type ConfirmationModalProps = {
    isOpen: boolean;
    title?: string;
    text: string;
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ isOpen, onClose, title, text, onConfirm }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} style={{ paddingBottom: 20 }}>
            <DialogTitle>{ title || 'Confirm your action' }</DialogTitle>
            <DialogContent>
                { text }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

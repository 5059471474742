import { IConversion } from '../../../model';

export const SET_LIMIT_OFFSET = 'SET_LIMIT_OFFSET';
export const FETCH_CONVERSIONS_SUCCESS = 'FETCH_CONVERSIONS_SUCCESS';
export const FETCH_CONVERSIONS_FAILURE = 'FETCH_CONVERSIONS_FAILURE';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_NEW_CONVERSION = 'SET_NEW_CONVERSION';

interface SetIsFetching {
    type: typeof SET_IS_FETCHING;
    isFetching: boolean;
}

interface SetLimitOffset {
    type: typeof SET_LIMIT_OFFSET;
    offset?: number;
    limit?: number;
}

interface FetchConversionsSuccess {
    type: typeof FETCH_CONVERSIONS_SUCCESS;
    conversions: IConversion[];
}

interface FetchConversionsFailure {
    type: typeof FETCH_CONVERSIONS_FAILURE;
    error: Error;
}

interface SetNewConversion {
    type: typeof SET_NEW_CONVERSION;
    newConversion: IConversion;
    uploadURLs: string[];
}

export type CsvAction =
    | SetLimitOffset
    | FetchConversionsSuccess
    | FetchConversionsFailure
    | SetIsFetching
    | SetNewConversion;

import api from './axios.main';

export interface RunMigrationParams {
    name: string;
    backupId: string;
    options: string;
}

export const migrationApi = {
    async getAllMigrations(offset?: number, limit?: number) {
        const res = await api.get(`/migrations?offset=${offset}&limit=${limit}`).then((res) => res.data);
        return res;
    },

    async getMigrationById(id: string) {
        const res = await api.get(`/migrations/${id}`).then((res) => res.data);
        return res;
    },

    async runMigration(params: RunMigrationParams) {
        const res = await api.post(`/migrations`, params).then((res) => res.data);
        return res;
    },

    async restartMigration(id: string) {
        const res = await api.post(`/migrations/${id}/restart`).then((res) => res.data);
        return res;
    },

    async deleteMigrationById(id: string) {
        const res = await api.delete(`/migrations/${id}`).then((res) => res.data);
        return res;
    },

    async getOutputDownloadLink(id: string) {
        const res = await api.get(`/migrations/${id}/download-link`).then((res) => res.data);
        return res;
    },

    async stopMigration(id: string) {
        const res = await api.post(`/migrations/${id}/stop`).then((res) => res.data);
        return res;
    },

    async cancelMigration(id: string) {
        const res = await api.post(`/migrations/${id}/cancel`).then((res) => res.data);
        return res;
    },
};

import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ChangeEvent, createRef, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../..';
import { fetchTemplates } from '../../../../app/csv/templates/actions';
import { useAppDispatch } from '../../../../app/hooks';
import { AppState } from '../../../../app/root.reducer';
import { CSVTabsType } from '../../../../enums';
import { filterCsv } from '../../../../helpers/filterData';
import { usePagination } from '../../../../hooks/usePagination';
import { ITemplate } from '../../../../model';
import { NoMatchFound } from '../../../common/Tables/NoMatchFound';
import { useStyles } from './classes';
import { Filter } from './Filter';
import { Template } from './Template';

const PER_PAGE = 10;

type PropsType = {
    csvTab: CSVTabsType;
    setCsvTab: Dispatch<SetStateAction<CSVTabsType>>;
};

export type CsvFilterType = {
    searchByName: string;
    showDeleted: boolean;
};

const defaultFilter: CsvFilterType = {
    searchByName: '',
    showDeleted: false,
};

export const Templates: FC<PropsType> = ({ setCsvTab }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { isFetching, templates } = useSelector((state: AppState) => state.csvTemplatesStore);
    const [filter, setFilter] = useState<CsvFilterType>(defaultFilter);
    const ref = createRef<HTMLDivElement>();
    useEffect(() => {
        dispatch(fetchTemplates());
    }, []);

    const filteredTemplates = filterCsv(templates, filter);
    const [page, setPage] = useState(1);
    const count = Math.ceil(filteredTemplates.length / PER_PAGE);
    const _templates = usePagination(filteredTemplates, PER_PAGE, ref);
    const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
        setPage(page);
        _templates.jump(page);
        _templates.scroll();
    };

    useEffect(() => {
        setPage(1);
        _templates.jump(1);
    }, [filteredTemplates.length]);

    return (
        <div ref={ref} className={classes.content}>
            <Filter filter={filter} setFilter={setFilter} />
            <Box className={classes.header}>
                <span>name</span>
                <span>created at</span>
                <span>created by</span>
                <span>actions</span>
            </Box>
            {isFetching ? (
                <Loader />
            ) : (
                _templates
                    .currentData()
                    .map((template: ITemplate) => (
                        <Template
                            key={template.id}
                            name={template.name}
                            created={template.createdAt}
                            createdBy={template.created_by_email}
                            id={template.id}
                            template={template.template}
                            setCsvTab={setCsvTab}
                            deleted={template.deleted}
                        />
                    ))
            )}
            {!isFetching && filteredTemplates.length === 0 && <NoMatchFound />}
            {!isFetching && filteredTemplates.length !== 0 && (
                <Pagination
                    className={classes.pagination}
                    count={count}
                    size="large"
                    page={page}
                    variant="text"
                    color="primary"
                    onChange={handleChangePage}
                />
            )}
        </div>
    );
};

import { TablePagination } from '@material-ui/core';
import React, { FC } from 'react';
import { useStyles } from './classes';

type PaginationProps = {
    count: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (event: unknown, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Pagination: FC<PaginationProps> = ({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange }) => {
    const classes = useStyles();
    return (
        <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[15, 30, 60]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
        />
    );
};

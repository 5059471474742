import { Button, CircularProgress, Fade, Paper, Typography } from '@material-ui/core';
import { Dns } from '@material-ui/icons';
import { FC, useLayoutEffect, useState } from 'react';
import { healthApi } from '../../../api/health-api';
import { AppName, AppNames, AppNameViews } from '../../../enums';
import { StatusEntry } from '../../../model';
import { useStyles } from './classes';
import { HealthCheckIcons } from './HealthcheckIcons';

const UPDATE_HEALTH_STATUS_INTERVAL = 15000;

export const Healthchek: FC = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const initialStatuses = [
        { name: AppNames.extractionAppManager, status: null, message: null },
        { name: AppNames.backupsExtractionApp, status: null, message: null },
        { name: AppNames.migrationTool, status: null, message: null },
        { name: AppNames.foxProConnector, status: null, message: null },
    ];
    const [statuses, setStatuses] = useState<StatusEntry[]>(initialStatuses);

    const getHealthStatuses = () => {
        setLoading(true);
        const appNames = Object.values(AppNames);
        const promises = appNames.map((appName: AppName) =>
            healthApi.getStatus(appName).then((res) => ({
                name: appName,
                status: res.status || res,
                message: res.data?.message || res.data?.message === false ? res.data.message : res,
            }))
        );
        Promise.all(promises).then((values) => {
            setStatuses(values);
            setLoading(false);
        });
    };

    useLayoutEffect(() => {
        getHealthStatuses();
        const intervalId = setInterval(() => {
            getHealthStatuses();
        }, UPDATE_HEALTH_STATUS_INTERVAL);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className={classes.healthChecksContainer}>
            {statuses?.map((status) => {
                return (
                    <Paper key={status.name} elevation={3} className={classes.healthChecksItem}>
                        <div className={classes.serviceName}>
                            <Dns color="primary" fontSize="large" className={classes.iconApp} />

                            <Typography variant="subtitle1">{AppNameViews[status.name]}</Typography>
                        </div>

                        {loading ? (
                            <Fade in={loading} unmountOnExit>
                                <CircularProgress className={classes.iconStatus} size={30} />
                            </Fade>
                        ) : (
                            <HealthCheckIcons
                                status={status.status}
                                name={status.name}
                                getHealthStatuses={getHealthStatuses}
                                message={status.message}
                            />
                        )}
                    </Paper>
                );
            })}
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.checkStatusesbtn}
                onClick={getHealthStatuses}
            >
                Check statuses
            </Button>
        </div>
    );
};

import { FC } from 'react';
import { useStyles } from './classes';
import {
    CloudUpload,
    CloudDone,
    AccessTime,
    Check,
    Error,
    SyncAlt,
    QueryBuilder,
    DeleteForever,
} from '@material-ui/icons';
import { BackupStatus, BackupStatusColor, BackupStatusView } from '../../../../enums';

export type StatusComponentProps = {
    status: string;
};

export const BackupStatusElement: FC<StatusComponentProps> = ({ status }) => {
    const classes = useStyles();

    const renderStatusIcon = () => {
        switch (status) {
            case BackupStatus.uploadingToCloud:
                return <CloudUpload />;
            case BackupStatus.uploadedToCloud:
                return <CloudDone />;
            case BackupStatus.extracting:
                return <AccessTime />;
            case BackupStatus.extracted:
                return <Check />;
            case BackupStatus.error:
                return <Error />;
            case BackupStatus.deleteQueued:
                return <SyncAlt />;
            case BackupStatus.deleteInProgress:
                return <QueryBuilder />;
            case BackupStatus.deleteError:
                return <Error />;
            case BackupStatus.deleted:
                return <DeleteForever />;
            default:
                return null;
        }
    };

    const switchBgColor = () => {
        switch (status) {
            case BackupStatus.uploadingToCloud:
            case BackupStatus.uploadedToCloud:
            case BackupStatus.extracting:
            case BackupStatus.deleteQueued:
            case BackupStatus.deleteInProgress:
                return BackupStatusColor.processing;
            case BackupStatus.extracted:
                return BackupStatusColor.success;
            case BackupStatus.error:
            case BackupStatus.deleteError:
            case BackupStatus.deleted:
                return BackupStatusColor.failed;
            default:
                return null;
        }
    };

    return (
        <div className={classes.statusWrapper} style={{ backgroundColor: `${switchBgColor()}` }}>
            {renderStatusIcon()} {BackupStatusView[status]}
        </div>
    );
};

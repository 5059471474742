import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    TextField
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../../../../../api/user-api';
import { setError } from '../../../../../app/error/error.actions';
import { useAppDispatch } from '../../../../../app/hooks';
import { UserRoles, userRolesEntries, UserRolesType } from '../../../../../enums';

export type UserModalProps = {
    open: boolean;
    handleClose: () => void;
};

export const UserModal: FC<UserModalProps> = ({ open, handleClose }) => {
    const [role, setRole] = useState<UserRolesType>(UserRoles.user);
    const [email, setEmail] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleChange = (event: React.ChangeEvent<any>) => {
        setRole(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleAddButton = async () => {
        try {
            const res = await userApi.createUser(email, role);
            if (res) {
                navigate(0);
                handleClose();
            }
        } catch (err: any) {
            dispatch(setError(err.response?.data.message || err.message));
        }
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose} aria-labelledby="add-user-dialog">
            <DialogTitle>Add user</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">User role</InputLabel>
                    <Select
                        native
                        value={role}
                        onChange={handleChange}
                        inputProps={{
                            name: 'role',
                            id: 'role-select',
                        }}
                    >
                        {userRolesEntries.map((role) => (
                            <option key={role.value} value={role.value}>
                                {role.view}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <TextField margin="normal" label="Email" type="email" onChange={handleEmailChange} fullWidth />
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Close
                    </Button>
                    <Button onClick={handleAddButton} color="primary" variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

import { useEffect } from 'react';
import { useAppDispatch } from "./../app/hooks";

export const useData = (updateInterval: number, fetchData: any) => {
    let typingTimer: null | ReturnType<typeof setTimeout> = null;
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchData());
        const intervalId = setInterval(() => {
            dispatch(fetchData());
        }, updateInterval);
        return () => {
            clearInterval(intervalId);
            if (typingTimer) clearTimeout(typingTimer);
        };
    }, []);
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    title: {
        paddingBottom: 0,
    },
    content: {
        paddingTop: '16px',
        paddingBottom: '16px',
    },
});

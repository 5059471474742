import { Dispatch } from 'redux';
import { settingsApi } from '../../api/settings.api';
import { parseDbSettings } from '../../helpers/formatters';
import { setError } from '../error/error.actions';
import { setSuccess } from '../success/success.actions';
import { SET_GENERAL_SETTINGS } from './action-types';
import { AppSettingsState } from './reducer';

export const setGeneralSettings = (settings: Partial<AppSettingsState>) => ({
    type: SET_GENERAL_SETTINGS,
    settings,
});

export const updateGeneralSettings = (settings: AppSettingsState) => async (dispatch: Dispatch) => {
    try {
        const res = await settingsApi.setSettings(settings);
        const { success, message } = res;
        if (success) {
            const settingsObj = parseDbSettings(message);
            dispatch(setGeneralSettings(settingsObj));
            dispatch(setSuccess('General settings saved successfully!'));
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

export const fetchGlobalSettings = () => async (dispatch: Dispatch) => {
    try {
        const res = await settingsApi.getSettings();
        const { success, message } = res;
        if (success) {
            const settingsObj = parseDbSettings(message);
            dispatch(setGeneralSettings(settingsObj));
        } else throw new Error(message);
    } catch (error: any) {
        dispatch(setError(error.response?.data?.message || error.message));
    }
};

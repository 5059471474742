import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Paper,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import { AttachFile, Delete, Edit, PlayArrow } from '@material-ui/icons';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { format } from 'timeago.js';
import { createConversion } from '../../../../app/csv/conversions/actions';
import { deleteTemplate } from '../../../../app/csv/templates/actions';
import { setError } from '../../../../app/error/error.actions';
import { useAppDispatch } from '../../../../app/hooks';
import { CSVTabs, CSVTabsType } from '../../../../enums';
import { emailToUserName } from '../../../../helpers/formatters';
import { deletedStyle } from '../../../../helpers/styles';
import { EditTemplate } from '../CreateTemplate/EditTemplate';
import { useStyles } from './classes';

type TemplateProps = {
    name: string;
    created: string;
    createdBy: string;
    id: string;
    template: string;
    setCsvTab: Dispatch<SetStateAction<CSVTabsType>>;
    deleted: boolean;
};

type ModalProps = {
    openModal: boolean;
    handleModalClose: () => void;
    template: string;
    id: string;
    setCsvTab: Dispatch<SetStateAction<CSVTabsType>>;
};

type FileInputProps = {
    handleUploadCsv: (e: any) => void;
    item: any;
};

const RenderFileInput: FC<FileInputProps> = ({ handleUploadCsv, item }) => {
    const classes = useStyles();
    const [localFile, setLocalFile] = useState<File | null>(null);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const file = e.target.files[0];
            setLocalFile(file);
            handleUploadCsv(e);
        }
    };
    if (localFile) {
        return <TextField margin="normal" type="filename" value={localFile?.name || ''} fullWidth />;
    }

    return (
        <label key={item.model} htmlFor={`csv-file-${item.model}`} style={{ width: '100%' }}>
            <input
                type="file"
                id={`csv-file-${item.model}`}
                name={item.model}
                onChange={handleChange}
                accept=".csv"
                hidden
            />
            <FormControl className={classes.formControl} fullWidth>
                <Button color="primary" component="span" variant="contained" startIcon={<AttachFile />}>
                    Select file
                </Button>
            </FormControl>
        </label>
    );
};

const Modal: FC<ModalProps> = ({ openModal, handleModalClose, template, id, setCsvTab }) => {
    const dispatch = useAppDispatch();
    const [csvFiles, setCsvFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<{ model: string; name: string }[]>([]);
    if (openModal) {
        const parsedTemplate: any = JSON.parse(template);
        const handleUploadCsv = (e: any) => {
            const file = e.target.files[0];
            setCsvFiles([...csvFiles, file]);
            setFiles([...files, { model: e.target.name, name: file.name }]);
        };

        const close = () => {
            handleModalClose();
            setCsvFiles([]);
            setFiles([]);
        };

        const handleCreateConversion = async () => {
            if (parsedTemplate.length !== csvFiles.length) {
                dispatch(setError('Upload CSV files!'));
            } else {
                await dispatch(createConversion({ id, files, csvFiles }));
                setCsvTab(CSVTabs.conversions);
            }
        };
        return (
            <Dialog maxWidth="xs" fullWidth open={openModal} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle>Upload csv files</DialogTitle>
                <DialogContent>
                    {parsedTemplate instanceof Array &&
                        parsedTemplate?.map((item: any) => (
                            <div key={item.model}>
                                <Typography style={{ textTransform: 'capitalize' }}>{item.model}:</Typography>
                                <RenderFileInput item={item} handleUploadCsv={handleUploadCsv} />
                            </div>
                        ))}
                    <DialogActions>
                        <Button onClick={close} color="secondary">
                            Close
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleCreateConversion}>
                            Run conversion
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    } else return null;
};

export const Template: FC<TemplateProps> = ({ name, created, createdBy, id, template, setCsvTab, deleted }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const handleDeleteTemplate = () => {
        dispatch(deleteTemplate(id));
    };
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openEditor, setOpenEditor] = useState<boolean>(false);

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handleEditorOpen = () => setOpenEditor(true);
    const handleEditorClose = () => setOpenEditor(false);

    return (
        <Paper style={{ opacity: deletedStyle(deleted) }} className={classes.container}>
            <span>{name}</span>
            <span>{format(created as string) || '-'}</span>
            <span>{emailToUserName(createdBy)}</span>
            {!deleted && (
                <div className={classes.actionsContainer}>
                    <Tooltip title="Edit">
                        <IconButton edge="start" onClick={handleEditorOpen}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Run">
                        <IconButton edge="start" onClick={handleModalOpen}>
                            <PlayArrow />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton edge="start" onClick={handleDeleteTemplate}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </div>
            )}

            <Modal
                openModal={openModal}
                handleModalClose={handleModalClose}
                template={template}
                id={id}
                setCsvTab={setCsvTab}
            />
            <EditTemplate
                openEditor={openEditor}
                setCsvTab={setCsvTab}
                name={name}
                template={template}
                id={id}
                handleEditorClose={handleEditorClose}
            />
        </Paper>
    );
};

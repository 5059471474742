import { FC } from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { useStyles } from './classes';
import { MigrationCurrentModel } from '../../../../model';
import { MigrationModel, MigrationModelView, Vendors } from '../../../../enums';
import { MigrationStatus } from '../../../../enums/migration-status';
import { removeCannedJobsModel } from '../../../../helpers/migrations';

type ProgressBarProps = {
    status: string;
    model: MigrationCurrentModel;
    error?: string;
    options: string;
    vendor: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({ status, model, error, options, vendor }) => {
    const classes = useStyles();
    const parsedOptions = options ? JSON.parse(options) : options;

    const steps = parsedOptions?.not_included_JSONs
        ? Object.values(MigrationModel).filter((i) => parsedOptions.not_included_JSONs.indexOf(i) === -1)
        : Object.values(MigrationModel);

    const activeStep =
        status === MigrationStatus.queued || status === MigrationStatus.restoring
            ? -1
            : status === MigrationStatus.done || status === MigrationStatus.deleted
            ? steps.length + 1
            : steps.findIndex((step) => step === model);

    return (
        <Stepper className={classes.expandBlock} activeStep={activeStep} alternativeLabel>
            {steps.map((step) => {
                if (step === MigrationModel.cannedJobs && removeCannedJobsModel(vendor as Vendors)) return null;
                const labelProps: { optional?: React.ReactNode; error?: boolean } = {};
                if (error) {
                    labelProps.error = step === model;
                }
                return (
                    <Step key={step}>
                        <StepLabel className={classes.stepLabel} {...labelProps}>
                            {MigrationModelView[step]}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

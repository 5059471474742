import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    migrationHeader: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 100,
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'minmax(215px, 1fr) 70px 160px 1fr 1fr 170px 80px',
        columnGap: '20px',
        backgroundColor: '#eaeaea',
        padding: '8px',
        borderRadius: '4px',
        fontWeight: 700,
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    migrationBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    addButton: {
        marginRight: '15px',
        marginTop: 'auto',
    },
    addIcon: {
        width: '50px',
        height: '50px',
    },
    pagination: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
    },
});

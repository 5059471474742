import { FilterType } from '../components/common/Tables/FilterBar';

export const filterData = (data: any[], filter: FilterType) => {
    let sortedData = data;
    sortedData = data.filter((dataItem) =>
        filter.vendor.includes(dataItem?.backup_vendor_type || dataItem?.vendor_type)
    );

    sortedData = sortedData.filter((dataItem) => filter.status.includes(dataItem.status));

    if (filter.startDate) {
        sortedData = sortedData.filter(
            (dataItem) =>
                Date.parse(dataItem?.start_ts || dataItem?.last_cloud_upload) >=
                Date.parse(filter.startDate!.toISOString())
        );
    }

    if (filter.endDate) {
        sortedData = sortedData.filter(
            (dataItem) =>
                Date.parse(dataItem?.start_ts || dataItem?.last_cloud_upload) <= Date.parse(filter.endDate!.toString())
        );
    }

    if (filter.searchByName) {
        sortedData = sortedData.filter((dataItem) =>
            dataItem.name.toLowerCase().includes(filter.searchByName?.toLowerCase())
        );
    }

    if (!filter?.deleted) {
        sortedData = sortedData.filter((dataItem) => dataItem.deleted !== true);
    }

    return sortedData;
};

export const filterCsv = (data: any[], filter: { searchByName: string; showDeleted: boolean }) => {
    let filterData = data;
    if (filter.searchByName) {
        filterData = filterData.filter((dataItem) =>
            dataItem.name.toLocaleLowerCase().includes(filter.searchByName.toLowerCase())
        );
    }
    if (!filter.showDeleted) {
        filterData = filterData.filter((dataItem) => dataItem.deleted !== true);
    }
    return filterData;
};

import { Box, Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { SearchField } from '../../../common/Forms/SearchField';
import { useStyles } from './classes';
import { CsvFilterType } from './Templates';

type FilterT = {
    filter: CsvFilterType;
    setFilter: Dispatch<SetStateAction<CsvFilterType>>;
};

export const Filter: FC<FilterT> = ({ filter, setFilter }) => {
    const classes = useStyles();
    
    const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFilter({...filter, searchByName: e.target.value});
    };

    const handleShowDeleted = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter({ ...filter, showDeleted: !filter.showDeleted })
    }
    
    return (
        <Box className={classes.filterContainer}>
            <SearchField handleSearch={handleSearch} searchValue={filter.searchByName} />
            <FormControl fullWidth>
                <FormControlLabel 
                    label="Show Deleted"
                    control={
                        <Checkbox 
                            color="primary"
                            checked={filter.showDeleted}
                            onChange={handleShowDeleted}
                        />
                    }
                />
            </FormControl>
        </Box>
    );
};

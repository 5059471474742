import { fetchBackups } from '../../../../app/backup/actions';
import { fetchConversions } from '../../../../app/csv/conversions/actions';
import { useAppSelector } from '../../../../app/hooks';
import { fetchMigrations } from '../../../../app/migrations/actions';
import { BackupStatus, ConversionStatus, MigrationStatus } from '../../../../enums';
import { useData } from '../../../../hooks/useData';
import { NoMatchFound } from '../../../common/Tables/NoMatchFound';
import { ConversionsHeader } from '../../CSV/Conversions/Conversions';
import { MigrationsHeader } from '../../Migrations/Migrations';
import { RunningConversions } from './RunningConversions';
import { RunningRestore } from './RunningRestore';

const UPDATE_DATA_INTERVAL = 30000;

const checkConversionIsRunning = (status: string) => {
    switch (status) {
        case ConversionStatus.uploadingInput:
        case ConversionStatus.inputUploaded:
        case ConversionStatus.processing:
            return true;
        default:
            return false;
    }
};

const checkRestoreIsRunning = (status: string) => {
    switch (status) {
        case BackupStatus.uploadingToCloud:
        case BackupStatus.uploadedToCloud:
        case BackupStatus.extracting:
            return true;
        default:
            return false;
    }
};

export const RunningProcesses = () => {
    useData(UPDATE_DATA_INTERVAL, fetchMigrations);
    useData(UPDATE_DATA_INTERVAL, fetchBackups);
    useData(UPDATE_DATA_INTERVAL, fetchConversions);
    const { backups } = useAppSelector((state) => state.backupStore);
    const { migrations } = useAppSelector((state) => state.migrationStore);
    const { conversions } = useAppSelector((state) => state.csvConversionsStore);
    const runningMigrations = migrations.filter(
        (migration) => migration.status === MigrationStatus.restoring || migration.status === MigrationStatus.inProgress
    );
    const runningConversions = conversions.filter((conversion) => checkConversionIsRunning(conversion.status));
    const runningRestoreBackups = backups.filter((backup) => checkRestoreIsRunning(backup.status));
    return (
        <>
            {runningConversions.length || runningRestoreBackups.length || runningMigrations.length ? (
                <>
                    <RunningConversions
                        runningConversions={runningMigrations}
                        type="migration"
                        title="Running migrations"
                    >
                        <MigrationsHeader />
                    </RunningConversions>
                    <RunningConversions
                        runningConversions={runningConversions}
                        type="csvConversion"
                        title="Running csv conversions"
                    >
                        <ConversionsHeader />
                    </RunningConversions>
                    <RunningRestore runningRestoreBackups={runningRestoreBackups} />
                </>
            ) : (
                <NoMatchFound title="No running conversions or running DB restore" />
            )}
        </>
    );
};

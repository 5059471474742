import { Backup } from '../../model';
import {
    BackupAction,
    DELETE_BACKUP_FAILURE,
    DELETE_BACKUP_IN_PROGRESSS,
    DELETE_BACKUP_SUCCESS,
    FETCH_BACKUPS_FAILURE,
    FETCH_BACKUPS_IN_PROGRESS,
    FETCH_BACKUPS_SUCCESS,
    RESTORE_BACKUP_FAILURE,
    RESTORE_BACKUP_IN_PROGRESS,
    RESTORE_BACKUP_SUCCESS,
} from './action-types';

export interface BackupState {
    backups: Backup[];
    isFetching: boolean;
    fetchError?: Error | null;
    offset?: number;
    limit?: number;
    startRestoreError?: Error | null;
    isBackupDeleting: boolean;
    deleteBackupError?: Error | null;
    inProgress: string[];
}

const initialState: BackupState = {
    backups: [],
    isFetching: false,
    isBackupDeleting: false,
    inProgress: [],
};

export const BackupReducer = (state: BackupState = initialState, action: BackupAction) => {
    switch (action.type) {
        case FETCH_BACKUPS_IN_PROGRESS:
            return {
                ...state,
                isFetching: true,
                offset: action.offset,
                limit: action.limit,
            };
        case FETCH_BACKUPS_SUCCESS:
            return {
                ...state,
                backups: action.backups,
                isFetching: false,
                fetchError: null,
            };
        case FETCH_BACKUPS_FAILURE:
            return {
                ...state,
                backups: [],
                isFetching: false,
                fetchError: action.error,
            };
        case RESTORE_BACKUP_IN_PROGRESS:
            return {
                ...state,
                inProgress: [...state.inProgress, action.id],
            };
        case RESTORE_BACKUP_SUCCESS:
            return {
                ...state,
                inProgress: state.inProgress.filter((id) => id !== action.id),
                startRestoreError: null,
            };
        case RESTORE_BACKUP_FAILURE:
            return {
                ...state,
                inProgress: state.inProgress.filter((id) => id !== action.id),
                startRestoreError: action.error,
            };
        case DELETE_BACKUP_IN_PROGRESSS:
            return {
                ...state,
                isBackupDeleting: true,
            };
        case DELETE_BACKUP_SUCCESS:
            return {
                ...state,
                isBackupDeleting: false,
                deleteBackupError: null,
            };
        case DELETE_BACKUP_FAILURE:
            return {
                ...state,
                isBackupDeleting: false,
                deleteBackupError: action.error,
            };
        default:
            return state;
    }
};

import axios from 'axios';
import { TemplateType } from '../app/csv/templates/reducer';
import api from './axios.main';

export interface ICreateTemplate {
    name: string;
    vendorType: string | null;
    template: TemplateType[];
}

export const csvApi = {
    async getAllTemplates(offset?: number, limit?: number) {
        const res = await api.get(`/csv/template?offset=${offset}&limit=${limit}`).then((res) => res.data);
        return res;
    },
    async getTemplateById(id: string) {
        const res = await api.get(`/csv/template/${id}`).then((res) => res.data);
        return res;
    },
    async deleteTemplateById(id: string) {
        const res = await api.delete(`/csv/template/${id}`).then((res) => res.data);
        return res;
    },
    async createTemplate(params: ICreateTemplate) {
        const res = await api.post(`/csv/template`, params).then((res) => res.data);
        return res;
    },
    async updateTemplateById(id: string, body: ICreateTemplate) {
        const res = await api.put(`/csv/template/${id}`, body).then((res) => res.data);
        return res;
    },
    async getAllConversions(offset?: number, limit?: number) {
        const res = await api.get(`/csv/conversion?offset=${offset}&limit=${limit}`).then((res) => res.data);
        return res;
    },
    async createConversion(params: { id: string; files: { model: string; name: string }[] }) {
        const res = await api.post(`/csv/conversion`, params).then((res) => res.data);
        return res;
    },
    async uploadCsv(url: string, body: File) {
        const res = await axios.put(url, body).then((res) => res.data);
        return res;
    },
    async completeUploadCsvToS3(id: string) {
        const res = await api.put(`/csv/conversion/${id}`).then((res) => res.data);
        return res;
    },
    async getConversionById(id: string) {
        const res = await api.get(`/csv/conversion/${id}`).then((res) => res.data);
        return res;
    },
    async runConversion(params: { id: string }) {
        const res = await api.post(`/csv/conversion/${params.id}`, params).then((res) => res.data);
        return res;
    },
    async getOutputDownloadLink(id: string) {
        const res = await api.get(`/csv/conversion/${id}/download-link`).then((res) => res.data);
        return res;
    },
    async deleteConversionById(id: string) {
        const res = await api.delete(`/csv/conversion/${id}`).then((res) => res.data);
        return res;
    },
};

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React, { useEffect } from 'react';
import { deleteBackup, fetchBackups } from '../../../app/backup/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { defaultStatusesView, defaultVendorsView } from '../../../enums';
import { filterData } from '../../../helpers/filterData';
import { getComparator, Order, stableSort } from '../../../helpers/tables';
import { useData } from '../../../hooks/useData';
import { Backup } from '../../../model';
import { EnhancedTableHead, HeadCell } from '../../common/Tables/EnhancedTableHead';
import { FilterType } from '../../common/Tables/FilterBar';
import { NoMatchFound } from '../../common/Tables/NoMatchFound';
import { Pagination } from '../../common/Tables/Pagination';
import { Loader } from '../../Loader/Loader';
import { BackupElement } from './BackupElement/BackupElement';
import { ToolBar } from './BackupsToolBar/ToolBar';
import { RestoreBackupModal } from './RestoreBackupModal/RestoreBackupModal';
import UploadBackupBar from './RestoreBackupModal/UploadBackupBar';

export const headCells: HeadCell[] = [
    { id: 'name', disablePadding: true, label: 'Name' },
    { id: 'vendor_type', disablePadding: false, label: 'Vendor Type' },
    { id: 'status', disablePadding: false, label: 'Status' },
    { id: 'last_cloud_upload', disablePadding: false, label: 'Last Upload' },
    { id: 'last_extract', disablePadding: false, label: 'Last Extract' },
    { id: 'restored_by_email', disablePadding: false, label: 'Restored By' },
];

const UPDATE_BACKUPS_TABLE_INTERVAL = 5000;

const initialFilter = {
    vendor: defaultVendorsView,
    status: defaultStatusesView,
    startDate: null,
    endDate: null,
    searchByName: '',
    deleted: false,
};

export const Backups: React.FC = () => {
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Backup>('last_cloud_upload');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    useData(UPDATE_BACKUPS_TABLE_INTERVAL, fetchBackups);
    const { backups, isFetching, inProgress } = useAppSelector((state) => state.backupStore);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const [filter, setFilter] = React.useState<FilterType>(initialFilter);
    const [isChecked, setIsCheched] = React.useState(true);
    const backupsInProgress = backups.filter(({ id }) => inProgress.includes(id));
    const handleModalClose = () => setIsModalOpen(false);

    useEffect(() => {
        function confirmExit() {
            if (inProgress.length > 0) {
                return 'show warning';
            }
        }
        window.onbeforeunload = confirmExit;
    }, [inProgress.length]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property as keyof Backup);
    };

    const filteredBackups = stableSort(filterData(backups, filter) as any, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsCheched(!isChecked);
        if (isChecked) {
            const newSelecteds = filteredBackups ? filteredBackups.map((n) => n.id) : [];
            setSelected(newSelecteds as string[]);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];
        if (selectedIndex !== -1) {
            newSelected = selected.filter((v) => v !== id);
        } else {
            newSelected = [...selected, id];
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const onDelete = () => {
        dispatch(deleteBackup(...selected));
        setSelected([]);
    };

    return (
        <>
            <ToolBar
                numSelected={selected.length}
                onDelete={onDelete}
                setIsModalOpen={setIsModalOpen}
                filter={filter}
                setFilter={setFilter}
            />
            <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={backups.length}
                        headCells={headCells}
                        showSelectAllCheckbox={true}
                    />
                    <TableBody>
                        {filteredBackups.map((row, index) => {
                            return (
                                <BackupElement
                                    key={row.id as string}
                                    row={row as Backup}
                                    index={index}
                                    isSelected={isSelected}
                                    handleClick={handleClick}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
                {filteredBackups.length === 0 && !isFetching && <NoMatchFound />}
                {backups.length === 0 && isFetching && <Loader />}
            </TableContainer>
            <Pagination
                count={backups.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <RestoreBackupModal isOpen={isModalOpen} close={handleModalClose} />
            {backupsInProgress.length > 0 && <UploadBackupBar backupInProgress={backupsInProgress} />}
        </>
    );
};

import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type LineChart = {
    title: string;
    data: ChartData<'line', number[], string>;
};

export const LineChart = ({ title, data }: LineChart) => {
    return (
        <Line
            style={{ maxWidth: '100%', margin: '0 10px 10px 10px' }}
            options={{
                normalized: false,
                spanGaps: false,
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                plugins: {
                    title: {
                        display: true,
                        text: 'Migrations',
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
                scales: {
                    y: {
                        type: 'linear' as const,
                        display: true,
                        position: 'left' as const,
                        title: {
                            display: true,
                            text: 'Migrations',
                            align: 'end',
                        },
                        ticks: {
                            precision: 0,
                        },
                    },
                    x: {
                        title: {
                            display: true,
                            text: title,
                        },
                    },
                },
            }}
            data={data}
        />
    );
};

import { ErrorActions, REMOVE_ERROR, SET_ERROR } from './error.types';

export type ErrorState = {
    errorMessage: string | null;
};

const initialState: ErrorState = {
    errorMessage: null,
};

export const ErrorReducer = (state: ErrorState = initialState, action: ErrorActions) => {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                errorMessage: action.message,
            };
        case REMOVE_ERROR:
            return {
                ...initialState,
            };
        default:
            return {
                ...state,
            };
    }
};

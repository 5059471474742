import { Paper } from '@material-ui/core';
import { fetchSystemInfo } from '../../../../app/dashboard/actions';
import { useAppSelector } from '../../../../app/hooks';
import { useData } from '../../../../hooks/useData';
import { useStyles } from './classes';
import { MemoryStatus } from './MemoryStatus';

enum title {
    disk = 'Disc Space',
    ram = 'Used RAM',
}

const labels = {
    disk: ['Used Disc Space', 'Free Disc Space'],
    ram: ['Used RAM', 'Free RAM'],
};

const UPDATE_INTERVAL = 5000;

export const ToolRealTimeInfo = () => {
    const classes = useStyles();
    useData(UPDATE_INTERVAL, fetchSystemInfo);
    const { disk, ram } = useAppSelector((state) => state.dashboardStore);
    return (
        <Paper className={classes.container}>
            <MemoryStatus memory={disk} labels={labels.disk} title={title.disk} />
            <MemoryStatus memory={ram} labels={labels.ram} title={title.ram} />
        </Paper>
    );
};

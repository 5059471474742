import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { convertDateToDateISO, convertShortDate } from './formatters';

export const filterMigrationsByCustomRange = (start: Date, end: Date) => {
    let date = addMonths(new Date(end), -5);
    const labels = [];
    const dates = [];
    if (Date.parse(start.toISOString()) >= Date.parse(date.toISOString())) {
        let isRangeByMouths = false;
        let startDate = convertShortDate(new Date(start));
        let endDate = convertShortDate(new Date(end));
        let i = Date.parse(startDate);
        do {
            labels.push(format(i, 'PP'));
            dates.push(i);
            i += 24 * 3600 * 1000;
        } while (i <= Date.parse(endDate));
        let title = `${startDate} - ${endDate}`;
        return { labels, dates, title, startDate, endDate, isRangeByMouths };
    } else {
        let isRangeByMouths = true;
        let startDate = convertShortDate(startOfMonth(start));
        let endDate = convertShortDate(endOfMonth(end));
        let i = Date.parse(startDate);
        do {
            labels.push(format(i, 'MMMM'));
            dates.push(i);
            i += 744 * 3600 * 1000;
        } while (i < Date.parse(endDate));
        let title = `${startDate} - ${endDate}`;
        return { labels, dates, title, startDate, endDate, isRangeByMouths };
    }
};

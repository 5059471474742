import { Box, Tab, Tabs } from '@material-ui/core';
import { Class, Home } from '@material-ui/icons';
import { ChangeEvent, FC } from 'react';
import { CSVTabs, CSVTabsType } from '../../../../enums';
import { useStyles } from './classes';

type TabsPanelProps = {
    csvTab: CSVTabsType;
    handleChangeTabs: (event: ChangeEvent<{}>, newTab: CSVTabsType | null) => void;
};

export const TabsPanel: FC<TabsPanelProps> = ({ csvTab, handleChangeTabs }) => {
    const classes = useStyles();
    return (
        <Box className={classes.buttonsContainer}>
            <Tabs value={csvTab} onChange={handleChangeTabs} variant="fullWidth" aria-label="Tabs for csv templates">
                <Tab
                    selected={csvTab === CSVTabs.allTemplates}
                    value={CSVTabs.allTemplates}
                    icon={<Class />}
                    label={CSVTabs.allTemplates}
                />
                <Tab
                    selected={csvTab === CSVTabs.createTemplate}
                    value={CSVTabs.createTemplate}
                    icon={<Home />}
                    label={CSVTabs.createTemplate}
                />
                <Tab
                    selected={csvTab === CSVTabs.conversions}
                    value={CSVTabs.conversions}
                    icon={<Class />}
                    label={CSVTabs.conversions}
                />
            </Tabs>
        </Box>
    );
};

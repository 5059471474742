import { JsonsNamesType } from '../../../enums';
import { ITemplate } from '../../../model';
import {
    CsvTemplatesAction,
    FETCH_TEMPLATES_FAILURE,
    SET_LIMIT_OFFSET,
    FETCH_TEMPLATES_SUCCESS,
    SET_IS_FETCHING,
    SET_CREATED_TEMPLATE,
    SET_IS_TEMPLATE_CREATED,
} from './actions-types';

export interface MappingType {
    destination: string;
    type?: string;
    source: string[] | string | MappingType[][] | number;
}

export interface TemplateType {
    model: JsonsNamesType;
    mapping: MappingType[];
}

export interface CsvFiles {
    model: string | null;
    csv: File | null;
    headers: string[];
    mapping: MappingType[];
    body: string[][];
}

export interface CsvTemplatesState {
    templates: ITemplate[];
    isFetching: boolean;
    createdTemplate: ITemplate | null;
    offset?: number;
    limit?: number;
    fetchTemplateError?: Error | null;
    isTemplateCreated?: boolean;
}

const initialState: CsvTemplatesState = {
    templates: [],
    createdTemplate: null,
    isFetching: false,
};

export const СsvTemplatesReducer = (
    state: CsvTemplatesState = initialState,
    action: CsvTemplatesAction
): CsvTemplatesState => {
    switch (action.type) {
        case SET_LIMIT_OFFSET:
            return {
                ...state,
                limit: action.limit,
                offset: action.offset,
            };
        case SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case FETCH_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.templates,
                isFetching: false,
            };
        case FETCH_TEMPLATES_FAILURE:
            return {
                ...state,
                fetchTemplateError: action.error,
            };
        case SET_CREATED_TEMPLATE:
            return {
                ...state,
                createdTemplate: action.createdTemplate,
            };
        case SET_IS_TEMPLATE_CREATED:
            return {
                ...state,
                isTemplateCreated: action.isTemplateCreated,
            };
        default:
            return state;
    }
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  paper: {
    maxWidth: '200px',
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
  },
  logoContainer: {
    maxWidth: '100%',
    padding: '20px',
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  dividerDown: {
    marginTop: 'auto',
  },
  selected: {
    backgroundColor: 'red',
  },
});

import { Button, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { changePassword } from '../../app/auth/auth.actions';
import { useAppDispatch } from '../../app/hooks';
import { useStyles } from './classes';
import { PasswordField } from './common';
import { resetPasswordValidation } from './validation';

interface FormValues {
    code: string;
    password: string;
    confirmPassword: string;
}

type ConfirmProps = {
    email: string;
};

export const ConfirmForgotPassword: FC<ConfirmProps> = ({ email }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const defaultValues: FormValues = {
        code: '',
        password: '',
        confirmPassword: '',
    };

    const formik = useFormik<FormValues>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            await dispatch(changePassword(email, values.code, values.password, values.confirmPassword));
        },
        validationSchema: resetPasswordValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit } = formik;
    const { code, password, confirmPassword } = values;

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <h1>Change your password</h1>
            <p>
                We've sent you an email with a code to reset your password. Please check your spam folder if you can't
                find this email in your inbox.
            </p>
            <TextField
                required
                className={classes.formInput}
                type="text"
                label="Code"
                name="code"
                value={code}
                onChange={handleChange}
                error={!!errors.code}
                helperText={errors?.code}
            />
            <PasswordField
                title="New password"
                name="password"
                value={password}
                onChange={handleChange}
                showPassword={showPassword}
                error={!!errors.password}
                helperText={errors?.password}
                handleShowPassword={handleClickShowPassword}
            />
            <PasswordField
                title="Confirm password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                showPassword={showConfirmPassword}
                error={!!errors.confirmPassword}
                helperText={errors?.confirmPassword}
                handleShowPassword={handleClickShowConfirmPassword}
            />
            <Button variant="outlined" color="primary" type="submit" className={classes.button}>
                Change password
            </Button>
        </form>
    );
};

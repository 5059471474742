import { createTheme } from '@material-ui/core';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#FF6125',
        },
        secondary: {
            main: '#666666',
        },
    },
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#ff61254f',
                    '&:hover': {
                        backgroundColor: '#ff612566',
                    },
                },
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '4px',
                '&$selected': { borderRadius: '10px', background: '#FF6125', color: 'white' },
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none',
            },
        },
    },
});

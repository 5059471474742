import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { updateGeneralSettings } from '../../../../app/appsettings/actions';
import { useAppDispatch } from '../../../../app/hooks';
import { backupAutodeleteEntries } from '../../../../enums/general-settings';
import { useStyles } from './classes';
import { SaveButton } from './SaveButton';

type PropsType = {
    BACKUP_EXPIRES_TIME: string;
};

export const GeneralSettingsForm: FC<PropsType> = ({ BACKUP_EXPIRES_TIME }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [isFetching, setIsFetching] = useState(false);
    const globalSettings = useFormik({
        initialValues: {
            BACKUP_EXPIRES_TIME,
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setIsFetching(true);
            await dispatch(updateGeneralSettings(values));
            setIsFetching(false);
        },
    });
    return (
        <form className={classes.settingsBlock} onSubmit={globalSettings.handleSubmit}>
            <h3>General settings</h3>
            <FormControl className={classes.textInput}>
                <InputLabel id="default-backup-autodelete-label">Backups Auto Delete</InputLabel>
                <Select
                    labelId="default-backup-autodelete-label"
                    id="default-backup-autodelete-select"
                    name="BACKUP_EXPIRES_TIME"
                    value={globalSettings.values.BACKUP_EXPIRES_TIME}
                    onChange={globalSettings.handleChange}
                >
                    {backupAutodeleteEntries.map((entry) => (
                        <MenuItem key={entry.value} value={entry.value}>
                            {entry.view}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText id="my-helper-text">
                    How long should a restored database remain before it is automatically deleted?
                </FormHelperText>
            </FormControl>
            <SaveButton isFetching={isFetching} />
        </form>
    );
};

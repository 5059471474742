import { IconButton, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff, InfoOutlined, PlayArrow, Refresh, Stop } from '@material-ui/icons';
import { FC } from 'react';
import { appsManagerApi } from '../../../api/appsManager-api';
import { AppName, AppNames, StatusMessages } from '../../../enums';
import { useStyles } from './classes';
import clsx from 'clsx';

type PropsType = {
    status: number | null;
    name: AppName;
    getHealthStatuses: () => void;
    message?: boolean | string | null;
};

export const HealthCheckIcons: FC<PropsType> = ({ status, name, getHealthStatuses, message }) => {
    const classes = useStyles();
    const stopExtractionApp = async (name: AppName) => {
        await appsManagerApi.stopExtractionApp(name);
        getHealthStatuses();
    };

    const startExtractionApp = async (name: AppName) => {
        await appsManagerApi.startExtractionApp(name);
        getHealthStatuses();
    };
    const restartExtractionApp = async (name: AppName) => {
        await appsManagerApi.restartExtractionApp(name);
        getHealthStatuses();
    };
    if (status === 200 && message === false) {
        return (
            <div className={classes.iconsGroup}>
                {(name === AppNames.backupsExtractionApp || name === AppNames.migrationTool) && (
                    <div>
                        <Tooltip title="Start service">
                            <IconButton aria-label="Start service" onClick={() => startExtractionApp(name)}>
                                <PlayArrow color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Restart service">
                            <IconButton aria-label="Restart service" onClick={() => restartExtractionApp(name)}>
                                <Refresh color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                <Tooltip title={StatusMessages.corrupted}>
                    <InfoOutlined className={clsx(classes.iconStatus, classes.off)} fontSize="large" />
                </Tooltip>
            </div>
        );
    }
    if (status === 200 && message)
        return (
            <div className={classes.iconsGroup}>
                {(name === AppNames.backupsExtractionApp || name === AppNames.migrationTool) && (
                    <div>
                        <Tooltip title="Stop service">
                            <IconButton aria-label="Stop service" onClick={() => stopExtractionApp(name)}>
                                <Stop color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Restart service">
                            <IconButton aria-label="Restart service" onClick={() => restartExtractionApp(name)}>
                                <Refresh color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                <Tooltip title={StatusMessages.success}>
                    <CheckCircleOutline className={clsx(classes.iconStatus, classes.on)} fontSize="large" />
                </Tooltip>
            </div>
        );
    else
        return (
            <div className={classes.iconsGroup}>
                {(name === AppNames.backupsExtractionApp || name === AppNames.migrationTool) && (
                    <div>
                        <Tooltip title="Restart service">
                            <IconButton aria-label="Restart service" onClick={() => restartExtractionApp(name)}>
                                <Refresh color="primary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                <Tooltip title={message as string}>
                    <HighlightOff className={clsx(classes.iconStatus, classes.error)} fontSize="large" />
                </Tooltip>
            </div>
        );
};

import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Dispatch, FC, SetStateAction } from 'react';
import { convertCsvTemplateToObj } from '../../../../helpers/convertCsvTemplateToObj';
import { useStyles } from './classes';
import { CreateTemplate } from './CreateTemplate';

type EditTemplatePropsType = {
    openEditor: boolean;
    setCsvTab: Dispatch<SetStateAction<'Create template' | 'All templates' | 'Conversions'>>;
    name: string;
    template: string;
    id: string;
    handleEditorClose: () => void;
};

export const EditTemplate: FC<EditTemplatePropsType> = ({
    openEditor,
    setCsvTab,
    name,
    template,
    id,
    handleEditorClose,
}) => {
    const classes = useStyles();
    const parsedTemplate = convertCsvTemplateToObj(template);
    const close = () => {
        handleEditorClose();
    };
    return (
        <Dialog maxWidth="lg" fullWidth open={openEditor} onClose={close}>
            <DialogTitle>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>Edit template</div>
                    <Tooltip title="Close">
                        <IconButton edge="start" onClick={close}>
                            <Clear fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent>
                <CreateTemplate
                    setCsvTab={setCsvTab}
                    editedTemplate={parsedTemplate}
                    templateName={name}
                    isEdit={true}
                    templateId={id}
                    close={close}
                />
            </DialogContent>
        </Dialog>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: 0,
    },
    openRestoreBackupModalButton: {
        marginRight: '15px',
    },
    restoreBackupIcon: {
        width: '50px',
        height: '50px',
    },
    deleteBackupButton: {
        marginLeft: 'auto',
    },
});

import { User, UserInterface } from '../../model';
import { DefaultVendor, UploadMethod } from '../../model';
import { DISABLE_USER, ENABLE_USER, SET_CURRENT_USER, SET_IS_FETCHING, SET_USERS, SET_USER_SETTINGS, UserAction } from './action-types';

export interface UserState {
    users: UserInterface[];
    currentUser: User | null;
    chunk_size: number;
    default_vendor: DefaultVendor;
    backup_source: UploadMethod;
    isFetching: boolean;
}

const initialState: UserState = {
    users: [],
    currentUser: null,
    chunk_size: 5000,
    default_vendor: 'mitchell',
    backup_source: 'LOCAL_FILE',
    isFetching: false,
};

export default (state: UserState = initialState, action: UserAction) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser,
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users,
            };
        case SET_USER_SETTINGS:
            return {
                ...state,
                ...action.settings,
            };
        case SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case DISABLE_USER:
            return {
                ...state,
                users: state.users.map(v => {
                    const { id } = action.disabledUser;
                    if (v.id === id) {
                        return action.disabledUser;
                    }
                    return v;
                }),
            };
        case ENABLE_USER:
            return {
                ...state,
                users: state.users.map(v => {
                    const { id } = action.enabledUser;
                    if (v.id === id) {
                        return action.enabledUser;
                    }
                    return v;
                }),
            };
        default:
            return state;
    }
};

import { Button, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import { IConversion, Migration } from '../../../../model';
import { Conversion } from '../../CSV/Conversions/Conversion/Conversion';
import { MigrationElement } from '../../Migrations/MigrationElement/MigrationElement';
import { useStyles } from './classes';

enum conversionType {
    migration = 'migration',
    csvConversion = 'csvConversion',
}

type ConversionType = `${conversionType}`;

export enum title {
    migrations = 'Running migrations',
    csbConversions = 'Running csv conversions',
}

type TitleType = `${title}`;

type LastConversions = {
    runningConversions: Migration[] | IConversion[];
    type: ConversionType;
    title: TitleType;
    children: JSX.Element;
};

export const RunningConversions = ({ runningConversions, type, title, children }: LastConversions) => {
    const classes = useStyles();
    const [lastIndex, setLastIndex] = useState(5);
    const showMoreMigrations = () => {
        setLastIndex((prev) => prev + 5);
    };
    if (runningConversions.length) {
        return (
            <Paper className={classes.constainer}>
                <Typography className={classes.title}>{title}</Typography>
                {children}
                {runningConversions.length &&
                    runningConversions
                        .slice(0, lastIndex)
                        .map((conversion) =>
                            type === conversionType.migration ? (
                                <MigrationElement key={conversion.id} migration={conversion as Migration} />
                            ) : (
                                <Conversion key={conversion.id} conversion={conversion as IConversion} />
                            )
                        )}
                {runningConversions.length > lastIndex && (
                    <Button className={classes.btn} variant="outlined" color="primary" onClick={showMoreMigrations}>
                        Show more
                    </Button>
                )}
            </Paper>
        );
    }
    return null;
};

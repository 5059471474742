import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ChangeEvent, createRef, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Loader } from '../../..';
import { fetchConversions } from '../../../../app/csv/conversions/actions';
import { useAppSelector } from '../../../../app/hooks';
import { CSVTabsType } from '../../../../enums';
import { filterCsv } from '../../../../helpers/filterData';
import { useData } from '../../../../hooks/useData';
import { usePagination } from '../../../../hooks/usePagination';
import { IConversion } from '../../../../model';
import { NoMatchFound } from '../../../common/Tables/NoMatchFound';
import { Filter } from '../Templates/Filter';
import { CsvFilterType } from '../Templates/Templates';
import { useStyles } from './classes';
import { Conversion } from './Conversion/Conversion';

const UPDATE_CONVERSIONS_INTERVAL = 10000;
const PER_PAGE = 10;

type PropsType = {
    csvTab: CSVTabsType;
    setCsvTab: Dispatch<SetStateAction<CSVTabsType>>;
};

const defaultFilter: CsvFilterType = {
    showDeleted: false,
    searchByName: '',
};

export const ConversionsHeader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.header}>
            <span>name</span>
            <span style={{ paddingLeft: '22px' }}>status</span>
            <span>created at</span>
            <span>created by</span>
            <span>actions</span>
        </Box>
    );
};

export const Conversions: FC<PropsType> = () => {
    const classes = useStyles();
    const ref = createRef<HTMLDivElement>();
    useData(UPDATE_CONVERSIONS_INTERVAL, fetchConversions);
    const { conversions, isFetching } = useAppSelector((state) => state.csvConversionsStore);
    const [filter, setFilter] = useState<CsvFilterType>(defaultFilter);
    const filteredConversions = filterCsv(conversions, filter);
    const [page, setPage] = useState(1);
    const count = Math.ceil(filteredConversions.length / PER_PAGE);
    const _conversions = usePagination(filteredConversions, PER_PAGE, ref);
    const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
        setPage(page);
        _conversions.jump(page);
        _conversions.scroll();
    };

    useEffect(() => {
        setPage(1);
        _conversions.jump(1);
    }, [filteredConversions.length]);
    return (
        <div ref={ref} className={classes.content}>
            <Filter filter={filter} setFilter={setFilter} />
            <ConversionsHeader />
            <Box>
                {_conversions.currentData().map((conversion: IConversion) => (
                    <Conversion key={conversion.id} conversion={conversion} />
                ))}
            </Box>
            {isFetching && <Loader />}
            {!isFetching && filteredConversions.length === 0 && <NoMatchFound />}
            {!isFetching && filteredConversions.length !== 0 && (
                <Pagination
                    className={classes.pagination}
                    count={count}
                    size="large"
                    page={page}
                    variant="text"
                    color="primary"
                    onChange={handleChangePage}
                />
            )}
        </div>
    );
};

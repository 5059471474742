export enum StatusColors {
    error = '#ff1f1f',
    success = '#00ff00',
    corrupted = '#ffd900',
}

export enum StatusMessages {
    error = 'Error',
    success = 'On',
    corrupted = 'Off',
}

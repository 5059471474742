import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { format } from 'timeago.js';
import { BackupStatus } from '../../../../enums';
import { deletedStyle } from '../../../../helpers/styles';
import { Backup } from '../../../../model';
import { BackupStatusElement } from '../BackupStatusElement/backup-status-element';
import { BackupError } from './BackupError';
import { useStyles } from './classes';

interface BackupElementProps {
    row: Backup;
    index: number;
    isSelected: (id: string) => boolean;
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
}

export const BackupElement: FC<BackupElementProps> = ({ row, index, isSelected, handleClick }) => {
    const classes = useStyles();
    const [expand, setExpand] = useState<boolean>(false);
    const handleExpandClick = () => {
        setExpand(!expand);
    };

    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const showError = ([BackupStatus.error, BackupStatus.deleteError] as string[]).includes(row.status) && row.error;
    return (
        <>
            <TableRow
                style={{ opacity: deletedStyle(row.deleted) }}
                hover
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell align="center" component="td" id={labelId} padding="none" style={{ paddingRight: '26px' }}>
                    {row.name}
                </TableCell>
                <TableCell align="center" style={{ paddingRight: '26px' }}>
                    {row.vendor_type}
                </TableCell>
                <TableCell align="center">
                    {showError ? (
                        <button className={classes.statusBlock} onClick={handleExpandClick}>
                            <ExpandMore
                                className={clsx(classes.expandIcon, expand && classes.expanded)}
                                fontSize="small"
                            />
                            <BackupStatusElement status={row.status as string} />
                        </button>
                    ) : (
                        <BackupStatusElement status={row.status as string} />
                    )}
                </TableCell>
                <TableCell align="center" style={{ paddingRight: '26px' }}>
                    {(row.last_cloud_upload && format(row.last_cloud_upload as string)) || '-'}
                </TableCell>
                <TableCell align="center" style={{ paddingRight: '26px' }}>
                    {(row.last_extract && format(row.last_extract as string)) || '-'}
                </TableCell>
                <TableCell align="center" style={{ paddingRight: '26px' }}>
                    {row.restored_by_email}
                </TableCell>
            </TableRow>
            <BackupError expand={expand} error={row.error as string} />
        </>
    );
};

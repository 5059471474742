import { useFormik } from 'formik';
import { useState } from 'react';
import { authApi } from '../../../../api/auth-api';
import { setError } from '../../../../app/error/error.actions';
import { useAppDispatch } from '../../../../app/hooks';
import { setSuccess } from '../../../../app/success/success.actions';
import { PasswordField } from '../../../Auth/common';
import { changePasswordValidation } from '../../../Auth/validation';
import { useStyles } from './classes';
import { SaveButton } from './SaveButton';

export type ChangePasswordData = {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
};

const defaultValues: ChangePasswordData = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
};

export const PasswordChangeForm = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [isFetching, setIsFetching] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const formik = useFormik<ChangePasswordData>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            setIsFetching(true);
            try {
                const res = await authApi.passwordChange(values.oldPassword, values.newPassword);
                if (res) {
                    dispatch(setSuccess('Password changed successfully!'));
                    resetForm();
                }
            } catch (error: any) {
                dispatch(setError(error.response?.data?.message || error.message));
            }
            setIsFetching(false);
        },
        validationSchema: changePasswordValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit } = formik;
    const { oldPassword, newPassword, newPasswordConfirm } = values;

    return (
        <form className={classes.settingsBlock} onSubmit={handleSubmit}>
            <h3>Change password</h3>
            <PasswordField
                title="Current Password"
                name="oldPassword"
                value={oldPassword}
                onChange={handleChange}
                showPassword={showCurrentPassword}
                error={!!errors.oldPassword}
                helperText={errors?.oldPassword}
                handleShowPassword={handleShowCurrentPassword}
            />
            <PasswordField
                title="New Password"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                showPassword={showNewPassword}
                error={!!errors.newPassword}
                helperText={errors?.newPassword}
                handleShowPassword={handleShowNewPassword}
            />
            <PasswordField
                title="Confirm Password"
                name="newPasswordConfirm"
                value={newPasswordConfirm}
                onChange={handleChange}
                showPassword={showConfirmPassword}
                error={!!errors.newPasswordConfirm}
                helperText={errors?.newPasswordConfirm}
                handleShowPassword={handleShowConfirmPassword}
            />
            <SaveButton isFetching={isFetching} />
        </form>
    );
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    dialog: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        marginTop: '8px',
    },
});

export enum AppNames {
    extractionAppManager = 'extraction-apps-manager',
    backupsExtractionApp = 'backups-extraction-app',
    migrationTool = 'migration-tool',
    foxProConnector = 'fox-pro-connector',
}

export const AppNameViews = {
    [AppNames.extractionAppManager]: 'Extraction App Manager service',
    [AppNames.backupsExtractionApp]: 'Backups Extraction service',
    [AppNames.migrationTool]: 'Migration Tool service',
    [AppNames.foxProConnector]: 'Fox Pro service',
};

export type AppName = `${AppNames}`;

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Add, Edit, ExpandMore, Remove } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CsvFiles, MappingType } from '../../../../app/csv/templates/reducer';
import { JsonFieldTypes, jsonsNames } from '../../../../enums';
import { mapModel } from '../../../../helpers/csv-exports';
import { SearchField } from '../../../common/Forms/SearchField';
import { NoMatchFound } from '../../../common/Tables/NoMatchFound';
import { useStyles } from './classes';
import { DefaultFieldModal } from './DefaultFieldModal';
import { PreviewCsv } from './PreviewCsv';
import { PreviewJSON } from './PreviewJSON';

const CSVColumn: FC<{ header: string }> = ({ header }) => {
    const classes = useStyles();
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'csvHeader',
        options: {
            dropEffect: 'move',
        },
        item: {
            id: header + 1,
            name: header,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    return (
        <div
            className={classes.dragElement}
            ref={drag}
            key={header}
            style={{
                border: isDragging ? '2px solid #ff6125' : '0px',
            }}
        >
            :: {header}
        </div>
    );
};

type ActionFieldProps = {
    clearField: () => void;
    setIsModalOpen: (value: SetStateAction<boolean>) => void;
};

const ActionField: FC<ActionFieldProps> = ({ clearField, setIsModalOpen }) => {
    return (
        <div>
            <Tooltip title="Clear the field">
                <IconButton size="small" color="primary" onClick={clearField}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Default field value">
                <IconButton size="small" color="primary" onClick={() => setIsModalOpen(true)}>
                    <Edit fontSize="small" />
                </IconButton>
            </Tooltip>
        </div>
    );
};

type JsonColProps = {
    board: MappingType;
    setBoards: Dispatch<SetStateAction<MappingType[]>>;
    boards: MappingType[];
};

export const JsonColumn: FC<JsonColProps> = ({ board, setBoards, boards }) => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCleared, setIsCleared] = useState(0);
    const defaultBoardtype = board?.type;
    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const addCsvHeaderToBoard = (id: string, name: string) => {
        setBoards(boards.filter((board) => id !== board.destination));
        defaultBoardtype ? (board.type = defaultBoardtype) : delete board.type;
        if (board.type === JsonFieldTypes.stringConcat || board.type === JsonFieldTypes.intArray) {
            board.source = [...(board.source as string[]), name];
        } else {
            board.source = name;
        }
    };

    const clearField = () => {
        if (board.type === JsonFieldTypes.stringConcat || board.type === JsonFieldTypes.intArray) {
            setIsCleared((prevDef) => prevDef + 1);
            board.source = [];
        } else {
            setIsCleared((prevDef) => prevDef + 1);
            board.source = '';
        }
    };
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: 'csvHeader',
        drop: (item: any) => addCsvHeaderToBoard(item.id as string, item.name),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }));
    if (board.destination === 'phones') return null;
    return (
        <div
            className={classes.dropBoard}
            ref={drop}
            style={{
                borderRadius: '15px',
                border: isOver ? '2px solid #ff6125' : 'none',
                backgroundColor: canDrop ? '#ffdfd3' : 'transparent',
                height:
                    board.type === JsonFieldTypes.stringConcat || board.type === JsonFieldTypes.intArray
                        ? '60px'
                        : 'none',
            }}
        >
            <KeyboardBackspaceIcon color="primary" />
            {board.source instanceof Array ? (
                <div
                    style={{
                        overflow:
                            board.type === JsonFieldTypes.stringConcat || board.type === JsonFieldTypes.intArray
                                ? 'auto'
                                : 'none',
                    }}
                >
                    {board.source.map((item) => `${item}, `)}
                </div>
            ) : (
                board.source
            )}
            <ActionField clearField={clearField} setIsModalOpen={setIsModalOpen} />
            <DefaultFieldModal isOpen={isModalOpen} close={handleModalClose} board={board} />
        </div>
    );
};

type PhoneColType = {
    phoneMapping: MappingType;
};

const PhonesColumn = ({ phoneMapping }: PhoneColType) => {
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCleared, setIsCleared] = useState(0);
    const handleModalClose = () => setIsModalOpen(false);
    const defaultBoardtype = phoneMapping?.type;
    const addCsvHeaderToBoard = (id: string, name: string) => {
        phoneMapping.source = name;
        defaultBoardtype ? (phoneMapping.type = defaultBoardtype) : delete phoneMapping.type;
    };
    const clearField = () => {
        setIsCleared((prevDef) => prevDef + 1);
        phoneMapping.source = '';
    };
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: 'csvHeader',
        drop: (item: any) => addCsvHeaderToBoard(item.id as string, item.name),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    }));
    return (
        <div
            className={classes.dropBoard}
            ref={drop}
            style={{
                borderRadius: '15px',
                border: isOver ? '2px solid #ff6125' : 'none',
                backgroundColor: canDrop ? '#ffdfd3' : 'transparent',
            }}
        >
            <KeyboardBackspaceIcon color="primary" />
            {phoneMapping.source}
            <ActionField clearField={clearField} setIsModalOpen={setIsModalOpen} />
            <DefaultFieldModal isOpen={isModalOpen} close={handleModalClose} board={phoneMapping} />
        </div>
    );
};

type MappingTableProps = {
    csv: File;
    model: string | null;
    mapping: MappingType[];
    headers: string[];
    body: string[][];
    setCsvFiles: Dispatch<SetStateAction<CsvFiles[]>>;
    phonesMapping: {
        source: string;
        destination: string;
    }[][];
    handleAddPhoneItem: () => void;
    handleDeletePhoneItem: (indexDel: number) => void;
    createPreviewTemplate: () => any;
};

export const MappingTable = (props: MappingTableProps) => {
    const classes = useStyles();
    const {
        headers,
        mapping,
        model,
        phonesMapping,
        handleAddPhoneItem,
        handleDeletePhoneItem,
        body,
        csv,
        createPreviewTemplate,
    } = props;
    const [boards, setBoards] = useState(mapping);
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [searchCsvHeader, setSearchCsvHeader] = useState('');
    const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchCsvHeader(e.target.value);
    };
    const [previewJson, setPreviewJson] = useState<any[]>([]);

    const filterCsvHeader = () => {
        let filteredHeaders = headers;
        if (searchCsvHeader) {
            filteredHeaders = filteredHeaders.filter((header) =>
                header.toLocaleLowerCase().includes(searchCsvHeader.toLowerCase())
            );
        }
        return filteredHeaders;
    };

    const filteredHeaders = filterCsvHeader();

    const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [openPreview, setOpenPreview] = useState(false);
    const handlePreviewOpen = () => setOpenPreview(true);
    const handlePreviewClose = () => setOpenPreview(false);

    const [openPreviewJson, setOpenPreviewJson] = useState(false);
    const handlePreviewJsonOpen = () => setOpenPreviewJson(true);
    const handlePreviewJsonClose = () => setOpenPreviewJson(false);

    return (
        <Paper className={classes.accordion}>
            <PreviewCsv
                headers={headers}
                body={body}
                model={model!}
                openPreview={openPreview}
                handlePreviewClose={handlePreviewClose}
            />
            <DndProvider backend={HTML5Backend}>
                <Accordion
                    style={{ boxShadow: 'none' }}
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={classes.accordionTitle}>{model}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div>
                                <Typography className={classes.accordionSubtitle}>Jsons fields</Typography>
                                <>
                                    {boards.map((field) =>
                                        field.destination === 'phones' ? null : (
                                            <div
                                                className={classes.dropBoard}
                                                key={field.destination}
                                                style={{
                                                    height:
                                                        field.destination === 'address' &&
                                                        field.type === JsonFieldTypes.stringConcat
                                                            ? '60px'
                                                            : 'none',
                                                }}
                                            >
                                                {field.destination}
                                                {field.destination === 'government_id' && '(RG)'}
                                                {field.destination === 'tax_id' && '(CPF)'}
                                            </div>
                                        )
                                    )}
                                    {model === jsonsNames.customers && (
                                        <div>
                                            phones:
                                            {phonesMapping.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            key={index}
                                                            style={{
                                                                marginTop: '16px',
                                                                marginBottom: '16px',
                                                                position: 'relative',
                                                            }}
                                                        >
                                                            {item.map((item) => {
                                                                return (
                                                                    <div
                                                                        className={classes.dropBoard}
                                                                        key={item.destination}
                                                                    >
                                                                        {item.destination}
                                                                    </div>
                                                                );
                                                            })}
                                                            <Tooltip
                                                                title="Delete phone fields"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                    right: '0',
                                                                }}
                                                            >
                                                                <IconButton
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={() => handleDeletePhoneItem(index)}
                                                                >
                                                                    <Remove fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {model === jsonsNames.customers && (
                                        <Tooltip title="Add phone fields">
                                            <IconButton size="small" color="primary" onClick={handleAddPhoneItem}>
                                                <Add fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            </div>
                            <div style={{ minWidth: '210px', marginLeft: '22px' }}>
                                <Typography className={classes.accordionSubtitle}>CSV Columns:</Typography>
                                <>
                                    {boards.map((board) => (
                                        <JsonColumn
                                            key={board.destination}
                                            board={board}
                                            setBoards={setBoards}
                                            boards={boards}
                                        />
                                    ))}
                                    {model === jsonsNames.customers && (
                                        <div className={classes.phonesItems}>
                                            {phonesMapping.map((item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{ marginTop: '16px', marginBottom: '16px' }}
                                                    >
                                                        {item.map((item) => {
                                                            return (
                                                                <PhonesColumn
                                                                    key={item.destination}
                                                                    phoneMapping={item}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography className={classes.accordionSubtitle}>CSV column:</Typography>
                                <Button color="primary" size="small" onClick={handlePreviewOpen}>
                                    Preview CSV
                                </Button>
                            </div>
                            <SearchField handleSearch={handleSearch} searchValue={searchCsvHeader} />
                            <div className={classes.dragsElContainer}>
                                {filteredHeaders.map((header) => {
                                    return <CSVColumn key={header} header={header} />;
                                })}
                            </div>
                            {filteredHeaders.length === 0 && <NoMatchFound />}
                        </div>
                    </AccordionDetails>
                    <Button
                        style={{ marginLeft: '16px', marginBottom: '16px' }}
                        color="primary"
                        size="small"
                        onClick={async () => {
                            const mapping = createPreviewTemplate().template.find(
                                (template: any) => template.model === model
                            ).mapping;
                            await mapModel(csv, mapping, setPreviewJson);
                            handlePreviewJsonOpen();
                        }}
                    >
                        Preview output JSON
                    </Button>
                    <PreviewJSON
                        model={model}
                        previewJson={previewJson}
                        openPreviewJson={openPreviewJson}
                        handlePreviewJsonClose={handlePreviewJsonClose}
                    />
                </Accordion>
            </DndProvider>
        </Paper>
    );
};

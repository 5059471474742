import { Card, CardContent, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { shortEnglishHumanizer } from '../../../../helpers/formatters';
import { useStyles } from './classes';

type AverageMigrationTime = {
    durations: number | null;
};

export const AverageMigrationTime = () => {
    const classes = useStyles();
    const { migrations } = useAppSelector((state) => state.migrationStore);
    const [durations, setDurations] = useState<number | null>(null);
    const calculateDuration = () => {
        if (migrations.length) {
            const allDurations = migrations.reduce((accumulator, migration) => {
                if (migration.end_ts) {
                    return accumulator + Date.parse(migration.end_ts) - Date.parse(migration.start_ts);
                } else {
                    return accumulator + 0;
                }
            }, 0);
            const durationAvr = allDurations / migrations.length;
            return durationAvr;
        } else {
            return null;
        }
    };
    useEffect(() => {
        setDurations(calculateDuration());
    }, [migrations.length]);
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography gutterBottom align="center" variant="h6" component="h2">
                    Average migration time
                </Typography>
                <Typography align="center" variant="h6" color="textSecondary" component="h2">
                    {durations && shortEnglishHumanizer(durations, { round: true, largest: 2, delimiter: ' ' })}
                </Typography>
            </CardContent>
        </Card>
    );
};

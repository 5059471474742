import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    constainer: {
        padding: '10px',
    },
    title: {
        paddingBottom: '4px',
    },
    btn: {
        width: '100%',
    },
});

export enum BackupSource {
    localFile = 'LOCAL_FILE',
    s3Link = 'S3_LINK',
}

export type BackupSourceType = `${BackupSource}`;

export const BackupSourceView: any = {
    [BackupSource.localFile]: 'Local File',
    [BackupSource.s3Link]: 'S3 link',
};

export const backupSourceEntries = Object.keys(BackupSource).map((v) => {
    const value = (BackupSource as any)[v];
    const view = BackupSourceView[value];
    return { value, view };
});

export enum FilterBackups {
    name = 'Name',
    email = 'Email',
    vendor = 'Vendor',
}

export enum BackupModel {
    uploadingToCloud = 'Uploading to cloud',
    uploadedToCloud = 'Uploaded to cloud',
    extracting = 'Extracting',
    extracted = 'Extracted',
}

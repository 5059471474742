import { AppName, AppNames } from '../enums';
import api, { responseInterceptor } from './axios.main';

const healthIntance = Object.assign({}, api);

healthIntance.interceptors.response.eject(responseInterceptor);

export const healthApi = {
    async getStatus(appName?: AppName) {
        const route = appName !== AppNames.extractionAppManager ? `/health-check?appName=${appName}` : '/health-check';
        const res = await api
            .get(route)
            .then((res) => res)
            .catch((error) => {
                const err = error.response || error.message;
                return err;
            });
        return res;
    },
};

import * as yup from 'yup';
/*eslint-disable */
yup.setLocale({
    mixed: {
        required: 'Required field',
    },
    string: {
        min: 'Enter at least ${min} characters',
        max: 'Maximum ${max} characters',
        email: 'Enter valid Email',
    },
});

export const loginValidation = yup.object().shape({
    email: yup.string().email().min(2).max(100).required(),
    password: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
    temp_password: yup.string().min(2).max(50).required(),
    confirm: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
});

export const sendResetCodeValidation = yup.object().shape({
    email: yup.string().email().min(2).max(100).required(),
});

export const resetPasswordValidation = yup.object().shape({
    code: yup.string().required(),
    password: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
    confirmPassword: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
});

export const singInValidation = yup.object().shape({
    email: yup.string().email().min(2).max(100).required(),
    password: yup.string().min(8).max(50).required(),
});

export const changePasswordValidation = yup.object().shape({
    oldPassword: yup.string().min(8).max(50).required(),
    newPassword: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
    newPasswordConfirm: yup
        .string()
        .min(8)
        .max(50)
        .required()
        .oneOf([yup.ref('newPassword')], 'Passwords must match')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
});
/*eslint-enable */

import { SET_IS_AUTH, SET_IS_RESTORED_PASSWORD, SET_LOADING } from './auth.types';
import { AuthActions } from './auth.types';

export type AuthState = {
    isAuthorized: boolean;
    isLoading: boolean;
    isRestoredPassword: boolean;
};

const initialState: AuthState = {
    isAuthorized: false,
    isLoading: true,
    isRestoredPassword: false,
};

export const AuthReducer = (state: AuthState = initialState, action: AuthActions) => {
    switch (action.type) {
        case SET_IS_AUTH:
            return {
                ...state,
                isAuthorized: action.isAuth,
            };
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_IS_RESTORED_PASSWORD:
            return {
                ...state,
                isRestoredPassword: action.isRestoredPassword,
            };
        default:
            return state;
    }
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    container: {
        gridColumn: '1 / 8',
        backgroundColor: '#000',
        padding: '10px',
        color: 'rgb(242, 70, 70)',
        fontWeight: 600,
    },
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        width: '300px',
        margin: '10px',
        background: '#FFFFFF',
        border: '1px solid #DFE0EB',
        bordeRadius: '8px',
        '&:hover': {
            borderColor: '#FF6125',
            color: '#FF6125',
        },
    },
});

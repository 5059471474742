import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { getUserAuth } from './app/auth/auth.actions';
import { removeError } from './app/error/error.actions';
import { useAppDispatch } from './app/hooks';
import { AppState } from './app/root.reducer';
import { removeSuccess } from './app/success/success.actions';
import { Auth, Loader, Main, Notification } from './components';
import { Confirm } from './components/Auth/Confirm';
import { Login } from './components/Auth/Login';
import { Restore } from './components/Auth/Restore';
import { Backups } from './components/Main/Backups/Backups';
import { CSV } from './components/Main/CSV/CSV';
import { Dashboard } from './components/Main/Dashboard/Dashboard';
import { Healthchek } from './components/Main/Healthcheck/Healthcheck';
import { Migrations } from './components/Main/Migrations/Migrations';
import { Settings } from './components/Main/Settings/Settings';
import { Users } from './components/Main/Users/Users';
import { AppRouteNames, AppRoutes } from './enums';
import { ProtectedRoute } from './hocs/protected.route';

function App() {
    const dispatch = useAppDispatch();
    const { isAuthorized, isLoading } = useSelector((state: AppState) => state.authStore);
    const { errorMessage } = useSelector((state: AppState) => state.errorStore);
    const { successMessage } = useSelector((state: AppState) => state.successStore);

    useLayoutEffect(() => {
        dispatch(getUserAuth());
    }, [dispatch]);

    return (
        <div className="App">
            {isLoading ? (
                <Loader />
            ) : (
                <Router>
                    <Routes>
                        <Route path={AppRouteNames.root} element={<Navigate to={AppRoutes.migrations} />} />
                        <Route path={AppRouteNames.main} element={<Navigate to={AppRoutes.migrations} />} />
                        <Route
                            path={AppRouteNames.main}
                            element={
                                <ProtectedRoute isAuthenticated={isAuthorized} authenticationPath={AppRoutes.authLogin}>
                                    <Main />
                                </ProtectedRoute>
                            }
                        >
                            <Route path={AppRouteNames.users} element={<Users />} />
                            <Route path={AppRouteNames.migrations} element={<Migrations />} />
                            <Route path={AppRouteNames.settings} element={<Settings />} />
                            <Route path={AppRouteNames.backups} element={<Backups />} />
                            <Route path={AppRouteNames.health} element={<Healthchek />} />
                            <Route path={AppRouteNames.csv} element={<CSV />} />
                            <Route path={AppRouteNames.dashboard} element={<Dashboard />} />
                        </Route>
                        <Route path={AppRouteNames.auth} element={<Auth />}>
                            <Route path={AppRouteNames.authLogin} element={<Login />} />
                            <Route path={AppRouteNames.authConfirm} element={<Confirm />} />
                            <Route path={AppRouteNames.reset} element={<Restore />} />
                        </Route>
                        <Route path="*" element={<Navigate to={AppRouteNames.root} />} />
                    </Routes>
                </Router>
            )}
            {errorMessage && <Notification message={errorMessage} removeMessage={removeError} typeMessage="error" />}
            {successMessage && (
                <Notification message={successMessage} removeMessage={removeSuccess} typeMessage="success" />
            )}
        </div>
    );
}

export default App;

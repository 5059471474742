import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { changePassByAdmin } from '../../../../../app/user/actions';
import { ClipboardButton } from '../../../../common/DownloadGroup';
import { useStyles } from './classes';
import { newPasswordValidation } from './validation';

type ResetPasswordProps = {
    open: boolean;
    handleClose: () => void;
    email: string;
};

type FormValues = {
    newPassword: string;
};

export const ResetPasswordModal: FC<ResetPasswordProps> = ({ open, handleClose, email }) => {
    const classes = useStyles();
    const [isCoppied, setIsCoppied] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const defaultValues: FormValues = {
        newPassword: '',
    };

    const formik = useFormik<FormValues>({
        initialValues: defaultValues,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            setLoading(true);
            await dispatch(changePassByAdmin(email, values.newPassword));
            setLoading(false);
        },
        validationSchema: newPasswordValidation,
        enableReinitialize: true,
    });

    const { values, handleChange, errors, handleSubmit, setFieldValue } = formik;
    const { newPassword } = values;

    const closeModal = () => {
        handleClose();
        setFieldValue('newPassword', '');
    };

    const passwordCopyClick = () => {
        navigator.clipboard.writeText(newPassword);
        setIsCoppied(true);
        setTimeout(() => {
            setIsCoppied(false);
        }, 2500);
    };

    return (
        <Dialog maxWidth="xs" fullWidth open={open} onClose={closeModal} aria-labelledby="reset-user-password-dialog">
            <DialogTitle className={classes.title}>Change user password</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent className={classes.content}>
                    <TextField
                        margin="normal"
                        label="New password"
                        fullWidth
                        type="text"
                        name="newPassword"
                        onChange={handleChange}
                        error={!!errors.newPassword}
                        helperText={errors?.newPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ClipboardButton
                                        isCoppied={isCoppied}
                                        handleCopyClick={passwordCopyClick}
                                        itemToCopy="password"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <DialogActions>
                        <Button onClick={closeModal} color="secondary">
                            Close
                        </Button>
                        <Button type="submit" color="primary" variant="contained" disabled={loading}>
                            Change password
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    );
};

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { AppState } from '../../../app/root.reducer';
import { fetchUsers } from '../../../app/user/actions';
import { AppRoutes, UserRoles } from '../../../enums';
import { getComparator, Order, stableSort } from '../../../helpers/tables';
import { UserInterface } from '../../../model';
import { EnhancedTableHead, HeadCell } from '../../common/Tables/EnhancedTableHead';
import { NoMatchFound } from '../../common/Tables/NoMatchFound';
import { Pagination } from '../../common/Tables/Pagination';
import { Loader } from '../../Loader/Loader';
import { ToolBar } from './ToolBar/ToolBar';
import { User } from './User/User';
import { UserModal } from './User/UserModals/UserModal';

const headCells: HeadCell[] = [
    { id: 'email', disablePadding: true, label: 'email' },
    { id: 'role', disablePadding: false, label: 'role' },
    { id: 'confirmed', disablePadding: false, label: 'confirmed' },
    { id: 'disabled', disablePadding: false, label: 'disabled' },
    { id: 'actions', disablePadding: false, label: 'actions' },
];

export const Users: React.FC = () => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof UserInterface>('email');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const { users, currentUser, isFetching } = useSelector((store: AppState) => store.userStore);
    const [searchBar, setSearchBar] = React.useState('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    React.useLayoutEffect(() => {
        dispatch(fetchUsers());
    }, []);
    React.useLayoutEffect(() => {
        if (currentUser && currentUser.role !== UserRoles.admin) {
            navigate(AppRoutes.migrations);
        }
    }, []);
    const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property as keyof UserInterface);
    };

    const filterTable = () => {
        let sortedUsers = users;
        if (searchBar) {
            sortedUsers = sortedUsers.filter((user) =>
                user.email.toString().toLowerCase().includes(searchBar.toLowerCase())
            );
        }
        return sortedUsers;
    };

    const filteredUsers = stableSort(filterTable(), getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };
    return (
        <>
            <ToolBar handleModalOpen={handleModalOpen} searchBar={searchBar} setSearchBar={setSearchBar} />
            <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={users.length}
                        headCells={headCells}
                    />

                    <TableBody>
                        {filteredUsers.map((user, index) => {
                            return <User user={user} index={index} key={user.email} />;
                        })}
                    </TableBody>
                </Table>
                {filteredUsers.length === 0 && !isFetching && <NoMatchFound />}
                {filteredUsers.length === 0 && isFetching && <Loader />}
            </TableContainer>

            <Pagination
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <UserModal open={openModal} handleClose={handleModalClose} />
        </>
    );
};

export enum CSVTabs {
    createTemplate = 'Create template',
    allTemplates = 'All templates',
    conversions = 'Conversions',
}

export type CSVTabsType = `${CSVTabs}`;

export enum ConversionStatus {
    queued = 'QUEUED',
    uploadingInput = 'UPLOADING_INPUT',
    inputUploaded = 'INPUT_UPLOADED',
    inputUploadFailed = 'INPUT_UPLOAD_FAILED',
    processing = 'PROCESSING',
    finished = 'FINISHED',
    error = 'ERROR',
    deleted = 'DELETED',
}

export type ConversionStatusType = `${ConversionStatus}`;

export enum ConversionStatusColor {
    failed = 'rgb(242, 70, 70)',
    success = 'rgb(4, 155, 74)',
    processing = 'rgb(127, 127, 127)',
}

export const ConversionStatusView: any = {
    [ConversionStatus.queued]: 'Queued',
    [ConversionStatus.uploadingInput]: 'Uploading input',
    [ConversionStatus.inputUploaded]: 'Input uploaded',
    [ConversionStatus.inputUploadFailed]: 'input upload failed',
    [ConversionStatus.processing]: 'Processing',
    [ConversionStatus.finished]: 'Finished',
    [ConversionStatus.error]: 'Error',
    [ConversionStatus.deleted]: 'Deleted',
};

export enum jsonsNames {
    customers = 'customers',
    inventory = 'inventory',
    vehicles = 'vehicles',
    technicians = 'technicians',
    vendors = 'vendors',
}

export type JsonsNamesType = `${jsonsNames}`;

export const jsonsNamesEntries = Object.keys(jsonsNames);

export enum JsonFieldTypes {
    string = 'STRING',
    int = 'INT',
    objectsArray = 'OBJECTS_ARRAY',
    singleIntArray = 'SINGLE_INT_ARRAY',
    stringConcat = 'STRING_CONCAT',
    defaultValue = 'DEFAULT_VALUE',
    intArray = 'INT_ARRAY',
}

export const AUTH_USER = 'AUTH_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const SET_IS_AUTH = 'SET_IS_AUTH';
export const SET_LOADING = 'SET_LOADING';
export const SET_IS_RESTORED_PASSWORD = 'SET_IS_RESTORED_PASSWORD';

type LogoutUserType = {
    type: 'LOG_OUT_USER';
};

type IsAuthType = {
    type: 'SET_IS_AUTH';
    isAuth: boolean;
};

type SetLoadingType = {
    type: 'SET_LOADING';
    isLoading: boolean;
};

type SetIsRestoredPassword = {
    type: 'SET_IS_RESTORED_PASSWORD';
    isRestoredPassword: boolean;
};

export type AuthActions = LogoutUserType | SetLoadingType | IsAuthType | SetIsRestoredPassword;

import { Migration } from '../../model';
import {
    CANCEL_MIGRATION_IN_PROGRESS,
    CANCEL_MIGRATION_SUCCESS,
    CANCEL_MIGRATION_FAILURE,
    MigrationAction,
    SET_IS_FETCHING,
    SET_LIMIT_OFFSET,
    SET_MIGRATIONS,
    STOP_MIGRATION_FAILURE,
    STOP_MIGRATION_IN_PROGRESS,
    STOP_MIGRATION_SUCCESS,
} from './action-types';

export interface MigrationState {
    migrations: Migration[];
    isFetching: boolean;
    offset?: number;
    limit?: number;
    isMigrationStopInProgress: boolean;
    stopMigrationError?: Error | null;
    isMigrationCancelInProgress: boolean;
    cancelMigrationError?: Error | null;
}

const initialState: MigrationState = {
    migrations: [],
    isFetching: false,
    isMigrationStopInProgress: false,
    isMigrationCancelInProgress: false,
};

export const MigrationReducer = (state: MigrationState = initialState, action: MigrationAction) => {
    switch (action.type) {
        case SET_MIGRATIONS:
            return {
                ...state,
                migrations: action.migrations,
            };
        case SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case SET_LIMIT_OFFSET:
            return {
                ...state,
                limit: action.limit,
                offset: action.offset,
            };
        case STOP_MIGRATION_IN_PROGRESS:
            return {
                ...state,
                isMigrationStopInProgress: true,
            };

        case STOP_MIGRATION_SUCCESS:
            return {
                ...state,
                isMigrationStopInProgress: false,
                stopMigrationError: null,
            };

        case STOP_MIGRATION_FAILURE:
            return {
                ...state,
                isMigrationStopInProgreess: false,
                stopMigrationError: action.error,
            };

        case CANCEL_MIGRATION_IN_PROGRESS:
            return {
                ...state,
                isMigrationCancelInProgress: true,
            };

        case CANCEL_MIGRATION_SUCCESS:
            return {
                ...state,
                isMigrationCancelInProgress: false,
                cancelMigrationError: null,
            };

        case CANCEL_MIGRATION_FAILURE:
            return {
                ...state,
                isMigrationCancelInProgress: false,
                cancelMigrationError: action.error,
            };

        default:
            return state;
    }
};
